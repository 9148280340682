<template>
  <!-- 充值弹框 -->
  <a-modal v-model:visible="visible" style="width: 900px" :title="$t('_sjdk._gmzk')">
    <div>
      <a-form ref="form" :model="formState" name="basic" :label-col="{ span: 3 }" :wrapper-col="{ span: 18 }" autocomplete="off">
        <!-- 当前套餐 -->
        <!-- <a-form-item :label="$t('flow.dqtc')">
          <div>{{ currentDay.combo }}{{ $t('flow.tc1') }} - {{ $t('flow.sy2') }}{{ currentDay.day }}{{ $t('flow.tian3') }}</div>
        </a-form-item> -->
        <!-- 套餐 -->
        <a-form-item :label="$t('_sjdk._yhez')">
          <a-row type="flex">
            <a-col :span="24">
              <div class="bcs1">
                <div :class="['clas1', currentIndex === i ? 'active' : '']" @click="onCombo(i)" v-for="(item, i) in conpouList" :key="i">
                  <div>
                    <div class="title" style="font-size: 17px; color: #f89900">${{ item.amount }}</div>
                  </div>
                  <div class="usd">{{ $t('_sjdk._shoujia') }}: $ {{ item.price }}</div>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-form-item>
        <!-- 选择板块 -->
        <!-- <a-form-item :label="$t('flow.gmsj')">
          <a-radio-group v-model:value="currentDiscount" name="radioGroup" @change="onDiscount">
            <a-radio :value="i" v-for="(item, i) in config.discount" :key="i">
              <div class="dis-box">
                <div>{{ item.number }} {{ typeStr[item.type].title }}</div>
                <div class="dis" v-if="doDiscount(item.discount) > 0">-{{ doDiscount(item.discount) }}%</div>
              </div>
            </a-radio>
          </a-radio-group>
        </a-form-item> -->
        <!-- 套餐金额 -->
        <a-form-item :label="$t('flow.tcjg')">
          <div class="price">$ {{ currentCombo.price || 0 }}</div>
        </a-form-item>

        <!-- 实际支付 -->
        <!-- <a-form-item :label="$t('flow.sjzf')">
          <div class="price">$ {{ discounted_price }}</div>
        </a-form-item> -->

        <!-- 备注 -->
        <a-form-item :label="$t('flow.tcsm')">
          <div style="padding: 5px 0; line-height: 23px; color: #999">
            <div>{{ $t('_sjdk._yhsmwa') }}</div>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <template #footer>
      <a-button key="submit" type="primary" :loading="btnLoad" @click="onSave">{{ $t('advertise.shop.modal.btn') }}</a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'BuyCoupon',
  data() {
    return {
      visible: false,
      btnLoad: false,
      formState: {},

      currentIndex: 0,
      currentCombo: {},
      conpouList: []
    }
  },
  created() {},
  methods: {
    open() {
      this.visible = true
      this.getCouponList()
    },
    getCouponList() {
      const that = this
      this.$api.coupon.getCouponList().then((res) => {
        that.conpouList = res.data
        that.currentCombo = that.conpouList[that.currentIndex]
      })
    },
    //选择套餐
    onCombo(i) {
      this.currentIndex = i
      this.currentCombo = this.conpouList[i]
    },
    onSave() {
      const that = this
      this.$refs.form.validateFields().then((values) => {
        values.coupon_id = that.currentCombo.id
        console.log(values)
        that.btnLoad = true
        this.$message.loading({
          content: 'submit...',
          key: 'msg',
          duration: 50
        })
        that.$api.coupon
          .payCoupon(values)
          .then((res) => {
            that.btnLoad = false
            that.visible = false
            that.$message.success({
              content: res.msg,
              key: 'msg'
            })
            that.$emit('CallGetMyCoupon')
          })
          .catch(() => {
            that.btnLoad = false
          })
      })
    }
  }
}
</script>
