/**-----------------------------------------------*/
/* 会员
/* -----------------------------------------------*/
import Layout from '../view/member/layout.vue'
import Overview from '../view/member/overview.vue'
import Order from '../view/member/order.vue'
import Payment from '../view/member/payment.vue'
import Address from '../view/member/address.vue'
import Setting from '../view/member/setting.vue'
import Wishlist from '../view/member/wishlist.vue'
import Follow from '../view/member/follow.vue'
import Message from '../view/member/message.vue'
import Redbag from '../view/member/redbag.vue'
import Coupon from '../view/member/coupon.vue'
import OrderDetail from '../view/member/order-detail.vue'
import OrderAll from '../view/member/order/order-all.vue'
import OrderUnpaid from '../view/member/order/order-unpaid.vue'
import OrderProcurement from '../view/member/order/order-procurement.vue'
import OrderProcessing from '../view/member/order/order-processing.vue'
import OrderShipped from '../view/member/order/order-shipped.vue'
import OrderCompleted from '../view/member/order/order-completed.vue'

const Router = [
  {
    path: '/member',
    name: 'member',
    meta: { isLogin: true, type: 1 },
    component: Layout,
    children: [
      {
        path: '/overview',
        name: 'overview',
        component: Overview,
        meta: { name: 'overview' }
      },
      {
        path: '/payment',
        name: 'payment',
        meta: { name: 'payment' },
        component: Payment
      },
      {
        path: '/address',
        name: 'address',
        meta: { name: 'address' },
        component: Address
      },
      {
        path: '/wishlist',
        name: 'wishlist',
        meta: { name: 'wishlist' },
        component: Wishlist
      },
      {
        path: '/follow',
        name: 'follow',
        meta: { name: 'follow' },
        component: Follow
      },
      {
        path: '/message',
        name: 'message',
        meta: { name: 'message' },
        component: Message
      },
      {
        path: '/redbag',
        name: 'redbag',
        meta: { name: 'redbag' },
        component: Redbag
      },
      {
        path: '/coupon',
        name: 'coupon',
        meta: { name: 'coupon' },
        component: Coupon
      },
      {
        path: '/setting',
        name: 'setting',
        meta: { name: 'setting' },
        component: Setting
      },
      {
        path: '/order',
        name: 'order',
        component: Order,
        meta: { name: 'order' },
        children: [
          {
            path: '/order/all',
            name: 'orderAll',
            component: OrderAll
          },
          {
            path: '/order/unpaid',
            name: 'orderUnpaid',
            component: OrderUnpaid
          },
          {
            path: '/order/procurement',
            name: 'orderProcurement',
            component: OrderProcurement
          },
          {
            path: '/order/processing',
            name: 'orderProcessing',
            component: OrderProcessing
          },
          {
            path: '/order/shipped',
            name: 'orderShipped',
            component: OrderShipped
          },
          {
            path: '/order/completed',
            name: 'orderCompleted',
            component: OrderCompleted
          }
        ]
      },
      {
        path: '/order-detail',
        name: 'order-detail',
        meta: { name: 'order' },
        component: OrderDetail
      }
    ]
  }
]

export default Router
