<template>
  <div id="root">
    <div class="account-setting">
      <div class="setting-title">
        <p class="setting-title-text">{{ $t('_sjdk.dkq') }}</p>
      </div>
      <!-- <div style="background-color: #fff; margin-top: 20px; padding: 20px">
        <div>
          <a class="sdf3q setting-section-content-item-text" @click="onUpdate()">{{ $t('_sjdhb._lqlqyhj') }}</a>
        </div>
      </div> -->

      <div class="card-root--card-root--BfQRUAH" style="margin-top: 20px; background-color: #fff; padding: 20px">
        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane :key="1" :tab="$t('_sjdk._yhjjl')">
            <CouponList ref="receive"></CouponList>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import CouponList from './module/CouponList.vue'
export default {
  name: 'settingVue',
  components: {
    CouponList
  },
  data() {
    return {
      user: {}
    }
  },
  created() {
    this.onGetInfo()
  },
  methods: {
    onUpdate() {
      this.$refs.getRedbag.show()
    },
    onGetInfo() {
      this.user = this.$common.getInfo()
    }
  }
}
</script>
<style scoped src="@/static/css/setting.css"></style>
<style scoped>
.u-avatar {
  width: 50px;
  height: 50px;
  overflow: hidden;
  margin-bottom: 10px;
}
.u-avatar img {
  width: 100%;
}

.sdf3q {
  padding: 7px;
  border: 1px solid #2788e9;
  color: #2788e9;
  border-radius: 5px;
  font-size: 14px;
}
</style>
