<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('_sjdk.dkq') }}</span>
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <a-row :gutter="24">
    <!-- 已借贷金额 -->
    <a-col :sm="24" :md="8" :xl="8" :style="{ marginBottom: '24px' }">
      <a-card :body-style="{ padding: '20px 24px 10px' }" :bordered="true">
        <div class="chart-header">
          <div class="meta">
            <div class="chart-title">{{ $t('_sjdk._yuee') }}</div>
          </div>
          <div class="icon">
            <a-tooltip>
              <template #title>
                <div>dollar</div>
              </template>
              <dollar-outlined :style="{ fontSize: '24px' }" />
            </a-tooltip>
          </div>
        </div>
        <div class="chart-number" style="display: flex; justify-content: space-between">
          <div>${{ myCoupon.coupon || '0.00' }}</div>
        </div>

        <div class="chart-footer" style="display: flex; align-items: center; justify-content: space-between">
          <div style="display: flex; align-items: center; gap: 10px">
            <a-tooltip>
              <template #title>
                <div>前往消息中心，点开买家聊天窗口，右上角“<gift-outlined style="color: greenyellow" />”图标，向他赠送！</div>
              </template>
              <div style="font-size: 13px; color: #666; cursor: pointer">{{ $t('_sjdk._qrzs') }}?</div>
            </a-tooltip>
            <router-link :to="{ name: 'storeMessage' }">{{ $t('_sjdk._qzs') }}>></router-link>
          </div>
          <div>
            <a-button type="primary" :style="{ marginLeft: '10px' }" @click="onBuy">{{ $t('_sjdk._gmyue') }}</a-button>
          </div>
        </div>
      </a-card>
    </a-col>
  </a-row>
  <!-- 钱包记录 -->
  <div>
    <a-card style="margin-top: 10px">
      <a-tabs v-model:activeKey="activeKey">
        <!-- <a-tab-pane :key="1" :tab="$t('_sjhb._kfxd')"> -->
        <!-- <RedbagList ref="redbag"></RedbagList> -->
        <!-- </a-tab-pane> -->
        <a-tab-pane :key="1" :tab="$t('_sjdk._yhjjl')">
          <CouponList ref="fxlist"></CouponList>
        </a-tab-pane>
        <!-- <a-tab-pane :key="3" :tab="$t('_sjdhb._ygq')">
          <RedbagGqList ref="loan"></RedbagGqList>
        </a-tab-pane> -->
      </a-tabs>
    </a-card>
    <BuyCoupon ref="buyCoupon" @CallGetMyCoupon="getMyCoupon"></BuyCoupon>
  </div>
</template>

<script>
import { DollarOutlined, GiftOutlined } from '@ant-design/icons-vue'
import CouponList from './module/CouponList.vue'
import BuyCoupon from './module/BuyCoupon.vue'

export default {
  name: 'walletVue',
  components: {
    DollarOutlined,
    CouponList,
    BuyCoupon,
    GiftOutlined
    // RedbagGqList,
    // ApplyLoan,
    // ApplyRepayment
  },
  data() {
    return {
      //tabs
      activeKey: 1,
      // 钱包
      myCoupon: { coupon: '0.00' }
    }
  },
  created() {
    this.getMyCoupon()
  },
  computed: {
    totalAmount() {
      let total = parseFloat(this.wallet.finance) + parseFloat(this.wallet.interest)
      return total.toFixed(2)
    }
  },
  methods: {
    onBuy() {
      this.$refs.buyCoupon.open()
    },
    getMyCoupon() {
      let that = this
      this.$api.coupon.getMyCoupon().then((res) => {
        that.myCoupon = res.data
      })
    },

    onRefresh() {
      this.$refs.fxlist.refresh()
    },
    onApply() {
      this.$refs.apply.show()
    },
    ApplyLoanSuccess() {
      this.$refs.loan.refresh()
    },

    onRepayment() {
      this.$refs.repayment.show()
    },
    ApplyRepaymentSuccess() {
      this.$refs.loan.refresh()
    }
  }
}
</script>

<style scoped src="@/static/css/store-css.css"></style>

<style lang="less">
.chart-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  .meta {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
  }
}

.chart-number {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  color: #000;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 30px;
  line-height: 38px;
  height: 38px;
}

.chart-footer {
  border-top: 1px solid #e8e8e8;
  padding-top: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.xy_span {
  padding: 4px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 8px;
  cursor: pointer;
}
.xy_span.active {
  border: 1px solid #009688;
  color: #009688;
}

.token_css {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
  border: 1px solid #b7eb8f;
  padding: 5px 10px;
  border-radius: 5px;
}
</style>
