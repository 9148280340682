<template>
  <a-modal v-model:visible="visible" :title="$t('_sjdk._zsyhj')" @ok="hide" @cancel="hide" :cancelText="$t('recharge.cancel.text')">
    <a-form ref="form" :model="formState" name="basic" :label-col="{ span: 3 }" :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-form-item :label="$t('_sjdk._zsdx')">
        <div style="display: flex; gap: 10px; align-items: center">
          <div>
            <img style="width: 40px; height: 40px" :src="currentChat.avatar" />
          </div>
          <div>
            <div style="font-weight: 700">{{ currentChat.nickname || '' }}</div>
            <div class="">{{ currentChat.uid || '' }}</div>
          </div>
        </div>
      </a-form-item>

      <a-form-item :label="$t('_sjdk._yuee')">
        <div class="price">$ {{ myCoupon.coupon || 0 }}</div>
      </a-form-item>

      <a-form-item :label="$t('_sjdk._yhjje')" name="amount" :rules="[{ required: true, message: $t('_sjdk._sryhjje') }]">
        <a-input v-model:value="formState.amount" :placeholder="$t('_sjdk._sryhjje')" />
      </a-form-item>
      <a-form-item :label="$t('_sjdk._xftj')" name="condition" :rules="[{ required: true, message: $t('_sjdhb._yhjbm') }]" :help="$t('_sjdk._yhqtj')">
        <a-input v-model:value="formState.condition" :placeholder="$t('_sjdk._srxftj')" />
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button key="submit" type="primary" :loading="btnLoad" @click="onGiftSave">{{ $t('_sjdk._ljzs') }}</a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'RechargeVue',
  components: {},
  data() {
    return {
      //存币
      visible: false,
      btnLoad: false,

      //赠送对象
      currentChat: {},
      //
      formState: {
        amount: '',
        condition: ''
      },

      // 钱包
      myCoupon: { coupon: '0.00' }
    }
  },
  created() {},
  methods: {
    onGiftSave() {
      const that = this
      this.$refs.form.validateFields().then((values) => {
        values.uid = that.currentChat.uid
        console.log(values)
        that.btnLoad = true
        this.$message.loading({
          content: 'submit...',
          key: 'msg',
          duration: 50
        })
        that.$api.coupon
          .giveaway(values)
          .then((res) => {
            that.btnLoad = false
            that.visible = false
            that.$message.success({
              content: res.msg,
              key: 'msg'
            })
            that.$emit('CallOnSuccess', values)
          })
          .catch(() => {
            that.btnLoad = false
          })
      })
    },
    /**
     * 存币
     */
    show(currentChat) {
      this.formState = {}
      if (currentChat.id > 0) {
        this.visible = true
        this.currentChat = currentChat
        this.getMyCoupon()
      } else {
        this.$message.error({ content: 'error' })
      }
    },
    getMyCoupon() {
      let that = this
      this.$api.coupon.getMyCoupon().then((res) => {
        that.myCoupon = res.data
      })
    },
    hide() {
      this.visible = false
    }
  }
}
</script>

<style></style>
