export default {
  'nav.account': 'パーソナル センター',
  'nav.language': '簡体字中国語',
  'signup.success': '送信に成功しました',

  //エラー
  'contact.empty': '連絡先情報を空にすることはできません',
  'phone.country.empty': '電話プレフィックスを空にすることはできません',
  'phone.empty': '電話を空にすることはできません',
  'email.valid': '有効なメールアドレスを入力してください',

  // ベース
  'base.success': '成功',
  'base.save': '保存',
  'base.slide': 'スライダーを引いて確認してください',
  'base.send.code': '確認コードを送信',
  'base.send.time': '({time}) 確認コードを再送信',
  'base.send.success': '送信成功',
  'base.view.more': 'もっと見る',
  'base.Language': '言語',
  'base.previous': '前のページ',
  'base.next': '次のページ',
  'base.total.page': '合計 {page} ページ',
  'base.go.to.page': 'ページに移動',
  'base.go': '移動',
  'base.followers': 'フォロワー',
  'base.follow': 'フォロー',
  'base.following': 'フォロー中',
  'base.visit.store': 'ストアを参照',
  'base.contact': 'お問い合わせ',
  'base.delivery': '配送予定日: 1-7 日',
  'base.search': '検索',
  'base.search.text': '検索するキーワードを入力してください',
  'base.server': 'オンラインカスタマーサービス',

  //索引
  'text.categories.title': '商品カテゴリー',
  'text.welcome': 'ショッピングへようこそ!',
  'text.wishlist': 'ウィッシュリスト',
  'text.account': 'パーソナル センター',
  'text.orders': '注文',
  'text.messages': '情報',
  'text.suggest.title': '限定オファー',
  'text.suggest.content': '新しいグローバル プレミアム メンバー限定!',
  'text.super.deals': '信じられない価格のトップ商品。',

  //サインアップ
  'signup.register': 'サインアップ',
  'signup.signin': 'サインイン',
  'signup.store.signin': 'ビジネスログイン',
  'signup.sign.out': 'ログアウト',
  'signup.email': 'メールアドレス',
  'signup.password': 'パスワード',
  'signup.qr.password': 'パスワードの確認',
  'signup.confirm.password': 'パスワードの確認',
  'signup.forgot.password': 'パスワードを忘れた',
  'signup.invitation': '招待コード',
  'signup.char': 'キャラクター',
  'signup.contains': '数字、文字、記号を含む',
  'signup.qr.contains': '2 つのパスワードが一致していません',
  'signup.create.account': 'アカウントを作成',
  'signup.agree': 'アカウントを作成します。同意します',
  'signup.member.agreement': '会員規約',
  'signup.and': 'and',
  'signup.privacy.policy': 'プライバシー ポリシー',
  'signup.email.code': 'メール認証',
  'signup.last.step': '最後のステップ',
  'signup.send.email': '{email} に送信された 4 桁のコードを入力してください',
  'signup.modify.email': 'メールを変更',
  'signup.verify.email': '確認メール',
  'signup.have.store': 'マーチャント アカウントを持っている',
  'signup.goto.signin': 'ビジネスログイン',
  'signup.no.store': '販売アカウントがありません',
  'signup.goto.store': '加盟店の登録',
  'signup.next': '次へ',
  'signup.your.email': 'あなたのメールアドレス',
  'signup.code.text': '認証コード',
  'signup.submit.signup': '今すぐサインアップ',
  'signup.smrz': '実名認証',
  'signup.derb': '2 番目のステップ',
  'signup.qsrxm': 'ドキュメントの名前を入力してください',
  'signup.qsrhm': 'ID 番号を入力してください',

  //忘れた
  'forgot.title': 'パスワードをリセット',
  'forgot.btn.check': 'メールアドレスを確認',
  'forgot.reset.now': '今すぐリセット',

  //お店
  'store.info.open': '{y}週オープン',

  // フッター
  'footer.great.value': 'お得です',
  'footer.great.value.desc': '1 億以上の商品を競争力のある価格で提供しています。',
  'footer.shopping': 'グローバル ショッピング',
  'footer.shopping.desc': '当社は 200 を超える国と地域に発送しており、当社のウェブサイトは 7 か国語で利用できます。',
  'footer.safe.payment': '安全な支払い',
  'footer.safe.payment.desc': '世界で最も人気があり、最も安全な支払い方法で支払います。',
  'footer.shop.with.confidence': '自信を持って買い物をする',
  'footer.shop.with.confidence.desc': '当社の買い手保護ポリシーは、購入プロセス全体をカバーしています。',
  'footer.help.center': 'ヘルプセンター',
  'footer.help.center.desc': 'スムーズなショッピング体験を作成するための年中無休のサポート。',
  'footer.terms.conditions': '利用規約',
  'footer.return.policy': '返品ポリシー',
  'footer.support.policy': 'サポート ポリシー',
  'footer.privacy.policy': 'プライバシー ポリシー',
  'footer.be.seller': '売り手になる',
  'footer.apply.now': '今すぐ申し込む',
  'footer.stay.connected': '接続を維持',

  'footer.about.us': '私たちについて',
  'footer.about.company': '会社概要',
  'footer.about.video': '動画紹介',
  'footer.contact': '連絡先',

  'footer.my.account': '私のアカウント',
  'footer.my.logout': 'ログアウト',
  'footer.my.order': '注文履歴',
  'footer.my.wish': '私のウィッシュリスト',
  'footer.my.join': 'メンバー パートナーになる',
  'footer.email': 'メール',
  'footer.gfemail': '公式メールアドレス',
  'footer.fwemail': 'サービスメール',
  'footer.address': 'アドレス',

  'apply.success': '申請成功',
  'apply.success.desc': '正常に適用されました。ログイン アカウントに移動します',

  // カテゴリ
  'category.title': '関連カテゴリ',
  'category.all': 'すべてのカテゴリ',

  //詳細
  'detail.store.home': '店舗ホーム',
  'detail.sale.items': '商品一覧',
  'detail.recommend': 'おすすめ',
  'detail.orders': '販売',
  'detail.quantity': '量',
  'detail.pieces.available': '利用可能',
  'detail.delivery': '配送',
  'detail.free.shipping': '送料無料',
  'detail.estimated.delivery': '配送予定',
  'detail.days': '日数',
  'detail.buy.now': '購入',
  'detail.add.to.cart': 'カートに入れる',
  'detail.buyer.protection': '買い手保護',
  'detail.money.guarantee': '返金保証',
  'detail.refund.desc': '商品が説明と異なる場合、または配送されなかった場合は、全額返金されます',
  'detail.description': '商品説明',
  'detail.customer.reviews': 'カスタマーレビュー',
  'detail.specifications': '製品仕様',
  'detail.top.selling.products': 'ベストセラー商品',
  'detail.recommended.for.you': 'あなたへのおすすめ',
  'detail.sold': '販売',
  'detail.receipt': '領収書の確認',
  'detail.receipt.title': '注文の受領を確認しますか?',
  'detail.receipt.content': '確認後、取引注文完了',
  'detail.comment': 'コメント',
  'detail.refund.title': '返金申請の確認',
  'detail.refund.content': '確認後、注文は返金を申請します',
  'detail.sqtk': '返金を申請',

  //確認
  'confirm.shipping.address': '受取人の住所',
  'confirm.change': '変更',
  'confirm.payment.methods': '支払い方法',
  'confirm.summary': '決済',
  'confirm.total.item.costs': 'アイテム合計',
  'confirm.total': '合計',
  'confirm.checkout': 'チェックアウト',
  'confirm.place.order': '今すぐ注文する',
  'confirm.pay.now': '今すぐ支払う',
  'confirm.order.desc': '[注文する] をクリックした後、内容を確認して確認しました',
  'confirm.order.policy': 'すべての条件とポリシー',
  'confirm.payment': 'グローバル ショッピングは、お客様の情報と支払いのセキュリティを保証します',

  // 住所
  'address.title': '受信者アドレス',
  'address.default': 'デフォルト',
  'address.edit': '編集',
  'address.delete': '削除',
  'address.new': '新しいアドレスを作成する',
  'address.contact': '連絡先',
  'address.address': '住所',
  'address.phone': '電話番号',
  'address.set.default': 'デフォルトに設定',
  'address.confirm': '確認',
  'address.cancel': 'キャンセル',
  'address.del.title': 'アドレスの削除を確認しますか?',
  'address.del.content': 'この配送先住所を削除してもよろしいですか?',

  'payment.method': '支払い方法',

  // ショッピングカート
  'shopping.title': 'ショッピングカート',
  'shopping.back.buy': '特別なショッピングに戻る',
  'shopping.start.shopping': 'ショッピングを開始',

  // メンバー
  'member.account': 'パーソナル センター',
  'member.overview': '概要',
  'member.orders': '注文',
  'member.payment': '私の財布',
  'member.address': '配送先住所',
  'member.wishlist': 'ウィッシュリスト',
  'member.followlist': 'フォローリスト',
  'member.message.center': 'メッセージ センター',
  'member.setting': '個人設定',
  'member.shop.info': 'ショップ情報',
  'member.shop.setting': 'ショップ設定',
  'member.order.notify': '新規注文通知',
  'member.order.tips': 'マーチャント アカウントのメールにメールを送信することにより',

  // member.order
  'member.order.title': '私の注文',
  'member.order.view.all': 'すべて表示',
  'member.order.all': 'すべて',
  'member.order.unpaid': '未払い',
  'member.order.paid': '有料',
  'member.order.procurement': '保留中の調達',
  'member.order.seller.paid': '購入済み',
  'member.order.processing': '保留中',
  'member.order.shipped': '発送済み',
  'member.order.completed': '完了',
  'member.order.refund': '返金済み',
  'member.order.all.time': 'すべて',
  'member.order.empty': 'まだ注文がありません',
  'member.order.date': '日付',
  'member.order.purchase.date': '購入',
  'member.order.cpoy': 'コピー',
  'member.order.id': '注文',
  'member.order.detail': '注文の詳細',
  'member.order.logical': '物流情報',

  // メンバー.支払い
  'member.payment.title': '私の財布',
  'member.wallet.balance': 'ウォレットの残高',
  'member.crypto.recharge': '再充電',
  'member.crypto.withdrawal': '撤退',
  'member.crypto.bank': '銀行カード',
  'member.wallet.record': 'コイン入金記録',
  'member.bankcard.record': '銀行カードの記録',
  'member.withdrawal.record': '退会記録',
  'member.income.record': '収入記録',
  'member.transaction.record': '取引記録',
  'member.wallet.freeze': '資金を凍結',
  'member.wallet.profit': '推定収益',

  //再充電
  'recharge.currency': '通貨',
  'recharge.protocol': 'プロトコル',
  'recharge.qrcode': 'QRコード',
  'recharge.address': 'ウォレットアドレス',
  'recharge.copy': 'コピーアドレス',
  'recharge.ok.text': '確認',
  'recharge.cancel.text': 'キャンセル',

  //銀行
  'bank.recharge.title': '銀行カードのリチャージ',
  'bank.title': '銀行名',
  'bank.name': '受取人名',
  'bank.account': '受取口座',
  'bank.routing': '銀行ルーティング番号',
  'bank.code': '銀行コード',
  'bank.bankname': '銀行名',
  'bank.bankaddress': '銀行の住所',
  'bank.ok.text': '次へ',
  'bank.ok.prev': '前のステップ',
  'bank.submit': '今すぐ提出',
  'bank.amount': 'チャージ金額',
  'bank.amount.desc': 'チャージ金額を入力してください',
  'bank.type': 'タイプ',
  'bank.usd': '米ドル',
  'bank.eur': 'ユーロ',
  'bank.receipt.number': '領収書番号',
  'bank.receipt.number.desc': '取引受付番号を入力してください',
  'bank.credential.picture': '資格証明画像',
  'bank.credential.picture.desc': '認証写真をアップロードしてください',
  'bank.remark': '備考',
  'bank.upload': '画像をアップロード',
  'bank.text.title': 'メモ',
  'bank.text.t1': '1.お振込みの場合は備考不要ですので空欄にしてください',
  'bank.text.t2': '2. 香港の世帯の場合は、中国ではなく香港を選択してください',
  'bank.text.t3': '3. アメリカの送金は平日、ヨーロッパの送金は午後 3 時までに送金してください。送金は銀行の営業時間内に可能です!',
  'bank.text.t4': '4. 支払い時間は T+1 で、最後の支払いはヨーロッパで T+3 です',
  'bank.text.t5': '5.送金前にプラットフォームに連絡して、口座が利用可能かどうかを確認してください。口座が閉鎖された場合、補償は支払われません。',
  'bank.text.t6': '6. 銀行カードによる入金。すべての顧客サービス情報が優先されます。',

  //バンクリスト
  'bank.list.title': '銀行名',
  'bank.list.code': '銀行コード',
  'bank.list.amount': '送金金額',
  'bank.list.number': '領収書番号',
  'bank.list.img': 'クーポン画像',
  'bank.list.status': 'ステータス',
  'bank.list.time': '時間',
  'bank.list.status1': '審査中',
  'bank.list.status2': '合格',
  'bank.list.status3': '拒否',

  // 出金
  'withdrawal.address.desc': 'ウォレットアドレスを入力してください!',
  'withdrawal.number': '量',
  'withdrawal.real.number': '実際の到着',
  'withdrawal.number.desc': '出金額を入力してください!',
  'withdrawal.btn.all': 'すべて',
  'withdrawal.balance': '残高',
  'withdrawal.commission': 'コミッション',
  'withdrawal.actual.amount': 'アカウント',
  'withdrawal.notice': 'お知らせ',
  'withdrawal.notice.text': '譲渡する前に、受取先住所情報が正しいことを確認してください。資産が譲渡されると、それらは返却できません。',
  'withdrawal.notice.content': '{name}({cp_name}) 手数料: 現在の市場価値 {fee_rate}%/トランザクション、最低基準: {fee_min} {name}/トランザクション',
  'withdrawal.submit': '提出',
  'withdrawal.choice': '暗号通貨を選択',
  'withdrawal.yzm': '検証コード',
  'withdrawal.fs': '送信',
  'withdrawal.qsryzm': 'メール認証コードを入力してください',

  // 受信
  'recive.method': 'プロトコル',
  'recive.amount': '総額',
  'recive.address': 'アドレス',
  'recive.date': '時間',
  'recive.status': 'ステータス',
  'recive.create.at': 'トランザクション時間',
  'recive.type': 'タイプ',
  'recive.befor': '取引前',
  'recive.balance': '残高',
  'recive.freeze': 'フリーズ',
  'recive.review': 'レビュー中',
  'recive.success': '合格',
  'recive.reject': '却下',

  // アドバタイズ
  'advertise.title': 'プロモーション センター',
  'advertise.shop.title': 'ショップのプロモーション',
  'advertise.shop.status': 'ステータス',
  'advertise.shop.expired': '期限切れ',
  'advertise.shop.promotion': '宣伝中',
  'advertise.shop.expire.date': '有効期限',
  'advertise.shop.renew': '広告の更新',
  'advertise.shop.payable': 'お支払い金額',
  'advertise.shop.explanation': '広告の説明',
  'advertise.shop.text': 'プラットフォームの広告宣伝に参加し、店舗での露出を増やし、取引注文を促進する',
  'advertise.shop.paynow': 'すぐに更新',
  'advertise.shop.modal.title': '支払いの確認',
  'advertise.shop.modal.desc': 'プロモーション料金の支払いを確認しました',
  'advertise.shop.modal.btn': '支払いの確認',

  //ウィッシュリスト
  'wishlist.title': 'ウィッシュリスト',
  'wishlist.delete': '削除',
  'wishlist.orders': '販売',

  //フォローリスト
  'followlist.title': 'フォローリスト',
  'followlist.delete': '削除',
  'followlist.follow': 'フォロー',

  // お店
  'store.dashboard': 'ダッシュボード',
  'store.products': '商品',
  'store.products.list': '商品一覧',
  'store.products.reviews': '商品レビュー',
  'store.orders': '注文',
  'store.wallet': 'ウォレット',
  'store.message': 'メッセージ センター',
  'store.setting': '設定',
  'store.order.total.profit': '推定総営業利益',

  //ダッシュボード
  'dashboard.store.hour.views': 'リアルタイム訪問',
  'dashboard.store.today.views': '今日のビュー',
  'dashboard.product.total': '総製品',
  'dashboard.product.today': '本日追加',
  'dashboard.order.total': '注文合計',
  'dashboard.sales.total': '推定売上高',
  'dashboard.sales.real': '実際の売上',
  'dashboard.sales.pay': '支払い総額',
  'dashboard.sales.profit': '総利益',
  'dashboard.commission.total': '総収入',
  'dashboard.commission.today': '今日の収益',
  'dashboard.order.sales': '商品の売上',
  'dashboard.sales.list': '売上ランキング',
  'dashboard.goods.cate.rate': '商品カテゴリ比率',
  'dashboard.goods.wish': 'いいね！グッズランキング',
  'dashboard.january': '1月',
  'dashboard.february': '2月',
  'dashboard.march': '3月',
  'dashboard.april': '4月',
  'dashboard.may': '5月',
  'dashboard.june': '6月',
  'dashboard.july': '7月',
  'dashboard.august': '8月',
  'dashboard.september': '9月',
  'dashboard.october': '10月',
  'dashboard.november': '11月',
  'dashboard.december': '12月',

  // 製品
  'products.add.new': '新しい商品を追加',
  'products.add.from.warehouse': '倉庫から商品を追加',
  'products.delete': '削除',
  'products.add': '追加',
  'products.table.img': '画像',
  'products.table.name': '商品名',
  'products.table.category': 'カテゴリー',
  'products.table.wish': 'いいね',
  'products.table.stock': '在庫',
  'products.table.price': '価格',
  'products.table.profit': '利益',
  'products.table.action': 'アクション',
  'products.search.category': 'カテゴリーで検索',
  'products.back.product': '商品リストに戻る',
  'products.total': '総製品',
  'products.yes': 'はい',
  'products.no': 'キャンセル',
  'products.batch.add': '一括追加',
  'products.ask.add': '本当に製品を追加しますか?',
  'products.batch.delete': '一括削除',
  'products.ask.delete': '本当に製品を削除しますか?',
  'products.top': 'トップ',
  'products.syzd': 'ホームページトップ',
  'products.zdwz': 'トップ ポジション',
  'products.t1': 'プロモーション',
  'products.t2': '優先',
  'products.t3': 'ブティック',
  'products.t4': 'なし',

  // レビュー
  'reviews.title': '商品レビュー',
  'reviews.product.name': '商品',
  'reviews.user.name': 'ユーザー',
  'reviews.star': '評価',
  'reviews.comment': 'レビューの内容',
  'reviews.sku': '商品仕様',
  'reviews.imgs': 'フォト ギャラリー',
  'reviews.created': '日付',

  // store.order
  'store.order.purchase': '今すぐ購入',
  'store.order.purchase.desc': 'この商品の支払いをしてもよろしいですか?',
  'store.order.purchase.yes': 'すぐに支払う',
  'store.order.purchase.no': 'キャンセル',
  'store.order.desc': 'この注文を購入するには支払いが必要であり、注文が完了すると特典を受け取ります。',
  'store.order.no': '注文番号',
  'store.order.number': '数量',
  'store.order.buyer': 'バイヤー',
  'store.order.total': '合計金額',
  'store.order.will.earning': '収益',
  'store.order.profit': '利益',
  'store.order.dividends': '配当',
  'store.order.payment.status': '支払い状況',
  'store.order.seller.buy.status': '購入状況',
  'store.order.status': '注文状況',
  'store.order.date': '注文日',
  'store.order.purchase.date': '購入日',
  'store.order.action': 'アクション',
  'store.order.purchase.amount': '購入金額',

  //所得
  'income.create.at': '記録時間',
  'income.order.sn': '注文番号',
  'income.realpay': '注文金額',
  'income.profit': '収入',

  //設定
  'setting.avatar': 'ビジネスアバター',
  'setting.upload': 'アップロード',
  'setting.shop.name': 'ショップ名',
  'setting.shop.phone': '連絡先電話',
  'setting.shop.address': 'ショップの住所',
  'setting.shop.save': '保存',
  'setting.upload.pic': '画像をアップロード',
  'setting.send.pic': '写真を送る',

  //ベセラー
  'beseller.title': '加盟店に申し込む',
  'beseller.account': 'アカウント情報',
  'beseller.store': '店舗情報',
  'beseller.store.name': 'ストア名',
  'beseller.store.address': '店舗住所',

  //セーブホーム
  'store.home.title': 'ホームページ設定',
  'store.home.topimg': 'トップ画像',
  'store.home.banner': 'カルーセル',
  'store.home.up3': '備考: 少なくとも 3 回のアップロード',
  'store.upload.more': '複数の写真をアップロード',
  'store.home.columns': '列',
  'store.home.bgimg': '背景画像',
  'store.goods.remark': '備考: 1 行あたり 5 アイテム、セクションあたり最大 10 アイテム',
  'store.home.select': '商品を選択',
  'store.home.add': '列を追加',

  //個人情報
  'setting.update.user': '個人情報',
  'setting.update.info': '情報を修正',
  'setting.user.avatar': 'ユーザーアバター',
  'setting.user.nickname': 'ユーザーのニックネーム',
  'setting.user.nickname.desc': 'ユーザーのニックネームを入力してください',

  'setting.safe.title': '安全情報',
  'setting.user.passwd': 'パスワードを変更',
  'setting.passwd.title1': '古いパスワード',
  'setting.passwd.desc1': '古いパスワードを入力してください',
  'setting.passwd.title2': '新しいパスワード',
  'setting.passwd.desc2': '新しいパスワードを入力してください',
  'setting.passwd.title3': 'パスワードの確認',
  'setting.passwd.desc3': '確認用パスワードを入力してください',
  'setting.invitation': '友達を招待',
  'setting.copy': 'コピー',

  'adv.add.products': 'プロモーション製品を追加',
  'adv.list.title': '製品プロモーション',
  'adv.type': 'プロモーション タイプ',
  'adv.begin.time': 'プロモーション時間',
  'adv.end.time': '終了時間',
  'adv.status': 'ステータス',
  'adv.status.s1': '昇格中',
  'adv.status.s2': '終了',
  'adv.add.back': '昇格したリストに戻る',
  'adv.recharge': 'リチャージ プロモーション ポイント',
  'adv.select.goods': '商品を選択',
  'adv.select.btn': 'プロモーション製品',
  'adv.recharge.title': 'リチャージプロモーションポイント',
  'adv.recharge.balance': 'ポイント残高',
  'adv.point': 'ポイント',
  'adv.point.rate': 'ポイント率',
  'adv.recharge.amount': 'リチャージ量',
  'adv.recharge.input.amount': 'リチャージ額を入力してください',
  'adv.select.mod': '選択パネル',
  'adv.mod1': '推奨列',
  'adv.mod2': '優先列',
  'adv.mod3': '売れ筋コラム',
  'adv.mod4': '優れたリスト',
  'adv.mod5': '分類リスト',
  'adv.mod6': '詳細プロモーション',
  'adv.ge': '量',
  'adv.xs': '時間',
  'adv.xzbk': 'プレートを選択',
  'adv.syme': '残りのクォータ',
  'adv.tgsc': 'プロモーション期間',
  'adv.tgjg': 'プロモーション価格',
  'adv.syye': '現在の残高',
  'adv.tgye': 'プロモーションバランス',
  'adv.yj1': '予想',
  'adv.yj2': 'プロモーションを開始',
  'adv.pay.point': '消費ポイント',

  'wallet.ykcsxf': '手数料が差し引かれました',
  'wallet.sxf': 'サービス手数料',
  'wallet.zxje': '最低額',
  'wallet.24hxe': '24時間制限',
  'wallet.desc.text': 'どのような状況下でも、当局はあなたに口座への送金を要求したり、確認コードを要求したりすることはありません. 代理購入、マネーロンダリング、違法な資金調達などの違法行為に加担せず、オンライン詐欺にご注意ください',
  'wallet.cunbi.text': 'このアドレスに入金できるのは {name} のみです。他の資産は取得されません',

  'credit.title': '与信限度額',
  'credit.ed': 'クォータ',
  'credit.hk': '返済',
  'credit.kyye': '利用可能な残高',
  'credit.dqqk': '現在の延滞',
  'credit.hkje': '返済額',
  'credit.hkje.desc': '返済額を入力してください',
  'credit.cgje': '購入金額',
  'credit.qrz': '証明書',
  'credit.srxyj': 'クレジット支払い額を入力してください',

  'store.gzl': 'フォロー',
  'store.wxz': '無制限',

  'auth.smrz': '実名認証',
  'auth.rzlx': 'タイプ',
  'auth.sfzm': 'ID カード前面',
  'auth.sffm': 'IDカードの裏面',
  'auth.zsxm': '本名',
  'auth.zjhm': '文書番号',
  'auth.yyzz': 'ビジネスライセンス',
  'auth.gsmc': '会社名',
  'auth.shz': '監査中',
  'auth.ytg': '合格',
  'auth.ybh': '拒否されました',
  'auth.zt': 'ステータス',
  'auth.gr': '個人',
  'auth.gs': 'エンタープライズ',
  'auth.ljtj': '認証申請',
  'auth.wrz': '未認証',

  'credit.qts': '説明を見る',
  'store.spss': '商品上限',
  'store.dpfh': 'ストアの配当',
  'store.qydj': '株式水準',
  'store.level': 'レベル',
  'store.jian': 'ピース',

  'order.notify.email': '電子メールのリスク管理のため、プラットフォームの注文リマインダー メールが頻繁に送信され、傍受される可能性があります。時間内に受信するには、ログインに {email} を追加することをお勧めしますメールのホワイトリスト',

  'setting.sjxx': '情報',
  'setting.smrz': '認証',
  'setting.aqzx': 'セキュリティ',
  'setting.tzxx': '通知',
  'setting.dpzx': '装飾',
  'setting.sjqy': 'エクイティ',
  'setting.sjdjqy': '加盟店レベル特典の説明',

  'setting.fhbky': '現在の配当は利用できません',
  'setting.spgzyzy': 'ストアのルールとガイドライン',

  'desc.dqqy': '現在の株式',
  'desc.xjqy': '従属利益',
  'desc.sjqy_0': 'ストアの与信限度額',
  'desc.sjqy_1': 'ストア配当',
  'desc.sjqy_2': '掲載可能商品数',

  'share.yqlb': '招待リスト',
  'share.zmj': 'トータルセラー',
  'share.zdd': '注文合計',
  'share.zsy': '総収入',
  'share.wcl': '未処理',
  'share.ycl': '処理済み',
  'share.ywc': '完了',
  'share.dpmc': 'ストア名',
  'share.ddl': '注文数量',
  'share.jjsy': '仲介収入',
  'share.zts': '総アイテム数',

  'chat.state': '状態',
  'chat.zx': 'オンライン',
  'chat.ljz': '接続中',

  'bind.wallet.title': '出金アドレス',
  'bind.address.title': 'アドレスを追加',
  'bind.bj': '編集',
  'bind.sc': '削除',
  'bind.qrsc': 'このアドレスを削除してもよろしいですか?',
  'bind.qbdz': 'ウォレットアドレス',
  'bind.tjsj': '時間を追加',
  'bind.cz': '操作',
  'withdrawal.address.choice': '引き出しアドレスを選択してください!',

  'order.sxsj': '有効時間',
  'order.ljsj': '即時有効',
  'order.dssj': '時間制限付き有効',

  'store.st.zt': 'ストアステータス',
  'store.st.zc': '通常',
  'store.st.zc.desc': 'あなたのストアは通常通りです、そのままにしておいてください',
  'store.st.yc': '例外',
  'store.st.yc.desc': 'あなたのストアには異常な注文があるか、顧客から苦情を受けています',
  'store.st.dj': 'フリーズ',
  'store.st.dj.desc': 'あなたのストアには一定の運営リスクがあり、資金は凍結されています',
  'store.st.jy': '無効',
  'store.st.jy.desc': 'あなたのストアはプラットフォーム関連の契約と規則に違反したため、無効になりました',

  'task.sy': 'ホームページ',
  'task.rwlbo': 'タスクリスト',
  'task.rwxqo': 'タスクの詳細',
  'task.ljcy': '今すぐ参加',
  'task.gdrw': 'その他のタスク',
  'task.rwlb': '取得タスクのリスト',
  'task.qbrw': 'すべてのタスク',
  'task.wcyd': '参加しました',
  'task.qdz': '注文の取得',
  'task.ywc': '完了',
  'task.djy': '取引される',
  'task.ddje': '注文金額',
  'task.cyrs': '参加者数',
  'task.ewsy': '追加の特典',
  'task.jzsj': '期限',
  'task.ckxq': '詳細を表示',
  'task.rwxq': 'タスクの詳細',
  'task.cylb': '参加リスト',
  'task.qdcyrw': 'この注文タスクに参加してもよろしいですか?',
  'task.sd': 'はい',
  'task.bue': 'いいえ',

  'task.wdsy': '収益',
  'task.wdfh': '配当',
  'task.wdqd': '注文を取得',
  'task.dd.leixin': '注文タイプ',
  'task.dd.qdrw': 'クイックオーダータスク',
  'task.dd.ptdd': '通常の順序',

  'flow.lltg': 'フロープロモーション',
  'flow.gmll': 'データ パッケージを購入',
  'flow.dqtc': '現在のパッケージ',
  'flow.tc1': 'パッケージ',
  'flow.sy2': '残り',
  'flow.tian3': '日',
  'flow.jlzs': 'レコードの総数',
  'flow.lltc': 'フローパッケージ',
  'flow.ll': 'フロー',
  'flow.gmsj': '購入時間',
  'flow.tcjg': 'パッケージ価格',
  'flow.sjzf': '実際の支払い',
  'flow.tcsm': 'パッケージの説明',
  'flow.tcsm.sm': 'このパッケージを購入すると、ストアの露出が増加し、より多くの顧客トラフィックを獲得できます。 より多くの注文を獲得し、店舗の収益を増やすため。 ',

  'flow.tcmc': 'パッケージ名',
  'flow.gmsc': '購入期間',
  'flow.zje': '合計金額',
  'flow.jzsj': '締め切り',
  'flow.gmrq': '購入日',
  'flow.tian1': '日',
  'flow.zhou1': '週',
  'flow.yue1': '月',

  'share.dj': 'レベル',
  'share.hz': '転送',
  'share.hzd': '転送先',
  'share.qbye': 'ウォレット残高',
  'share.tgje': 'プロモーション金額',
  'share.hzje': '送金金額',
  'share.qsrhzje': '送金額を入力してください',

  '_inv._title': '招待の説明',
  '_inv._t1': '1. 招待と招待された関係',
  '_inv._desc1': 'A は B を招待し、B は C を招待し、C は D を招待します。 最高レベルは 4 で、D が再度 E を招待した場合、E は A と何の関係もありません。 ',
  '_inv._t2': '2. 招待者は報酬を受け取ります',
  '_inv._desc2': 'A は注文 B で完了した合計金額の 4% を取得し、A は注文 C で完了した合計金額の 2% を取得し、A は注文 D で完了した合計金額の 1% を取得します',

  '_index._gm': '購入',
  '_index._sm': '説明: 店舗の露出を増やし、トラフィックを引き寄せます',

  '_n.dlyzc': 'ログインと登録',
  '_n.hy4': 'あと 4 つあります',
  '_n.ckgd': 'もっと見る',
  '_n._cxhzk': 'プロモーションと割引',
  '_n._cwyp': 'ペット用品',
  '_n._tjsp': '特別オファー',
  '_n._rmfl': '人気のカテゴリ',
  '_n._tjsp2': '推奨製品',
  '_n._rxsp': '推奨製品',
  '_n._rmsp': '人気のアイテム',
  '_n._gdsp': 'その他の製品',
  '_n._yzsj': '優良商人',

  '_n._zxsc': 'オンライン モール',
  '_n._fkyys': '支払いと配送',
  '_n._xsgz': '販売ルール',
  '_n._hhjth': '交換と返品',
  '_n._lxwm': 'お問い合わせ',
  '_n._sczc': 'モールポリシー',
  '_n._tkytj': '利用規約',
  '_n._tuzc': '返品ポリシー',
  '_n._zczc': 'サポートポリシー',
  '_n._yszc': 'プライバシー ポリシー',
  '_n._scjj': 'モールの紹介',
  '_n._ljgm': '今すぐ購入',

  '_n2._rxsp': '売れ筋商品',
  '_n2._pl': 'コメント',
  '_n2._sjxx': 'ビジネス情報',
  '_n2._rhsh': '商品の受け取り方法',
  '_n2._xsxd': 'オンライン注文',
  '_n2._jscldd': '専用のカスタマーサービス',
  '_n2._mfps': '送料無料',
  '_n2._2xsfh': 'ライトニング配信',
  '_n2._1d5tdd': '1 ～ 5 日以内に到着します',
  '_n2._tkzd': '速達メール',
  '_n2._ckfh': '倉庫から発送',
  '_n2._zcps': '近くの倉庫',
  '_n2._jsd': '受信ポイント',
  '_n2._qsjps': '全世界配信',
  '_n2._thtj': '返品条件',
  '_n2._thtjsm': '購入日から14日以内であれば、商品の交換または返品が可能です',
  '_n2._gycp': '製品について',
  '_n2._yc': '非表示',
  '_n2._tiao': '記事',
  '_n2._sypl': 'すべてのコメント',
  '_n2._lxfk': 'フィードバックを残す',
  '_n2._xzspgg': '製品仕様を選択してください',
  '_n2._qxdl': '最初にログインしてください',
  '_n2._rnsj': 'ご覧のとおり',
  '_n2._rx': 'ホットセール',

  '_n3._qrdd': '注文を確認します',
  '_n3._njsp': '{num} 個のアイテム',
  '_n3._ddzf': '注文の支払い',
  '_n3._wydlsp': 'ストアへのログインへようこそ',

  '_n3._sjwfgm': '販売者は商品を購入できません',
  '_n3._sjbnlt': '企業はチャットできません',
  '_chat._hc': '撤退',

  '_zc._sryxdz': 'メールアドレスを入力してください',
  '_zc._yxgscw': 'メール形式エラー',
  '_zc._qsrmm': 'パスワードを入力してください',
  '_zc._lcmmbxd': '2 つのパスワードは矛盾しています',
  '_zc._hdyzwtg': 'スライダーで確認してください',
  '_zc._qsryqm': '招待コードを入力してください',
  '_zc._yhzh': 'ユーザーアカウントを登録',
  '_zc._qzc': '登録に移動',
  '_zc._srdpm': '店舗名を入力してください',

  '_zc._gszz': '会社の資格',
  '_zc._ptzz': 'プラットフォーム関連の資格',

  '_dd._tijd': '注文を送信中',
  '_dd._xzdz': '配送先住所を選択してください',
  '_dd._zfz': '支払い中',

  '_wd._zf': '支払い',
  '_wd._cz': '再充電',
  '_wd._txsq': '出金申請',
  '_wd._ddsy': '注文収入',
  '_wd._cgzf': '購入',
  '_wd._txbh': '拒否',
  '_wd._txtg': '合格',
  '_wd._ddtk': '注文の返金',
  '_wd._yehz': '転送',
  '_wd._gmll': 'トラフィックを購入',
  '_wd._zjkc': '家賃控除',
  '_wd._fk': 'いいです',
  '_wd._ns': '税金',
  '_wd._bzj': 'マージン',

  '_xxtz._zntz': 'サイト通知',
  '_xxtz._fh': '戻る',
  '_xxtz._jzgd': 'さらにロード',
  '_xxtz._mysj': 'もうだめです',

  '_sjsy._jrdd': '今日の注文',
  '_sjsy._rxse': '日次売上',

  '_sjsy._xh': 'インデックス',

  '_wd._nbjy': '内部転送',
  '_wd._qsruid': '受取人のUIDを入力してください',
  '_wd._je': '金額',
  '_wd._qsrhzje': '送金額を入力してください',
  '_wd._skr': '受取人',
  '_wd._nbhzsm': 'プラットフォーム ユーザーに内部送金するには、受取人情報を注意深く確認してください',

  '_st._spxlphb': '商品売上ランキング',
  '_st._czsp': '検索をリセット',

  '_st._pljrgwc': '全員に参加',

  '_sj._yxz': '選択済み',
  '_sj._hjzje': '合計合計金額',
  '_sj._zdcr': '自動的に入金',

  '_wd._ddfh': '配当',

  '_dd._csqx': '注文のキャンセル',

  '_tx._yhktx': '銀行引き落とし',
  '_tx._sryhmc': '銀行名を入力してください',
  '_tx._srskrmc': '受取人の名前を入力してください',
  '_tx._srskzh': '支払い口座番号を入力してください',
  '_tx._sryhluh': '銀行ルーティング番号を入力してください',
  '_tx._sryhdm': '銀行コードを入力してください',
  '_tx._sryhdz': '銀行の住所を入力してください',
  '_tx._txje': '出金金額',
  '_tx._srtxje': '出金金額を入力してください',
  '_tx._txkc': '出金手数料 {_sxf}%',

  '_yye._yyye': '月次売上',
  '_yye._ysy': '月収',

  '_sj._hjzsy': '総収入総額',

  '_yhdl._dl': 'ログイン',
  '_yhdl._zc': 'アカウントの作成',

  '_zdy._24zrkf': '年中無休でサポートさせていただきます',
  '_zdy._zhsz': 'アカウント設定',
  '_zdy._mfps': '期間限定で、一部の地域では数千点の商品を 2 営業日以内に無料配送できます。 ',
  '_zdy._gmsp': '新製品を購入する',
  '_zdy._czyh': 'お得なセール、お気に入りの商品をお楽しみください',
  '_zdy._xsgw': '期間限定ショッピング! 5 月 12 日正午（東部標準時）までにご注文いただくと送料無料となります。 専用車でご自宅までお届け！ ',
  '_zdy._ssgjc': 'キーワード検索を入力してください',

  '_zdy._rmjx': 'あなたのおすすめ',

  '_dd._plcz': 'バッチ操作',
  '_dd._plcg': '一括購入',
  '_dd._qgxcgd': '購入する必要がある注文を確認してください',
  '_dd._yxddbcg': '選択した注文は購入する必要はありません',
  '_dd._qdyplcgm': '選択した注文を一括購入してもよろしいですか? ,数量: {n}',
  '_dd._qdcg': '購入を確認',
  '_dd._qx': 'キャンセル',
  '_dd._plcgwc': '一括購入が完了しました、成功しました: {s}/{t}',
  '_dd._zzcgz': '購入中',
  '_st._czhk': 'リチャージして返済する',
  '_st._dkje': '融資金額',
  '_st._dkjl': '融資履歴',
  '_st._hkfs': '返済',
  '_st._hkje': '返済額',
  '_st._jrong': 'モールファイナンス',
  '_st._ljsq': 'すぐに適用する',
  '_st._qrshkje': '返済額を入力してください',
  '_st._qsrdkje': '融資金額を入力してください',
  '_st._shz': '検討中',
  '_st._sqdklx': 'ローン',
  '_st._sqhk': '返済を申請する',
  '_st._sqhklx': '返済',
  '_st._sqjk': 'ローンを申し込む',
  '_st._sqlx': 'タイプ',
  '_st._ybh': '拒否する',
  '_st._yjk': '借りた',
  '_st._ytg': '合格',
  '_st._zzhk': '返済保留中',

  '_st._dqed': '融資金額',
  '_st._ccdked': '融資限度額を超えることはできません',
  '_st._tjsqz': '申請中です',
  '_st._dklx': '関心',
  '_st._dkll': 'ローン金利:{n}%',
  '_kdts._ts': '{t}日間営業',
  '_jltg._tgje': '広告投資',

  '_st._yqk': '延滞金',
  '_st._qb': 'すべて',

  '_spxx._spbm': 'コード',

  '_scrz._yyzz': 'ビジネスライセンス',

  '_sjhb._sjbt': 'クーポン',
  '_sjhb._kfxd': 'クーポンリスト',
  '_sjhb._fxlb': '共有リスト',
  '_sjdhb._hbje': '宗派',
  '_sjdhb._fxzs': '株式の総数',
  '_sjdhb._fxcz': '操作',
  '_sjdhb._fxyhj': '共有',
  '_sjdhb._gqsj': '有効期限',
  '_sjdhb._lqsj': '収集時間',
  '_sjdhb._lqbm': 'エンコーディング',
  '_sjdhb._wlq': '未受信',
  '_sjdhb._wsy': '使用されません',
  '_sjdhb._ysy': '使用済み',
  '_sjdhb._ylq': '受信しました',
  '_sjdhb._ygq': '期限切れ',
  '_sjdhb._fuzi': 'コピー',
  '_sjdhb._lqyhj': 'クーポン',
  '_sjdhb._lqlqyhj': 'クーポンを受け取る',
  '_sjdhb._yhjbm': 'クーポンコード',
  '_sjdhb._ljlq': '今すぐ入手',

  '_sjdhb._hqgdyhj': 'さらにクーポンを入手',
  '_sjdhb._nddzyj': 'あなたのメールアドレス',
  '_sjdhb._dy': '購読',
  '_sjdhb._zffs': '支払い方法',
  '_sjdhb._zfjs': 'ホールセール グローバル ステーションのユーザーは世界 112 か国から来ており、決済に USDT/ETH/BTC を使用しています。 USDT/ETH/BTC は、待ち時間なし、国際手数料なしで世界中で即座に低コストの取引を可能にするボーダレス取引方法です。 ',
  '_sjdhb._wzbq': '著作権 2023 卸売限定',

  '_sjdk.yhqbt': '割引アクティビティ',
 '_sjdk.yhq': 'クーポン',
 '_sjdk.dkq': 'マーチャントクーポン',

 '_sjdk._gmzk': '購入割引',
 '_sjdk._yhez': '割引額',
 '_sjdk._yhsmwa': '購入割引額。購入者に任意の額面のクーポンを提供して注文金額を差し引くことで、購入者に商品を購入してもらい、店舗の売上を増やすことができます。 ',

 '_sjdk._yuee': 'バランス',
 '_sjdk._gmyue': '購入',
 '_sjdk._shoujia': '価格',
 '_sjdk._zsdx': 'ギフト先',
 '_sjdk._zsyhj': 'クーポンをプレゼント',
 '_sjdk._yhjje': '金額',
 '_sjdk._sryhjje': 'カード金額を入力してください',
 '_sjdk._xftj': '条件',
 '_sjdk._srxftj': '注文金額条件を入力してください',
 '_sjdk._yhqtj': '注意: 注文金額は条件を満たしている必要があります',
 '_sjdk._ljzs': 'ギフト',
 '_sjdk._sysj': '使用時間',
 '_sjdk._zssj': 'ギフトタイム',
 '_sjdk._wsy': '使用されていません',
 '_sjdk._yss': '使用済み',
 '_sjdk._qrzs': '寄付方法',
 '_sjdk._qzs': 'プレゼントへ移動',
 '_sjdk._yhjjl': 'クーポンレコード',
 '_sjdk._syyhq': 'クーポン',
 '_sjdk._xfmje': '金額 >= ${n}',
}
