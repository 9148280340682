export default {
  'nav.account': '個人中心',
  'nav.language': '簡體中文',
  'signup.success': '提交成功',

  //error
  'contact.empty': '聯繫方式不能為空',
  'phone.country.empty': '電話前綴不能為空',
  'phone.empty': '電話不能為空',
  'email.valid': '請輸入正確的郵箱',

  // base
  'base.success': '成功',
  'base.save': '保存',
  'base.slide': '請拉動滑條驗證',
  'base.send.code': '發送驗證碼',
  'base.send.time': '({time}) 重發驗證碼',
  'base.send.success': '發送成功',
  'base.view.more': '瀏覽更多',
  'base.Language': '語言',
  'base.previous': '上一頁',
  'base.next': '下一頁',
  'base.total.page': '共 {page} 頁',
  'base.go.to.page': '轉到頁面',
  'base.go': '前往',
  'base.followers': '關注量',
  'base.follow': '關注',
  'base.following': '已關注',
  'base.visit.store': '瀏覽店鋪',
  'base.contact': '聯繫我們',
  'base.delivery': '預計交貨時間:1-7 天',
  'base.search': '搜索',
  'base.search.text': '輸入關鍵字搜索',
  'base.server': '在線客服',

  //index
  'text.categories.title': '商品分類',
  'text.welcome': '歡迎購物!',
  'text.wishlist': '喜歡列表',
  'text.account': '個人中心',
  'text.orders': '訂單',
  'text.messages': '消息',
  'text.suggest.title': '獨家優惠',
  'text.suggest.content': '只為新的全球至尊會員!',
  'text.super.deals': '頂級產品,令人難以置信的價格.',

  //signup
  'signup.register': '註冊',
  'signup.signin': '登錄',
  'signup.store.signin': '商家登錄',
  'signup.sign.out': '註銷',
  'signup.email': '郵箱地址',
  'signup.password': '密碼',
  'signup.qr.password': '確認密碼',
  'signup.confirm.password': '確認密碼',
  'signup.forgot.password': '忘記密碼',
  'signup.invitation': '邀請碼',
  'signup.char': '字符',
  'signup.contains': '包含數字、字母或符號',
  'signup.qr.contains': '兩次密碼不一致',
  'signup.create.account': '創建賬戶',
  'signup.agree': '創建帳戶，則同意',
  'signup.member.agreement': '會員協議',
  'signup.and': '與',
  'signup.privacy.policy': '隱私政策',
  'signup.email.code': '郵箱驗證',
  'signup.last.step': '最後一步',
  'signup.send.email': '請輸入發送至{email}的4位數代碼',
  'signup.modify.email': '修改郵箱',
  'signup.verify.email': '驗證郵箱',
  'signup.have.store': '有商家賬號',
  'signup.goto.signin': '商家登錄',
  'signup.no.store': '沒有商家賬號',
  'signup.goto.store': '註冊商家',
  'signup.next': '下一步',
  'signup.your.email': '你的郵箱',
  'signup.code.text': '驗證碼',
  'signup.submit.signup': '立即註冊',
  'signup.smrz': '實名認證',
  'signup.derb': '第二步',
  'signup.qsrxm': '請輸入證件姓名',
  'signup.qsrhm': '請輸入證件號碼',

  //forgot
  'forgot.title': '重置密碼',
  'forgot.btn.check': '驗證郵箱',
  'forgot.reset.now': '立即重置',

  //store
  'store.info.open': '開店{y}週',

  // footer
  'footer.great.value': '巨大的價值',
  'footer.great.value.desc': '我們為超過1億件商品提供具有競爭力的價格.',
  'footer.shopping': '全球購物',
  'footer.shopping.desc': '我們向200多個國家和地區發貨,我們的網站提供7種語言.',
  'footer.safe.payment': '安全支付',
  'footer.safe.payment.desc': '使用世界上最流行和最安全的支付方式進行支付.',
  'footer.shop.with.confidence': '放心購物',
  'footer.shop.with.confidence.desc': '我們的買家保護政策涵蓋您的整個購買過程.',
  'footer.help.center': '幫助中心',
  'footer.help.center.desc': '全天候協助,打造順暢的購物體驗.',
  'footer.terms.conditions': '條款和條件',
  'footer.return.policy': '退貨政策',
  'footer.support.policy': '支持政策',
  'footer.privacy.policy': '隱私政策',
  'footer.be.seller': '成為商家',
  'footer.apply.now': '立即申請',
  'footer.stay.connected': '保持聯繫',

  'footer.about.us': '關於我們',
  'footer.about.company': '公司簡介',
  'footer.about.video': '視頻介紹',
  'footer.contact': '聯繫方式',

  'footer.my.account': '我的賬戶',
  'footer.my.logout': '退出登錄',
  'footer.my.order': '訂單歷史',
  'footer.my.wish': '我的願望清單',
  'footer.my.join': '成為會員合作夥伴',
  'footer.email': '電子郵箱',
  'footer.gfemail': '官方郵箱',
  'footer.fwemail': '服務郵箱',
  'footer.address': '地址',

  'apply.success': '申請成功',
  'apply.success.desc': '已申請成功,前往登錄賬戶',

  // categories
  'category.title': '相關分類',
  'category.all': '所有分類',

  // detail
  'detail.store.home': '店舖首頁',
  'detail.sale.items': '商品列表',
  'detail.recommend': '推薦',
  'detail.orders': '銷量',
  'detail.quantity': '數量',
  'detail.pieces.available': '可用',
  'detail.delivery': '送貨',
  'detail.free.shipping': '免運費',
  'detail.estimated.delivery': '預計到達',
  'detail.days': '天',
  'detail.buy.now': '購買',
  'detail.add.to.cart': '加入購物車',
  'detail.buyer.protection': '買家保護',
  'detail.money.guarantee': '退款保證',
  'detail.refund.desc': '如果物品與描述不符或未送達，可獲得全額退款',
  'detail.description': '商品描述',
  'detail.customer.reviews': '顧客評論',
  'detail.specifications': '商品規格',
  'detail.top.selling.products': '暢銷商品',
  'detail.recommended.for.you': '為你推薦',
  'detail.sold': '銷量',
  'detail.receipt': '確認收貨',
  'detail.receipt.title': '確認收到訂單？',
  'detail.receipt.content': '確認後，交易訂單完成',
  'detail.comment': '評論',
  'detail.refund.title': '确认申请退款',
  'detail.refund.content': '确认后，订单将申请退款',
  'detail.sqtk': '申请退款',

  // confirm
  'confirm.shipping.address': '收件地址',
  'confirm.change': '改變',
  'confirm.payment.methods': '支付方式',
  'confirm.summary': '結算',
  'confirm.total.item.costs': '商品合計',
  'confirm.total': '總計',
  'confirm.checkout': '去結算',
  'confirm.place.order': '立即下單',
  'confirm.pay.now': '立即支付',
  'confirm.order.desc': "單擊'下訂單'後，我確認我已閱讀並確認",
  'confirm.order.policy': '所有條款和政策',
  'confirm.payment': '全球購物確保您的信息和付款安全',

  // address
  'address.title': '收件地址',
  'address.default': '默認',
  'address.edit': '編輯',
  'address.delete': '刪除',
  'address.new': '創建新地址',
  'address.contact': '聯繫人',
  'address.address': '地址',
  'address.phone': '電話號碼',
  'address.set.default': '設置默認',
  'address.confirm': '確認',
  'address.cancel': '取消',
  'address.del.title': '確認刪除地址?',
  'address.del.content': '您確定要刪除此送貨地址嗎?',

  'payment.method': '支付方式',

  // Shopping Cart
  'shopping.title': '購物車',
  'shopping.back.buy': '返回特價購物',
  'shopping.start.shopping': '開始購物',

  // member
  'member.account': '個人中心',
  'member.overview': '概述',
  'member.orders': '訂單',
  'member.payment': '我的錢包',
  'member.address': '收貨地址',
  'member.wishlist': '喜歡列表',
  'member.followlist': '關注列表',
  'member.message.center': '消息中心',
  'member.setting': '個人設置',
  'member.shop.info': '商舖信息',
  'member.shop.setting': '商舖設置',
  'member.order.notify': '新訂單通知',
  'member.order.tips': '通過發送郵件到商家賬號郵箱',

  // member.order
  'member.order.title': '我的訂單',
  'member.order.view.all': '查看全部',
  'member.order.all': '全部',
  'member.order.unpaid': '未支付',
  'member.order.paid': '已支付',
  'member.order.procurement': '待採購',
  'member.order.seller.paid': '已採購',
  'member.order.processing': '待處理',
  'member.order.shipped': '配送中',
  'member.order.completed': '已完成',
  'member.order.refund': '已退款',
  'member.order.all.time': '全部',
  'member.order.empty': '暫無訂單',
  'member.order.date': '日期',
  'member.order.purchase.date': '採購',
  'member.order.cpoy': '複製',
  'member.order.id': '訂單',
  'member.order.detail': '訂單詳情',
  'member.order.logistics': '物流信息',

  // member.payment
  'member.payment.title': '我的錢包',
  'member.wallet.balance': '錢包餘額',
  'member.crypto.recharge': '充值',
  'member.crypto.withdrawal': '提現',
  'member.crypto.bank': '銀行卡',
  'member.wallet.record': '存幣記錄',
  'member.bankcard.record': '銀行卡記錄',
  'member.withdrawal.record': '提現記錄',
  'member.income.record': '收益記錄',
  'member.transaction.record': '交易記錄',
  'member.wallet.freeze': '凍結資金',
  'member.wallet.profit': '預計收益',

  //recharge
  'recharge.currency': '幣種',
  'recharge.protocol': '協議',
  'recharge.qrcode': '二維碼',
  'recharge.address': '錢包地址',
  'recharge.copy': '複製地址',
  'recharge.ok.text': '確認',
  'recharge.cancel.text': '取消',

  //bank
  'bank.recharge.title': '銀行卡充值',
  'bank.title': '銀行名稱',
  'bank.name': '收款人姓名',
  'bank.account': '收款賬號',
  'bank.routing': '銀行路由號',
  'bank.code': '銀行代碼',
  'bank.bankname': '銀行名稱',
  'bank.bankaddress': '銀行地址',
  'bank.ok.text': '下一步',
  'bank.ok.prev': '上一步',
  'bank.submit': '立即提交',
  'bank.amount': '充值金額',
  'bank.amount.desc': '請輸入充值金額',
  'bank.type': '類型',
  'bank.usd': '美元',
  'bank.eur': '歐元',
  'bank.receipt.number': '回執單號',
  'bank.receipt.number.desc': '請輸入交易回執單號',
  'bank.credential.picture': '憑證圖片',
  'bank.credential.picture.desc': '請上傳憑證圖片',
  'bank.remark': '說明',
  'bank.upload': '上傳圖片',
  'bank.text.title': '注意事項',
  'bank.text.t1': '1、轉賬不要任何備註,不填',
  'bank.text.t2': '2、香港戶國家選擇香港,不要加中國',
  'bank.text.t3': '3、美國匯款,工作日,下午三點前匯歐洲匯款,銀行上班時間可匯!',
  'bank.text.t4': '4、到款時間T+1,歐洲最遲T+3到款',
  'bank.text.t5': '5、轉款之前告知平台,確定賬戶是否可用,如果封戶，不賠付.',
  'bank.text.t6': '6、通過銀行卡入金一切已客服信息為準.',

  //banklist
  'bank.list.title': '銀行名稱',
  'bank.list.code': '銀行代碼',
  'bank.list.amount': '轉賬金額',
  'bank.list.number': '回執單號',
  'bank.list.img': '憑證圖片',
  'bank.list.status': '狀態',
  'bank.list.time': '時間',
  'bank.list.status1': '審核中',
  'bank.list.status2': '已通過',
  'bank.list.status3': '已駁回',

  // Withdrawal
  'withdrawal.address.desc': '請輸入錢包地址!',
  'withdrawal.number': '數量',
  'withdrawal.real.number': '實際到賬',
  'withdrawal.number.desc': '請輸入提現數量!',
  'withdrawal.btn.all': '全部',
  'withdrawal.balance': '餘額',
  'withdrawal.commission': '佣金',
  'withdrawal.actual.amount': '到賬',
  'withdrawal.notice': '提示',
  'withdrawal.notice.text': '轉賬前,請確認收款地址信息正確無誤.資產一經轉出,不可退回.',
  'withdrawal.notice.content': '{name}({cp_name}) 佣金:當前市值{fee_rate}%/筆,最低標準:{fee_min} {name}/筆',
  'withdrawal.submit': '提交',
  'withdrawal.choice': '選擇加密貨幣',
  'withdrawal.yzm': '驗證碼',
  'withdrawal.fs': '發送',
  'withdrawal.qsryzm': '請輸入郵箱驗證碼',

  // recive
  'recive.method': '協議',
  'recive.amount': '金額',
  'recive.address': '地址',
  'recive.date': '時間',
  'recive.status': '狀態',
  'recive.create.at': '交易時間',
  'recive.type': '類型',
  'recive.befor': '交易之前',
  'recive.balance': '餘額',
  'recive.freeze': '凍結',
  'recive.review': '審核中',
  'recive.success': '已通過',
  'recive.reject': '已駁回',

  // advertise
  'advertise.title': '推廣中心',
  'advertise.shop.title': '店鋪推廣',
  'advertise.shop.status': '狀態',
  'advertise.shop.expired': '已過期',
  'advertise.shop.promotion': '推廣中',
  'advertise.shop.expire.date': '到期時間',
  'advertise.shop.renew': '廣告續費',
  'advertise.shop.payable': '應付金額',
  'advertise.shop.explanation': '推廣說明',
  'advertise.shop.text': '參與平台廣告推廣，提升店鋪曝光度，促進交易訂單量',
  'advertise.shop.paynow': '立即續費',
  'advertise.shop.modal.title': '確認支付',
  'advertise.shop.modal.desc': '確認為支付推廣費用',
  'advertise.shop.modal.btn': '確認支付',

  //Wish List
  'wishlist.title': '喜歡列表',
  'wishlist.delete': '刪除',
  'wishlist.orders': '銷量',

  //Follow List
  'followlist.title': '關注列表',
  'followlist.delete': '刪除',
  'followlist.follow': '關注',

  // store
  'store.dashboard': '儀錶盤',
  'store.products': '產品',
  'store.products.list': '產品列表',
  'store.products.reviews': '產品回复',
  'store.orders': '訂單',
  'store.wallet': '錢包',
  'store.message': '消息中心',
  'store.setting': '設置',
  'store.order.total.profit': '預計營業總收益',

  //dashboard
  'dashboard.store.hour.views': '實時訪問量',
  'dashboard.store.today.views': '今日訪問量',
  'dashboard.product.total': '產品總量',
  'dashboard.product.today': '今日新增',
  'dashboard.order.total': '訂單總量',
  'dashboard.sales.total': '預計營業額',
  'dashboard.sales.real': '實際營業額',
  'dashboard.sales.pay': '總支出',
  'dashboard.sales.profit': '總收益',
  'dashboard.commission.total': '總收益',
  'dashboard.commission.today': '今日收益',
  'dashboard.order.sales': '商品銷售量',
  'dashboard.sales.list': '銷售排行',
  'dashboard.goods.cate.rate': '商品分類占比',
  'dashboard.goods.wish': '喜歡商品排行',
  'dashboard.january': '1月',
  'dashboard.february': '2月',
  'dashboard.march': '3月',
  'dashboard.april': '4月',
  'dashboard.may': '5月',
  'dashboard.june': '6月',
  'dashboard.july': '7月',
  'dashboard.august': '8月',
  'dashboard.september': '9月',
  'dashboard.october': '10月',
  'dashboard.november': '11月',
  'dashboard.december': '12月',

  // products
  'products.add.new': '添加新產品',
  'products.add.from.warehouse': '從倉庫添加產品',
  'products.delete': '刪除',
  'products.add': '添加',
  'products.table.img': '圖片',
  'products.table.name': '商品名稱',
  'products.table.category': '所屬分類',
  'products.table.wish': '喜歡',
  'products.table.stock': '庫存',
  'products.table.price': '價格',
  'products.table.profit': '收益',
  'products.table.action': '操作',
  'products.search.category': '按分類搜索',
  'products.back.product': '返回產品列表',
  'products.total': '商品總計',
  'products.yes': '是的',
  'products.no': '取消',
  'products.batch.add': '批量增加',
  'products.ask.add': '確定要增加商品嗎?',
  'products.batch.delete': '批量刪除',
  'products.ask.delete': '確定要刪除商品嗎?',
  'products.top': '置頂',
  'products.syzd': '首页置顶',
  'products.zdwz': '置顶位置',
  'products.t1': '推广',
  'products.t2': '优选',
  'products.t3': '精品',
  'products.t4': '无',

  // reviews
  'reviews.title': '產品評論',
  'reviews.product.name': '商品',
  'reviews.user.name': '用戶',
  'reviews.star': '評分',
  'reviews.comment': '評論內容',
  'reviews.sku': '商品規格',
  'reviews.imgs': '圖集',
  'reviews.created': '日期',

  // store.order
  'store.order.purchase': '立即採購',
  'store.order.purchase.desc': '確定要付款採購該產品?',
  'store.order.purchase.yes': '立即支付',
  'store.order.purchase.no': '取消',
  'store.order.desc': '採購該訂單需要支付貨款，訂單完成後獲得收益。',
  'store.order.no': '訂單號',
  'store.order.number': '數量',
  'store.order.buyer': '買家',
  'store.order.total': '總金額',
  'store.order.will.earning': '收入',
  'store.order.profit': '收益',
  'store.order.dividends': '分紅',
  'store.order.payment.status': '支付狀態',
  'store.order.seller.buy.status': '採購狀態',
  'store.order.status': '訂單狀態',
  'store.order.date': '訂單日期',
  'store.order.purchase.date': '採購日期',
  'store.order.action': '操作',
  'store.order.purchase.amount': '採購金額',

  //income
  'income.create.at': '記錄時間',
  'income.order.sn': '訂單編號',
  'income.realpay': '訂單金額',
  'income.profit': '收益',

  //Setting
  'setting.avatar': '商家頭像',
  'setting.upload': '上傳',
  'setting.shop.name': '店鋪名稱',
  'setting.shop.phone': '聯繫電話',
  'setting.shop.address': '店鋪地址',
  'setting.shop.save': '保存',
  'setting.upload.pic': '上傳圖片',
  'setting.send.pic': '發送圖片',

  //beseller
  'beseller.title': '申請成為商家',
  'beseller.account': '賬號信息',
  'beseller.store': '店鋪信息',
  'beseller.store.name': '店鋪名稱',
  'beseller.store.address': '店鋪地址',

  //savehome
  'store.home.title': '首頁設置',
  'store.home.topimg': '頂部圖',
  'store.home.banner': '輪播圖',
  'store.home.up3': '備註：最少上傳3張',
  'store.upload.more': '上傳多圖',
  'store.home.columns': '欄目',
  'store.home.bgimg': '背景圖片',
  'store.goods.remark': '備註：每行5個商品，每個板塊最多10個商品',
  'store.home.select': '選擇商品',
  'store.home.add': '增加欄目',

  //個人信息
  'setting.update.user': '個人信息',
  'setting.update.info': '修改信息',
  'setting.user.avatar': '用戶頭像',
  'setting.user.nickname': '用戶暱稱',
  'setting.user.nickname.desc': '請輸入用戶暱稱',

  'setting.safe.title': '安全信息',
  'setting.user.passwd': '修改密碼',
  'setting.passwd.title1': '舊密碼',
  'setting.passwd.desc1': '請輸入舊密碼',
  'setting.passwd.title2': '新密碼',
  'setting.passwd.desc2': '請輸入新密碼',
  'setting.passwd.title3': '確認密碼',
  'setting.passwd.desc3': '請輸入確認密碼',
  'setting.invitation': '邀請朋友',
  'setting.copy': '複製',

  'adv.add.products': '增加推廣商品',
  'adv.list.title': '商品推廣',
  'adv.type': '推廣類型',
  'adv.begin.time': '推廣時間',
  'adv.end.time': '結束時間',
  'adv.status': '狀態',
  'adv.status.s1': '推廣中',
  'adv.status.s2': '已結束',
  'adv.add.back': '返回已推廣列表',
  'adv.recharge': '充值推廣點數',
  'adv.select.goods': '選擇商品',
  'adv.select.btn': '推廣商品',
  'adv.recharge.title': '充值推廣點數',
  'adv.recharge.balance': '點數餘額',
  'adv.point': '點',
  'adv.point.rate': '點數比例',
  'adv.recharge.amount': '充值金額',
  'adv.recharge.input.amount': '輸入充值金額',
  'adv.select.mod': '選擇板塊',
  'adv.mod1': '推薦欄目',
  'adv.mod2': '優選欄目',
  'adv.mod3': '熱銷欄目',
  'adv.mod4': '精品列表',
  'adv.mod5': '分類列表',
  'adv.mod6': '詳情推廣',
  'adv.ge': '數量',
  'adv.xs': '小時',
  'adv.xzbk': '選擇板塊',
  'adv.syme': '剩餘名額',
  'adv.tgsc': '推廣時長',
  'adv.tgjg': '推廣價格',
  'adv.syye': '當前餘額',
  'adv.tgye': '推廣餘額',
  'adv.yj1': '預計 ',
  'adv.yj2': '開始推廣',
  'adv.pay.point': '消費點數',

  'wallet.ykcsxf': '已扣除手續費',
  'wallet.sxf': '手續費',
  'wallet.zxje': '最小金額',
  'wallet.24hxe': '24小時限額',
  'wallet.desc.text': '官方在任何情況下都不會要求您向某一賬戶轉賬，也不會向您索要驗證碼。請勿參與代買，洗錢、非法集資等違法行為，謹防網絡詐騙',
  'wallet.cunbi.text': '您只能向此地址充入{name},充入其他資產將無法找回',

  'credit.title': '信用額度',
  'credit.ed': '額度',
  'credit.hk': '還款',
  'credit.kyye': '可用餘額',
  'credit.dqqk': '當前欠款',
  'credit.hkje': '還款金額',
  'credit.hkje.desc': '請輸入還款金額',
  'credit.cgje': '採購金額',
  'credit.qrz': '去認證',
  'credit.srxyj': '輸入信用支付金額',

  'store.gzl': '關注量',
  'store.wxz': '無限制',

  'auth.smrz': '實名認證',
  'auth.rzlx': '類型',
  'auth.sfzm': '身份證正面',
  'auth.sffm': '身份證反面',
  'auth.zsxm': '真實姓名',
  'auth.zjhm': '證件號碼',
  'auth.yyzz': '營業執照',
  'auth.gsmc': '公司名稱',
  'auth.shz': '審核中',
  'auth.ytg': '已通過',
  'auth.ybh': '已駁回',
  'auth.zt': '狀態',
  'auth.gr': '個人',
  'auth.gs': '企業',
  'auth.ljtj': '申請認證',
  'auth.wrz': '未認證',

  'credit.qts': '查看說明',
  'store.spss': '商品上限',
  'store.dpfh': '店鋪分紅',
  'store.qydj': '權益等級',
  'store.level': '級',
  'store.jian': '件',

  'order.notify.email': '由於郵箱風控,平台訂單提醒郵件發送頻繁會被攔截,為了及時收到,建議將{email}添加到登錄郵箱白名單',

  'setting.sjxx': '商家信息',
  'setting.smrz': '實名認證',
  'setting.aqzx': '安全中心',
  'setting.tzxx': '通知信息',
  'setting.dpzx': '裝修首頁',
  'setting.sjqy': '商家權益',
  'setting.sjdjqy': '商家等級權益說明',

  'setting.fhbky': '當前分紅不可用',
  'setting.spgzyzy': '商舖規則與指引',

  'desc.dqqy': '當前權益',
  'desc.xjqy': '下級權益',
  'desc.sjqy_0': '店鋪信用額度',
  'desc.sjqy_1': '店鋪分紅',
  'desc.sjqy_2': '可發布商品數量',

  'share.yqlb': '邀請列表',
  'share.zmj': '總賣家',
  'share.zdd': '總訂單',
  'share.zsy': '總收益',
  'share.wcl': '未處理',
  'share.ycl': '已處理',
  'share.ywc': '已完成',
  'share.dpmc': '店鋪名稱',
  'share.ddl': '訂單量',
  'share.jjsy': '經紀收益',
  'share.zts': '總條數',

  'chat.state': '狀態',
  'chat.zx': '在線',
  'chat.ljz': '連接中',

  'bind.wallet.title': '提幣地址',
  'bind.address.title': '添加地址',
  'bind.bj': '編輯',
  'bind.sc': '刪除',
  'bind.qrsc': '確定要刪除該地址?',
  'bind.qbdz': '錢包地址',
  'bind.tjsj': '添加時間',
  'bind.cz': '操作',
  'withdrawal.address.choice': '請選擇提現地址!',

  'order.sxsj': '生效時間',
  'order.ljsj': '立即生效',
  'order.dssj': '定時生效',

  'store.st.zt': '店铺状态',
  'store.st.zc': '正常',
  'store.st.zc.desc': '您的商铺一切正常，请继续保持',
  'store.st.yc': '异常',
  'store.st.yc.desc': '您的商铺存在部分的订单异常或收到客诉',
  'store.st.dj': '冻结',
  'store.st.dj.desc': '您的商铺存在一定的经营风险造成资金冻结',
  'store.st.jy': '禁用',
  'store.st.jy.desc': '您的商铺违返了平台相关协议与规则已禁用',

  'task.sy': '首页',
  'task.rwlbo': '任务列表',
  'task.rwxqo': '任务详情',
  'task.ljcy': '立即参与',
  'task.gdrw': '更多任务',
  'task.rwlb': '抢单任务列表',
  'task.qbrw': '全部任务',
  'task.wcyd': '我参与的',
  'task.qdz': '抢单中',
  'task.ywc': '已完成',
  'task.djy': '待交易',
  'task.ddje': '订单金额',
  'task.cyrs': '参与人数',
  'task.ewsy': '额外收益',
  'task.jzsj': '截止时间',
  'task.ckxq': '查看详情',
  'task.rwxq': '任务详情',
  'task.cylb': '参与列表',
  'task.qdcyrw': '确定要参与此订单任务吗?',
  'task.sd': '是的',
  'task.bue': '不',

  'task.wdsy': '收益',
  'task.wdfh': '分紅',
  'task.wdqd': '搶單',
  'task.dd.leixin': '訂單類型',
  'task.dd.qdrw': '搶單任務',
  'task.dd.ptdd': '普通訂單',

  'flow.lltg': '流量推廣',
  'flow.gmll': '購買流量套餐',
  'flow.dqtc': '當前套餐',
  'flow.tc1': '套餐',
  'flow.sy2': '剩餘',
  'flow.tian3': '天',
  'flow.jlzs': '記錄總數',
  'flow.lltc': '流量套餐',
  'flow.ll': '流量',
  'flow.gmsj': '購買時間',
  'flow.tcjg': '套餐價格',
  'flow.sjzf': '實際支付',
  'flow.tcsm': '套餐說明',
  'flow.tcsm.sm': '購買此套餐，將增加店舖的曝光率，獲得更大的客戶訪問流量。以此來獲得更多訂單，提高店鋪收益。 ',

  'flow.tcmc': '套餐名稱',
  'flow.gmsc': '購買時長',
  'flow.zje': '總金額',
  'flow.jzsj': '截止時間',
  'flow.gmrq': '購買日期',
  'flow.tian1': '天',
  'flow.zhou1': '週',
  'flow.yue1': '月',

  'share.dj': '等級',
  'share.hz': '劃轉',
  'share.hzd': '劃轉到',
  'share.qbye': '錢包餘額',
  'share.tgje': '推廣金額',
  'share.hzje': '劃轉金額',
  'share.qsrhzje': '請輸入劃轉金額',

  '_inv._title': '邀請說明',
  '_inv._t1': '1.邀請與被邀請關係',
  '_inv._desc1': 'A邀請B、B邀請C、C邀請D。最高4級,若D再邀請E, 則E與A無關。 ',
  '_inv._t2': '2.邀請人獲得獎勵',
  '_inv._desc2': 'A獲得B訂單完成總金額的4%，A獲得C訂單完成的總金額2%，A獲得D訂單完成的總金額1%',

  '_index._gm': '購買',
  '_index._sm': '說明：增加店鋪曝光率，吸引客流量',

  '_n.dlyzc': '登錄與註冊',
  '_n.hy4': '還有4個',
  '_n.ckgd': '查看更多',
  '_n._cxhzk': '促銷和折扣',
  '_n._cwyp': '寵物用品',
  '_n._tjsp': '特價商品',
  '_n._rmfl': '熱門分類',
  '_n._tjsp2': '推薦商品',
  '_n._rxsp': '推薦商品',
  '_n._rmsp': '熱門商品',
  '_n._gdsp': '更多商品',
  '_n._yzsj': '優質商家',

  '_n._zxsc': '在線商城',
  '_n._fkyys': '付款與運輸',
  '_n._xsgz': '銷售規則',
  '_n._hhjth': '換貨及退貨',
  '_n._lxwm': '聯繫我們',
  '_n._sczc': '商城政策',
  '_n._tkytj': '條款與條件',
  '_n._tuzc': '退貨政策',
  '_n._zczc': '支持政策',
  '_n._yszc': '隱私政策',
  '_n._scjj': '商城簡介',
  '_n._ljgm': '立即購買',

  '_n2._rxsp': '熱銷商品',
  '_n2._pl': '評論',
  '_n2._sjxx': '商家信息',
  '_n2._rhsh': '如何收貨',
  '_n2._xsxd': '線上下單',
  '_n2._jscldd': '專人客服',
  '_n2._mfps': '免費配送',
  '_n2._2xsfh': '閃電發貨',
  '_n2._1d5tdd': '1-5天內到達',
  '_n2._tkzd': '特快專遞',
  '_n2._ckfh': '從倉庫發貨',
  '_n2._zcps': '附近倉庫',
  '_n2._jsd': '收貨點',
  '_n2._qsjps': '全世界配送',
  '_n2._thtj': '退貨條件',
  '_n2._thtjsm': '自購買之日起 14 天內，您可以換貨或退貨',
  '_n2._gycp': '關於產品',
  '_n2._yc': '隱藏',
  '_n2._tiao': '條',
  '_n2._sypl': '所有評論',
  '_n2._lxfk': '留下反饋',
  '_n2._xzspgg': '請選擇商品規格',
  '_n2._qxdl': '請先登錄',
  '_n2._rnsj': '如你所見',
  '_n2._rx': '熱銷',

  '_n3._qrdd': '確認訂單',
  '_n3._njsp': '{num}件商品',
  '_n3._ddzf': '訂單支付',
  '_n3._wydlsp': '歡迎登錄店鋪',

  '_n3._sjwfgm': '商家不能購買商品',
  '_n3._sjbnlt': '商家不能聊天',
  '_chat._hc': '撤回',

  '_zc._sryxdz': '請輸入信箱',
  '_zc._yxgscw': '郵件格式錯誤',
  '_zc._qsrmm': '請輸入密碼',
  '_zc._lcmmbxd': '兩次密碼不一致',
  '_zc._hdyzwtg': '請透過滑動條驗證',
  '_zc._qsryqm': '請輸入邀請碼',
  '_zc._yhzh': '註冊用戶帳號',
  '_zc._qzc': '去註冊',
  '_zc._srdpm': '請輸入店名',

  '_zc._gszz': '公司資質',
  '_zc._ptzz': '平台相關資格',

  '_dd._tijd': '提交訂單中',
  '_dd._xzdz': '請選擇收貨地址',
  '_dd._zfz': '支付中',

  '_wd._zf': '支付',
  '_wd._cz': '充值',
  '_wd._txsq': '提現申請',
  '_wd._ddsy': '訂單收益',
  '_wd._cgzf': '採購',
  '_wd._txbh': '駁回',
  '_wd._txtg': '透過',
  '_wd._ddtk': '訂單退款',
  '_wd._yehz': '劃轉',
  '_wd._gmll': '購買流量',
  '_wd._zjkc': '租金扣除額',
  '_wd._fk': '罰款',
  '_wd._ns': '納稅',
  '_wd._bzj': '保證金',

  '_xxtz._zntz': '站內通知',
  '_xxtz._fh': '返回',
  '_xxtz._jzgd': '載入更多',
  '_xxtz._mysj': '沒有了',

  '_sjsy._jrdd': '今日訂單',
  '_sjsy._rxse': '每日銷售額',

  '_sjsy._xh': '序號',

  '_wd._nbjy': '內部劃轉',
  '_wd._qsruid': '請輸入收款人UID',
  '_wd._je': '金額',
  '_wd._qsrhzje': '請輸入劃轉金額',
  '_wd._skr': '收款人',
  '_wd._nbhzsm': '向平台使用者內部劃轉,請仔細核對收款人資訊',

  '_st._spxlphb': '商品銷售排行榜',
  '_st._czsp': '重置搜尋',

  '_st._pljrgwc': '全部加入',

  '_sj._yxz': '已選取',
  '_sj._hjzje': '合計總金額',
  '_sj._zdcr': '自動存入',

  '_wd._ddfh': '分紅',

  '_dd._csqx': '訂單取消',

  '_tx._yhktx': '銀行提現',
  '_tx._sryhmc': '請輸入銀行名稱',
  '_tx._srskrmc': '請輸入收款人名稱',
  '_tx._srskzh': '請輸入收款帳號',
  '_tx._sryhluh': '請輸入銀行路由號碼',
  '_tx._sryhdm': '請輸入銀行代碼',
  '_tx._sryhdz': '請輸入銀行地址',
  '_tx._txje': '提現金額',
  '_tx._srtxje': '請輸入提現金額',
  '_tx._txkc': '提現手續費{_sxf}%',

  '_yye._yyye': '月銷售額',
  '_yye._ysy': '月收益',

  '_sj._hjzsy': '合計總收益',

  '_yhdl._dl': '登入',
  '_yhdl._zc': '建立帳戶',

  '_zdy._24zrkf': '我們24/7隨時為您提供協助',
  '_zdy._zhsz': '账户设置',
  '_zdy._mfps': '在有限的時間內，您可以在選定區域內享受數千種商品的免費 2 個工作天送貨服務。',
  '_zdy._gmsp': '選購新品',
  '_zdy._czyh': '超值优惠，尽享您喜爱的商品',
  '_zdy._xsgw': '限時購物！ 在美國東部時間 5 月 12 日中午之前訂購免運費。 專人專車送上門！',
  '_zdy._ssgjc': '输入关键词搜索',

  '_zdy._rmjx': '您的热门精选',

  '_dd._plcz': '批次操作',
  '_dd._plcg': '大量採購',
  '_dd._qgxcgd': '請勾選需要採購的訂單',
  '_dd._yxddbcg': '已選單不需要購買',
  '_dd._qdyplcgm': '確定大量採購已選擇的訂單嗎？ ,數量:{n}',
  '_dd._qdcg': '確定採購',
  '_dd._qx': '取消',
  '_dd._plcgwc': '大量採購完成，成功:{s}/{t}',
  '_dd._zzcgz': '採購中',

  '_st._czhk': '儲值還款',
  '_st._dkje': '貸款金額',
  '_st._dkjl': '貸款記錄',
  '_st._hkfs': '還款方式',
  '_st._hkje': '還款金額',
  '_st._jrong': '商城金融',
  '_st._ljsq': '立即申請',
  '_st._qrshkje': '請輸入還款金額',
  '_st._qsrdkje': '請輸入貸款金額',
  '_st._shz': '審核中',
  '_st._sqdklx': '貸款',
  '_st._sqhk': '申請還款',
  '_st._sqhklx': '還款',
  '_st._sqjk': '申請貸款',
  '_st._sqlx': '類型',
  '_st._ybh': '拒絕',
  '_st._yjk': '已藉款',
  '_st._ytg': '透過',
  '_st._zzhk': '待還款',

  '_st._dqed': '貸款額度',
  '_st._ccdked': '不能超出貸款額度',
  '_st._tjsqz': '提交申請中',
  '_st._dklx': '利息',
  '_st._dkll': '貸款利率:{n}%',
  '_kdts._ts': '已營業{t}天',
  '_jltg._tgje': '廣告投入',

  '_st._yqk': '欠款',
  '_st._qb': '全部',

  '_spxx._spbm': '商品編碼',

  '_scrz._yyzz': '營業執照',

  '_sjhb._sjbt': '优惠券',
  '_sjhb._kfxd': '优惠券列表',
  '_sjhb._fxlb': '分享列表',
  '_sjdhb._hbje': '面额',
  '_sjdhb._fxzs': '分享总数',
  '_sjdhb._fxcz': '操作',
  '_sjdhb._fxyhj': '分享',
  '_sjdhb._gqsj': '过期时间',
  '_sjdhb._lqsj': '领取时间',
  '_sjdhb._lqbm': '编码',
  '_sjdhb._wlq': '未领取',
  '_sjdhb._wsy': '未使用',
  '_sjdhb._ysy': '已使用',
  '_sjdhb._ylq': '已领取',
  '_sjdhb._ygq': '已過期',
  '_sjdhb._fuzi': '复制',
  '_sjdhb._lqyhj': '优惠券',
  '_sjdhb._lqlqyhj': '领取优惠券',
  '_sjdhb._yhjbm': '优惠券编码',
  '_sjdhb._ljlq': '立即领取',

  '_sjdhb._hqgdyhj': '獲得您更多的優惠券',
  '_sjdhb._nddzyj': '您的電子郵件',
  '_sjdhb._dy': '訂閱',
  '_sjdhb._zffs': '付款方式',
  '_sjdhb._zfjs': 'Wholesale全球站的使用者來自全球112個國家，使用USDT/ETH/BTC進行結算。 USDT/ETH/BTC是一種無國界的交易方式，可以在全球範圍內實現即時低成本交易，無需等待，沒有國際費用。 ',
  '_sjdhb._wzbq': 'Wholesale 有限公司2023 版權所有',

  '_sjdk.yhqbt': '折扣活动',
  '_sjdk.yhq': '优惠券',
  '_sjdk.dkq': '商家优惠券',

  '_sjdk._gmzk': '购买优惠额',
  '_sjdk._yhez': '优惠额',
  '_sjdk._yhsmwa': '购买优惠额度，可向买家赠送任意面额的优惠券抵扣订单金额，以此来吸引买家购买商品，提高店铺销售额。',

  '_sjdk._yuee': '余额',
  '_sjdk._gmyue': '购买',
  '_sjdk._shoujia': '售价',
  '_sjdk._zsdx': '赠送给',
  '_sjdk._zsyhj': '赠送优惠券',
  '_sjdk._yhjje': '金额',
  '_sjdk._sryhjje': '请输入卡券金额',
  '_sjdk._xftj': '条件',
  '_sjdk._srxftj': '请输入订单金额条件',
  '_sjdk._yhqtj': '说明：订单金额达到条件才能使用',
  '_sjdk._ljzs': '赠送',
  '_sjdk._sysj': '使用时间',
  '_sjdk._zssj': '赠送时间',
  '_sjdk._wsy': '未使用',
  '_sjdk._yss': '已使用',
  '_sjdk._qrzs': '如何赠送',
  '_sjdk._qzs': '去赠送',
  '_sjdk._yhjjl': '优惠券记录',
  '_sjdk._syyhq': '优惠券',
  '_sjdk._xfmje': '金额 >= ${n}',
}
