<template>
  <a-table :columns="columns" :data-source="dataSource" :pagination="pagination" :loading="loading" @change="getRedbagRecord" style="width: 100%">
    <template #bodyCell="{ column, record }">
      <!-- <template v-if="record && column.title === 'Name'">
        <div style="display:flex;align-items: center;">
          <img :src="record.img" width="40" style="margin-right:5px" />
          <div style="font-size:13px">{{record.name}}</div>
        </div>
      </template>
      <template v-if="record && column.title === 'Price'">
        <div>${{record.price}}</div>
      </template>-->
      <template v-if="record && column.key === 'is_use'">
        <a-tag :color="statusType(record.is_use).color">{{ statusType(record.is_use).text }}</a-tag>
      </template>
      <template v-if="record && column.key === 'amount'">
        <div style="font-size: 17px; color: #07bc0c">$ {{ record.amount }}</div>
      </template>
      <template v-if="record && column.key === 'condition'">
        <div>$ {{ record.condition }}</div>
      </template>
      <template v-if="record && column.key === 'caozuo'">
        <div>
          <a-button type="dashed" @click="onCopy(record.code)">{{ $t('_sjdhb._fuzi') }}</a-button>
        </div>
      </template>
    </template>
  </a-table>
</template>

<script>
export default {
  name: 'ReceiveList',
  data() {
    return {
      //存币记录表格
      columns: [
        {
          title: this.$t('_sjsy._xh'),
          customRender: (record) => {
            if (!this.loading) {
              return (this.pagination.current - 1) * this.pagination.pageSize + record.index + 1
            }
          },
          width: '10%'
        },
        {
          title: this.$t('_sjdhb._lqbm'),
          dataIndex: 'code',
          width: '10%'
        },
        {
          title: this.$t('_sjdhb._hbje'),
          key: 'amount',
          width: ''
        },
        {
          title: this.$t('_sjdk._xftj'),
          key: 'condition',
          width: '10%'
        },
        {
          title: this.$t('bank.list.status'),
          key: 'is_use',
          width: '10%'
        },
        {
          title: this.$t('_sjdk._sysj'),
          dataIndex: 'use_time',
          width: '15%'
        },
        {
          title: this.$t('_sjdk._zssj'),
          dataIndex: 'create_time',
          width: '15%'
        }
      ],
      status: {
        0: {
          text: this.$t('_sjdhb._wlq'),
          color: ''
        },
        1: {
          text: this.$t('_sjdk._wsy'),
          color: ''
        },
        2: {
          text: this.$t('_sjdk._yss'),
          color: 'green'
        },
        3: {
          text: this.$t('_sjdhb._ygq'),
          color: 'red'
        }
      },
      loading: false,
      pagination: { total: 0, current: 1, pageSize: 10 },
      dataSource: [],
      queryParam: {}
    }
  },
  created() {
    this.getCouponRecord(this.pagination)
  },
  methods: {
    refresh() {
      this.getCouponRecord({ total: 0, current: 1, pageSize: 10 })
    },
    statusType(status) {
      return this.status[status]
    },
    getCouponRecord(parameter) {
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize
      }
      const requestParameters = Object.assign({ is_use: parameter.is_use }, pagination, this.queryParam)

      this.loading = true

      let that = this
      this.$api.coupon.getCouponRecord(requestParameters).then((res) => {
        that.loading = false
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize
        }
        that.dataSource = res.data.data
      })
    },
    /**
     * 复制地址
     */
    onCopy(token) {
      console.log(token)
      // copyData想要复制的内容
      this.$copyText(token)
        .then(() => {
          this.$message.success('copy success! ' + token)
        })
        .catch(() => {
          this.$message.error('copy fail')
        })
    }
  }
}
</script>

<style></style>
