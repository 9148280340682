export default {
  'nav.account': 'Centro personale',
  'nav.language': 'Cinese semplificato',
  'signup.success': 'Invia con successo',

  //errore
  'contact.empty': 'Le informazioni di contatto non possono essere vuote',
  'phone.country.empty': 'Il prefisso telefonico non può essere vuoto',
  'phone.empty': 'Il telefono non può essere vuoto',
  'email.valid': "Inserisci l'email corretta",

  //base
  'base.success': 'successo',
  'base.save': 'Salva',
  'base.slide': 'Trascina il dispositivo di scorrimento per verificare',
  'base.send.code': 'Invia codice di verifica',
  'base.send.time': '({time}) reinvia codice di verifica',
  'base.send.success': 'Invio riuscito',
  'base.view.more': 'Visualizza altro',
  'base.Language': 'Lingua',
  'base.previous': 'Pagina precedente',
  'base.next': 'Pagina successiva',
  'base.total.page': 'Totale {page} pagine',
  'base.go.to.page': 'Vai alla pagina',
  'base.go': 'vai a',
  'base.followers': 'Follower',
  'base.follow': 'segui',
  'base.following': 'Seguito',
  'base.visit.store': 'Sfoglia negozio',
  'base.contact': 'Contattaci',
  'base.delivery': 'Tempo di consegna stimato: 1-7 giorni',
  'base.search': 'Cerca',
  'base.search.text': 'Inserisci ricerca parola chiave',
  'base.server': 'Servizio clienti online',

  //indice
  'text.categories.title': 'Categoria di prodotto',
  'text.welcome': 'Benvenuto allo shopping!',
  'text.wishlist': 'Lista Mi piace',
  'text.account': 'Centro personale',
  'text.orders': 'Ordini',
  'text.messages': 'Messaggi',
  'text.suggest.title': 'Offerta esclusiva',
  'text.suggest.content': 'Solo per i nuovi membri premium globali!',
  'text.super.deals': 'Ottimi prodotti, prezzi incredibili.',

  //iscrizione
  'signup.register': 'Registrati',
  'signup.signin': 'Accedi',
  'signup.store.signin': 'Accesso aziendale',
  'signup.sign.out': 'Esci',
  'signup.email': 'Indirizzo email',
  'signup.password': 'Password',
  'signup.qr.password': 'Conferma password',
  'signup.confirm.password': 'Conferma password',
  'signup.forgot.password': 'Password dimenticata',
  'signup.invitation': 'Codice di invito',
  'signup.char': 'Carattere',
  'signup.contains': 'Contiene numeri, lettere o simboli',
  'signup.qr.contains': 'Le due password non sono coerenti',
  'signup.create.account': 'Crea account',
  'signup.agree': 'Crea un account, accetti',
  'signup.member.agreement': 'Accordo di adesione',
  'signup.and': 'e',
  'signup.privacy.policy': 'Informativa sulla privacy',
  'signup.email.code': 'Verifica email',
  'signup.last.step': 'Ultimo passaggio',
  'signup.send.email': 'Inserisci il codice a 4 cifre inviato a {email}',
  'signup.modify.email': 'Modifica email',
  'signup.verify.email': 'Verifica email',
  'signup.have.store': 'Disponi di un account commerciante',
  'signup.goto.signin': 'Accesso aziendale',
  'signup.no.store': 'Nessun account commerciante',
  'signup.goto.store': 'Iscriviti commerciante',
  'signup.next': 'Avanti',
  'signup.your.email': 'la tua email',
  'signup.code.text': 'Codice di verifica',
  'signup.submit.signup': 'Registrati ora',
  'signup.smrz': 'Autenticazione con nome reale',
  'signup.derb': 'Secondo passaggio',
  'signup.qsrxm': 'Inserisci il nome del documento',
  'signup.qsrhm': 'Inserisci il numero ID',

  //dimenticato
  'forgot.title': 'Reimposta password',
  'forgot.btn.check': 'Verifica email',
  'forgot.reset.now': 'Ripristina ora',

  //negozio
  'store.info.open': 'Aperto {y} settimane',

  // piè di pagina
  'footer.great.value': 'Ottimo valore',
  'footer.great.value.desc': 'Offriamo prezzi competitivi per oltre 100 milioni di articoli.',
  'footer.shopping': 'Acquisti globali',
  'footer.shopping.desc': 'Spediamo in più di 200 paesi e regioni e il nostro sito Web è disponibile in 7 lingue.',
  'footer.safe.payment': 'Pagamento sicuro',
  'footer.safe.payment.desc': 'Paga con i metodi di pagamento più diffusi e sicuri al mondo.',
  'footer.shop.with.confidence': 'Acquista con fiducia',
  'footer.shop.with.confidence.desc': "La nostra politica di protezione dell'acquirente copre l'intero processo di acquisto.",
  'footer.help.center': 'Centro assistenza',
  'footer.help.center.desc': "Assistenza in qualsiasi condizione atmosferica per creare un'esperienza di acquisto fluida.",
  'footer.terms.conditions': 'Termini e condizioni',
  'footer.return.policy': 'Politica di reso',
  'footer.support.policy': 'Politica di supporto',
  'footer.privacy.policy': 'Informativa sulla privacy',
  'footer.be.seller': 'Diventa un venditore',
  'footer.apply.now': 'Applica ora',
  'footer.stay.connected': 'Resta connesso',

  'footer.about.us': 'Chi siamo',
  'footer.about.company': 'Profilo azienda',
  'footer.about.video': 'Video introduttivo',
  'footer.contact': 'Informazioni di contatto',

  'footer.my.account': 'Il mio account',
  'footer.my.logout': 'Esci',
  'footer.my.order': 'Cronologia ordini',
  'footer.my.wish': 'La mia lista dei desideri',
  'footer.my.join': 'Diventa un membro partner',
  'footer.email': 'Email',
  'footer.gfemail': 'Email ufficiale',
  'footer.fwemail': 'Email di servizio',
  'footer.address': 'Indirizzo',

  'apply.success': 'Applicazione riuscita',
  'apply.success.desc': "Applicazione eseguita correttamente, vai all'account di accesso",

  // categorie
  'category.title': 'Categorie correlate',
  'category.all': 'Tutte le categorie',

  //dettaglio
  'detail.store.home': 'Casa negozio',
  'detail.sale.items': 'Elenco prodotti',
  'detail.recommend': 'Consiglia',
  'detail.orders': 'Vendite',
  'detail.quantity': 'Quantità',
  'detail.pieces.available': 'Disponibile',
  'detail.delivery': 'Consegna',
  'detail.free.shipping': 'Spedizione gratuita',
  'detail.valuated.delivery': 'Arrivo previsto',
  'detail.days': 'giorni',
  'detail.buy.now': 'Acquista',
  'detail.add.to.cart': 'Aggiungi al carrello',
  'detail.buyer.protection': "Protezione dell'acquirente",
  'detail.money.guarantee': 'Garanzia di rimborso',
  'detail.refund.desc': "Ottieni un rimborso completo se l'articolo non è come descritto o non è stato consegnato",
  'detail.description': 'Descrizione del prodotto',
  'detail.customer.reviews': 'Recensioni dei clienti',
  'detail.specifications': 'Specifiche del prodotto',
  'detail.top.selling.products': 'Prodotti più venduti',
  'detail.recommended.for.you': 'Consigliato per te',
  'detail.sold': 'Vendite',
  'detail.receipt': 'Conferma ricevuta',
  'detail.receipt.title': "Confermi la ricezione dell'ordine? ",
  'detail.receipt.content': "Dopo la conferma, l'ordine di transazione è completato",
  'detail.comment': 'Commento',
  'detail.refund.title': 'Conferma la richiesta di rimborso',
  'detail.refund.content': "Dopo la conferma, l'ordine richiederà un rimborso",
  'detail.sqtk': 'Richiedi rimborso',

  //Confermare
  'confirm.shipping.address': 'Indirizzo del destinatario',
  'confirm.change': 'Modifica',
  'confirm.payment.methods': 'Metodi di pagamento',
  'confirm.summary': 'Saldo',
  'confirm.total.item.costs': 'Totale articoli',
  'confirm.total': 'Totale',
  'confirm.checkout': 'Vai alla cassa',
  'confirm.place.order': 'Effettua un ordine ora',
  'confirm.pay.now': 'Paga adesso',
  'confirm.order.desc': "Dopo aver fatto clic su 'Effettua ordine', confermo di aver letto e confermo",
  'confirm.order.policy': 'Tutti i termini e le politiche',
  'confirm.payment': 'Global Shopping garantisce la sicurezza delle tue informazioni e dei pagamenti',

  // indirizzo
  'address.title': 'Indirizzo di ricezione',
  'indirizzo.default': 'predefinito',
  'indirizzo.edit': 'Modifica',
  'indirizzo.delete': 'Elimina',
  'address.new': 'Crea un nuovo indirizzo',
  'indirizzo.contatto': 'Contatto',
  'indirizzo.indirizzo': 'Indirizzo',
  'indirizzo.telefono': 'Numero di telefono',
  'address.set.default': 'Imposta predefinito',
  'address.confirm': 'Conferma',
  'address.cancel': 'Annulla',
  'address.del.title': "Confermi di eliminare l'indirizzo?",
  'address.del.content': 'Sei sicuro di voler eliminare questo indirizzo di spedizione?',

  'payment.method': 'Metodo di pagamento',

  // Carrello della spesa
  'shopping.title': 'Carrello',
  'shopping.back.buy': 'Torna allo shopping speciale',
  'shopping.start.shopping': 'Inizia a fare acquisti',

  // membro
  'member.account': 'Centro personale',
  'member.overview': 'Panoramica',
  'member.orders': 'Ordini',
  'member.payment': 'Il mio portafoglio',
  'member.address': 'Indirizzo di ricezione',
  'member.wishlist': 'Lista Mi piace',
  'member.followlist': 'Elenco da seguire',
  'member.message.center': 'Centro messaggi',
  'member.setting': 'Impostazioni personali',
  'member.shop.info': 'Informazioni sul negozio',
  'member.shop.setting': 'Impostazioni negozio',
  'member.order.notify': 'Notifica nuovo ordine',
  'member.order.tips': "Inviando email all'email dell'account commerciante",

  // membro.ordine
  'member.order.title': 'Il mio ordine',
  'member.order.view.all': 'Visualizza tutto',
  'member.order.all': 'Tutti',
  'member.order.unpaid': 'non pagato',
  'member.order.paid': 'Pagato',
  'member.order.procurement': 'da acquistare',
  'member.order.seller.paid': 'Acquistato',
  'member.order.processing': 'da elaborare',
  'member.order.shipped': 'spedito',
  'member.order.completed': 'Completato',
  'member.order.refund': 'Rimborsato',
  'member.order.all.time': 'tutto',
  'member.order.empty': 'Ancora nessun ordine',
  'member.order.date': 'data',
  'member.order.purchase.date': 'Acquisto',
  'member.order.cpoy': 'Copia',
  'member.order.id': 'Ordine',
  'member.order.detail': 'Dettagli ordine',
  'member.order.logistics': 'Informazioni logistiche',

  // membro.pagamento
  'member.payment.title': 'Il mio portafoglio',
  'member.wallet.balance': 'saldo del portafoglio',
  'member.crypto.recharge': 'Ricarica',
  'member.crypto.withdrawal': 'Prelievo',
  'member.crypto.bank': 'Carta bancaria',
  'member.wallet.record': 'registrazione del deposito di monete',
  'member.bankcard.record': 'Registri della carta bancaria',
  'member.withdrawal.record': 'Record di ritiro',
  'member.income.record': 'record di reddito',
  'member.transaction.record': 'Record della transazione',
  'member.wallet.freeze': 'Blocca fondi',
  'member.wallet.profit': 'Entrate stimate',

  //ricaricare
  'ricarica.valuta': 'Valuta',
  'recharge.protocol': 'Protocollo',
  'ricarica.qrcode': 'Codice QR',
  'recharge.address': 'Indirizzo portafoglio',
  'recharge.copy': 'Copia indirizzo',
  'recharge.ok.text': 'Conferma',
  'recharge.cancel.text': 'Annulla',

  //banca
  'bank.recharge.title': 'Ricarica carta di credito',
  'bank.title': 'Nome banca',
  'bank.name': 'Nome beneficiario',
  'bank.account': 'Account ricevente',
  'bank.routing': 'Numero di instradamento della banca',
  'bank.code': 'Codice banca',
  'bank.bankname': 'Nome della banca',
  'bank.bankaddress': 'Indirizzo banca',
  'bank.ok.text': 'Avanti',
  'bank.ok.prev': 'Passaggio precedente',
  'bank.submit': 'Invia ora',
  'bank.amount': 'Importo di ricarica',
  'bank.amount.desc': "Inserisci l'importo della ricarica",
  'bank.type': 'tipo',
  'banca.usd': 'USD',
  'banca.eur': 'Euro',
  'bank.receipt.number': 'Numero ricevuta',
  'bank.receipt.number.desc': 'Inserisci il numero della ricevuta della transazione',
  'bank.credential.picture': 'Immagine delle credenziali',
  'bank.credential.picture.desc': "Carica l'immagine della credenziale",
  'bank.remark': 'Osservazione',
  'bank.upload': 'Carica immagini',
  'bank.text.title': 'Note',
  'bank.text.t1': '1. Non sono necessarie osservazioni per il trasferimento, lascia vuoto',
  'bank.text.t2': '2. Seleziona Hong Kong come paese della famiglia di Hong Kong, non aggiungere la Cina',
  'bank.text.t3': "3. Rimesse negli Stati Uniti, nei giorni feriali, rimesse verso l'Europa prima delle 15:00, le rimesse sono disponibili durante l'orario di lavoro della banca!",
  'bank.text.t4': "4. Il tempo di pagamento è T+1 e l'ultimo pagamento è T+3 in Europa",
  'bank.text.t5': '5. Informare la piattaforma prima di trasferire denaro per confermare se il conto è disponibile. Se il conto viene chiuso, non verrà corrisposto alcun compenso.',
  'bank.text.t6': '6. Deposito tramite carta bancaria. Prevalgono le informazioni del servizio clienti.',

  //lista banca
  'bank.list.title': 'Nome banca',
  'bank.list.code': 'Codice banca',
  'bank.list.amount': 'Importo del trasferimento',
  'bank.list.number': 'Numero ricevuta',
  'bank.list.img': 'Immagine del buono',
  'bank.list.status': 'Stato',
  'bank.list.time': 'ora',
  'bank.list.status1': 'In corso di revisione',
  'bank.list.status2': 'Riuscito',
  'bank.list.status3': 'Rifiutato',

  // Ritiro
  'withdrawal.address.desc': "Inserisci l'indirizzo del portafoglio!",
  'numero.prelievo': 'Numero',
  'withdrawal.real.number': 'arrivo effettivo',
  'withdrawal.number.desc': "Inserisci l'importo del prelievo!",
  'withdrawal.btn.all': 'tutto',
  'prelievo.saldo': 'Saldo',
  'withdrawal.commission': 'commissione',
  'prelievo.importo.effettivo': 'Accounted',
  'withdrawal.notice': 'Richiesta',
  'withdrawal.notice.text': "Prima del trasferimento, conferma che le informazioni sull'indirizzo di destinazione sono corrette. Una volta che le risorse sono state trasferite, non possono essere restituite.",
  'withdrawal.notice.content': '{name}({cp_name}) commissione: valore di mercato corrente {fee_rate}%/penna, standard minimo: {fee_min} {name}/penna',
  'withdrawal.submit': 'Invia',
  'withdrawal.choice': 'Scegli una criptovaluta',
  'withdrawal.yzm': 'Codice di verifica',
  'withdrawal.fs': 'Invia',
  'withdrawal.qsryzm': 'Inserisci il codice di verifica email',

  // ricevo
  'recive.method': 'Protocollo',
  'recive.amount': 'Importo',
  'recive.address': 'indirizzo',
  'recive.date': 'ora',
  'recive.status': 'Stato',
  'recive.create.at': 'Tempo di transazione',
  'recive.type': 'Tipo',
  'recive.befor': 'prima della transazione',
  'recive.balance': 'Saldo',
  'recive.freeze': 'Blocca',
  'recive.review': 'Revisione',
  'recive.success': 'Riuscito',
  'recive.reject': 'Rifiutato',

  // pubblicizza
  'advertise.title': 'Centro promozioni',
  'advertise.shop.title': 'Promozione negozio',
  'advertise.shop.status': 'Stato',
  'advertise.shop.expired': 'Scaduto',
  'advertise.shop.promotion': 'Promozione',
  'advertise.shop.expire.date': 'Data di scadenza',
  'advertise.shop.renew': 'Rinnovo annuncio',
  'advertise.shop.payable': 'Importo da pagare',
  'advertise.shop.explanation': "Spiegazione dell'annuncio",
  'advertise.shop.text': "Partecipa alla promozione pubblicitaria della piattaforma, aumenta l'esposizione del negozio e promuovi gli ordini di transazione",
  'advertise.shop.paynow': 'Rinnova ora',
  'advertise.shop.modal.title': 'Conferma pagamento',
  'advertise.shop.modal.desc': 'Confermato pagamento della quota di promozione',
  'advertise.shop.modal.btn': 'Conferma pagamento',

  //Lista dei desideri
  'lista dei desideri.title': 'Lista dei desideri',
  'wishlist.delete': 'Elimina',
  'wishlist.orders': 'Vendite',

  // Segui elenco
  'followlist.title': 'Follow List',
  'followlist.delete': 'Elimina',
  'followlist.follow': 'Segui',

  // negozio
  'store.dashboard': 'Dashboard',
  'store.products': 'Prodotti',
  'store.products.list': 'Elenco prodotti',
  'store.products.reviews': 'Recensioni prodotti',
  'store.orders': 'Ordini',
  'store.wallet': 'Portafoglio',
  'store.message': 'Centro messaggi',
  'store.setting': 'impostazione',
  'store.order.total.profit': 'Reddito operativo totale stimato',

  //pannello di controllo
  'dashboard.store.hour.views': 'Visite in tempo reale',
  'dashboard.store.today.views': 'Visite di oggi',
  'dashboard.product.total': 'Prodotto totale',
  'dashboard.product.today': 'Nuovo oggi',
  'dashboard.order.total': 'Ordine totale',
  'dashboard.sales.total': 'Fatturato stimato',
  'dashboard.sales.real': 'Vendite reali',
  'dashboard.sales.pay': 'Pagamento totale',
  'dashboard.sales.profit': 'Profitto totale',
  'dashboard.commission.total': 'Entrate totali',
  'dashboard.commission.today': 'Guadagni di oggi',
  'dashboard.order.sales': 'Vendite di prodotti',
  'dashboard.sales.list': 'Classifica vendite',
  'dashboard.goods.cate.rate': 'Rapporto di classificazione del prodotto',
  'dashboard.goods.wish': 'Mi piace la classifica dei prodotti',
  'cruscotto.gennaio': 'Gennaio',
  'dashboard.february': 'Febbraio',
  'dashboard.march': 'Marzo',
  'dashboard.april': 'Aprile',
  'dashboard.may': 'Maggio',
  'cruscotto.giugno': 'giugno',
  'dashboard.july': 'Luglio',
  'cruscotto.agosto': 'Agosto',
  'dashboard.september': 'Settembre',
  'dashboard.october': 'Ottobre',
  'dashboard.november': 'Novembre',
  'dashboard.december': 'Dicembre',

  // prodotti
  'products.add.new': 'Aggiungi nuovo prodotto',
  'products.add.from.warehouse': 'Aggiungi prodotti dal magazzino',
  'prodotti.delete': 'Elimina',
  'prodotti.add': 'Aggiungi',
  'products.table.img': 'immagine',
  'products.table.name': 'nome prodotto',
  'products.table.category': 'Categoria',
  'products.table.wish': 'mi piace',
  'prodotti.table.stock': 'stock',
  'products.table.price': 'Prezzo',
  'products.table.profit': 'Profitto',
  'products.table.action': 'Azione',
  'products.search.category': 'Ricerca per categoria',
  'products.back.product': "Torna all'elenco dei prodotti",
  'products.total': 'Totale merci',
  'prodotti.yes': 'Sì',
  'products.no': 'Annulla',
  'products.batch.add': 'Aumento batch',
  'products.ask.add': 'Sei sicuro di voler aggiungere prodotti?',
  'products.batch.delete': 'Eliminazione batch',
  'products.ask.delete': 'Sei sicuro di voler eliminare il prodotto?',
  'prodotti.top': 'In alto',
  'products.syzd': 'Home Page Top',
  'products.zdwz': 'Prima posizione',
  'prodotti.t1': 'Promozione',
  'prodotti.t2': 'Preferito',
  'prodotti.t3': 'Boutique',
  'prodotti.t4': 'nessuno',

  // recensioni
  'reviews.title': 'Recensioni del prodotto',
  'reviews.product.name': 'Prodotto',
  'reviews.user.name': 'Utente',
  'recensioni.star': 'Valutazione',
  'reviews.comment': 'Commenta il contenuto',
  'reviews.sku': 'Specifiche del prodotto',
  'reviews.imgs': 'Atlante',
  'reviews.created': 'Data',

  // store.order
  'store.order.purchase': 'Acquista ora',
  'store.order.purchase.desc': 'Sei sicuro di voler pagare per questo prodotto?',
  'store.order.purchase.yes': 'Paga adesso',
  'store.order.purchase.no': 'Annulla',
  'store.order.desc': "L'acquisto di questo ordine richiede il pagamento e otterrai vantaggi una volta completato l'ordine. ",
  'store.order.no': 'Numero ordine',
  'store.order.number': 'Quantità',
  'store.order.buyer': 'Acquirente',
  'store.order.total': 'Importo totale',
  'store.order.will.earning': 'Reddito',
  'store.order.profit': 'Profitto',
  'store.order.dividends': 'Dividendi',
  'store.order.payment.status': 'Stato pagamento',
  'store.order.seller.buy.status': 'Stato acquisto',
  'store.order.status': 'Stato ordine',
  'store.order.date': 'data ordine',
  'store.order.purchase.date': 'Data di acquisto',
  'store.order.action': 'Operazione',
  'store.order.purchase.amount': 'Importo di acquisto',

  //reddito
  'income.create.at': 'Tempo record',
  'income.order.sn': 'Numero ordine',
  'income.realpay': 'Importo ordine',
  'reddito.profitto': 'reddito',

  //Collocamento
  'setting.avatar': 'Avatar aziendale',
  'setting.upload': 'Carica',
  'setting.shop.name': 'Nome negozio',
  'setting.shop.phone': 'Contatto telefonico',
  'setting.shop.address': 'Indirizzo negozio',
  'setting.shop.save': 'Salva',
  'setting.upload.pic': 'Carica immagine',
  'setting.send.pic': 'Invia immagine',

  //venditore
  'beseller.title': 'Fai domanda per diventare un commerciante',
  'beseller.account': "Informazioni sull'account",
  'beseller.store': 'Informazioni negozio',
  'beseller.store.name': 'Nome negozio',
  'beseller.store.address': 'Indirizzo negozio',

  //salvacasa
  'store.home.title': 'Impostazioni home page',
  'store.home.topimg': 'immagine superiore',
  'store.home.banner': 'Immagine carosello',
  'store.home.up3': 'Osservazioni: almeno 3 caricamenti',
  'store.upload.more': 'Carica altre foto',
  'store.home.columns': 'Colonne',
  'store.home.bgimg': 'Immagine di sfondo',
  'store.goods.remark': 'Nota: 5 articoli per riga, 10 articoli per piatto al massimo',
  'store.home.select': 'Seleziona prodotto',
  'store.home.add': 'Aggiungi colonna',

  //informazione personale
  'setting.update.user': 'Informazioni personali',
  'setting.update.info': 'Modifica informazioni',
  'setting.user.avatar': 'Avatar utente',
  'setting.user.nickname': 'Soprannome utente',
  'setting.user.nickname.desc': "Inserisci il nickname dell'utente",

  'setting.safe.title': 'Informazioni sulla sicurezza',
  'setting.user.passwd': 'Cambia password',
  'setting.passwd.title1': 'Vecchia password',
  'setting.passwd.desc1': 'Inserisci la vecchia password',
  'setting.passwd.title2': 'Nuova password',
  'setting.passwd.desc2': 'Inserisci una nuova password',
  'setting.passwd.title3': 'Conferma password',
  'setting.passwd.desc3': 'Inserisci una password di conferma',
  'setting.invitation': 'Invita amici',
  'setting.copy': 'Copia',

  'adv.add.products': 'Aggiungi prodotti promozionali',
  'adv.list.title': 'Promozione del prodotto',
  'adv.type': 'Tipo di promozione',
  'adv.begin.time': 'Tempo di promozione',
  'adv.end.time': 'Ora di fine',
  'adv.status': 'Stato',
  'adv.status.s1': 'Promozione',
  'adv.status.s2': 'Fine',
  'adv.add.back': "Torna all'elenco promosso",
  'adv.recharge': 'Ricarica punti promozione',
  'adv.select.goods': 'Seleziona prodotti',
  'adv.select.btn': 'Prodotti in promozione',
  'adv.recharge.title': 'Ricarica punti promozione',
  'adv.recharge.balance': 'Saldo punti',
  'adv.point': 'punto',
  'adv.point.rate': 'Rapporto punti',
  'adv.recharge.amount': 'Importo di ricarica',
  'adv.recharge.input.amount': 'Inserisci importo di ricarica',
  'adv.select.mod': 'Seleziona pannello',
  'adv.mod1': 'Colonne consigliate',
  'adv.mod2': 'Colonna preferita',
  'adv.mod3': 'Colonna saldi',
  'adv.mod4': 'Lista eccellente',
  'adv.mod5': 'Elenco di classificazione',
  'adv.mod6': 'Promozione dettagliata',
  'adv.ge': 'Quantità',
  'adv.xs': 'ora',
  'adv.xzbk': 'Seleziona piatto',
  'adv.syme': 'Quota rimanente',
  'adv.tgsc': 'Durata della promozione',
  'adv.tgjg': 'Prezzo promozionale',
  'adv.syye': 'Saldo corrente',
  'adv.tgye': 'Saldo promozione',
  'adv.yj1': 'previsto',
  'adv.yj2': 'Avvia promozione',
  'adv.pay.point': 'Punti di consumo',

  'wallet.ykcsxf': 'La commissione di gestione è stata detratta',
  'wallet.sxf': 'Commissione di servizio',
  'wallet.zxje': 'Importo minimo',
  'wallet.24hxe': 'limite di 24 ore',
  'wallet.desc.text': "In nessun caso, il funzionario non ti chiederà di trasferire denaro su un conto, né ti chiederà un codice di verifica. Non partecipare ad attività illegali come l'acquisto per conto di altri, il riciclaggio di denaro e la raccolta fondi illegale e fai attenzione alle frodi online",
  'wallet.cunbi.text': 'Puoi depositare solo {name} a questo indirizzo, gli altri asset non verranno recuperati',

  'credit.title': 'Limite di credito',
  'credit.ed': 'Quota',
  'credit.hk': 'Rimborso',
  'credit.kyye': 'Saldo disponibile',
  'credit.dqqk': 'Attuali arretrati',
  'credit.hkje': 'Importo del rimborso',
  'credit.hkje.desc': "Inserisci l'importo del rimborso",
  'credit.cgje': 'Importo di acquisto',
  'credit.qrz': "Vai all'autenticazione",
  'credit.srxyj': "Inserisci l'importo del pagamento del credito",

  'store.gzl': 'Quantità di attenzione',
  'store.wxz': 'Illimitato',

  'auth.smrz': 'Autenticazione con nome reale',
  'auth.rzlx': 'Digita',
  'auth.sfzm': "carta d'identità anteriore",
  'auth.sffm': "Il retro della carta d'identità",
  'auth.zsxm': 'vero nome',
  'auth.zjhm': 'Numero documento',
  'auth.yyzz': 'Licenza commerciale',
  'auth.gsmc': 'Nome azienda',
  'auth.shz': 'Controllo',
  'auth.ytg': 'Riuscito',
  'auth.ybh': 'Rifiutato',
  'auth.zt': 'stato',
  'auth.gr': 'Personale',
  'auth.gs': 'Azienda',
  'auth.ljtj': 'Richiedi autenticazione',
  'auth.wrz': 'Non autenticato',

  'credit.qts': 'Visualizza descrizione',
  'store.spss': 'Limite massimo merce',
  'store.dpfh': 'Memorizza dividendi',
  'store.qydj': 'Livello di capitale',
  'store.level': 'livello',
  'store.jian': 'pezzi',

  'order.notify.email': "A causa del controllo del rischio e-mail, le e-mail di promemoria dell'ordine della piattaforma vengono inviate frequentemente e verranno intercettate. Per riceverle in tempo, si consiglia di aggiungere {email} al login lista bianca email",

  'setting.sjxx': 'Informazioni commerciali',
  'setting.smrz': 'Autenticazione con nome reale',
  'setting.aqzx': 'Centro sicurezza',
  'setting.tzxx': 'Informazioni di notifica',
  'setting.dpzx': 'Decorazione home page',
  'setting.sjqy': 'Interessi commerciali',
  'setting.sjdjqy': 'Descrizione dei vantaggi a livello aziendale',

  'setting.fhbky': 'Il dividendo attuale non è disponibile',
  'setting.spgzyzy': 'Regole e linee guida del negozio',

  'desc.dqqy': 'Patrimonio corrente',
  'desc.xjqy': 'Interessi subordinati',
  'desc.sjqy_0': 'Limite di credito negozio',
  'desc.sjqy_1': 'Memorizza dividendi',
  'desc.sjqy_2': 'Numero di prodotti che possono essere pubblicati',

  'share.yqlb': 'Lista di inviti',
  'share.zmj': 'Venditore totale',
  'share.zdd': 'Ordine totale',
  'share.zsy': 'Entrate totali',
  'share.wcl': 'non elaborato',
  'share.ycl': 'Elaborato',
  'share.ywc': 'Completato',
  'share.dpmc': 'Nome negozio',
  'share.ddl': 'Quantità ordine',
  'share.jjsy': 'Reddito di intermediazione',
  'share.zts': 'Numero totale',

  'chat.state': 'stato',
  'chat.zx': 'In linea',
  'chat.ljz': 'Connessione',

  'bind.wallet.title': 'Indirizzo di prelievo',
  'bind.address.title': 'Aggiungi indirizzo',
  'bind.bj': 'Modifica',
  'bind.sc': 'Elimina',
  'bind.qrsc': 'Sei sicuro di voler eliminare questo indirizzo?',
  'bind.qbdz': 'Indirizzo portafoglio',
  'bind.tjsj': 'Aggiungi ora',
  'bind.cz': 'Operazione',
  'withdrawal.address.choice': "Scegli l'indirizzo per il prelievo!",

  'order.sxsj': 'Tempo effettivo',
  'order.ljsj': 'immediatamente efficace',
  'order.dssj': 'Efficacia temporizzata',

  'store.st.zt': 'Stato negozio',
  'store.st.zc': 'normale',
  'store.st.zc.desc': 'Il tuo negozio è normale, continua così',
  'store.st.yc': 'Eccezione',
  'store.st.yc.desc': 'Il tuo negozio ha degli ordini anomali o ha ricevuto reclami da parte dei clienti',
  'store.st.dj': 'Blocca',
  'store.st.dj.desc': 'Il tuo negozio ha un certo rischio operativo e i fondi sono congelati',
  'store.st.jy': 'disabilitato',
  'store.st.jy.desc': 'Il tuo negozio ha violato gli accordi e le regole relative alla piattaforma ed è stato disabilitato',

  'task.sy': 'Pagina iniziale',
  'task.rwlbo': 'Elenco attività',
  'task.rwxqo': 'Dettagli attività',
  'task.ljcy': 'Partecipa ora',
  'task.gdrw': 'Altre attività',
  'task.rwlb': 'Elenco delle attività di cattura',
  'task.qbrw': 'Tutte le attività',
  'task.wcyd': 'Ho partecipato a',
  'task.qdz': 'Ricezione ordini',
  'task.ywc': 'Completato',
  'task.djy': 'da scambiare',
  'task.ddje': 'Importo ordine',
  'task.cyrs': 'Numero di partecipanti',
  'task.ewsy': 'Ulteriori vantaggi',
  'task.jzsj': 'Scadenza',
  'task.ckxq': 'Visualizza dettagli',
  'task.rwxq': 'Dettagli attività',
  'task.cylb': 'Lista di partecipazione',
  'task.qdcyrw': "Sei sicuro di voler partecipare a questa attività dell'ordine?",
  'attività.sd': 'Sì',
  'attività.bue': 'no',

  'task.wdsy': 'Entrate',
  'task.wdfh': 'Dividendi',
  'task.wdqd': 'Ottieni ordine',
  'task.dd.leixin': 'Tipo di ordine',
  'task.dd.qdrw': 'Attività ordine rapido',
  'task.dd.ptdd': 'Ordine ordinario',

  'flow.lltg': 'Promozione flusso',
  'flow.gmll': 'Acquista pacchetto dati',
  'flow.dqtc': 'Pacchetto corrente',
  'flow.tc1': 'Pacchetto',
  'flow.sy2': 'rimanente',
  'flow.tian3': 'giorno',
  'flow.jlzs': 'Numero totale di record',
  'flow.lltc': 'Pacchetto flusso',
  'flusso.ll': 'flusso',
  'flow.gmsj': 'Tempo di acquisto',
  'flow.tcjg': 'Prezzo pacchetto',
  'flow.sjzf': 'pagamento effettivo',
  'flow.tcsm': 'Descrizione pacchetto',
  'flow.tcsm.sm': "L'acquisto di questo pacchetto aumenterà l'esposizione del negozio e otterrà più traffico clienti. Al fine di ottenere più ordini e aumentare le entrate del negozio. ",

  'flow.tcmc': 'Nome pacchetto',
  'flow.gmsc': "Durata dell'acquisto",
  'flow.zje': 'Importo totale',
  'flow.jzsj': 'Scadenza',
  'flow.gmrq': 'Data di acquisto',
  'flow.tian1': 'giorno',
  'flow.zhou1': 'settimana',
  'flow.yue1': 'mese',

  'share.dj': 'livello',
  'share.hz': 'Trasferisci',
  'share.hzd': 'Trasferisci a',
  'share.qbye': 'saldo del portafoglio',
  'share.tgje': 'Importo della promozione',
  'share.hzje': 'Importo del trasferimento',
  'share.qsrhzje': "Inserisci l'importo del trasferimento",

  '_inv._title': "Descrizione dell'invito",
  '_inv._t1': '1. Invito e relazione invitata',
  '_inv._desc1': 'A invita B, B invita C, C invita D. Il livello più alto è 4, se D invita di nuovo E, allora E non ha nulla a che fare con A. ',
  '_inv._t2': "2. L'invitante riceve una ricompensa",
  '_inv._desc2': "A ottiene il 4% dell'importo totale completato dall'ordine B, A ottiene il 2% dell'importo totale completato dall'ordine C e A ottiene l'1% dell'importo totale completato dall'ordine D",

  '_index._gm': 'Acquista',
  '_index._sm': "Descrizione: aumenta l'esposizione del negozio e attira traffico",

  '_n.dlyzc': 'Accesso e registrazione',
  '_n.hy4': 'Ce ne sono altri 4',
  '_n.ckgd': 'Vedi di più',
  '_n._cxhzk': 'Promozioni e sconti',
  '_n._cwyp': 'Forniture per animali domestici',
  '_n._tjsp': 'Offerta speciale',
  '_n._rmfl': 'Categorie popolari',
  '_n._tjsp2': 'Prodotti consigliati',
  '_n._rxsp': 'Prodotti consigliati',
  '_n._rmsp': 'Articoli importanti',
  '_n._gdsp': 'Altri prodotti',
  '_n._yzsj': 'Commerciante di qualità',

  '_n._zxsc': 'Centro commerciale online',
  '_n._fkyys': 'Pagamento e spedizione',
  '_n._xsgz': 'Regole di vendita',
  '_n._hhjth': 'Cambi e resi',
  '_n._lxwm': 'Contattaci',
  '_n._sczc': 'politica del centro commerciale',
  '_n._tkytj': 'Termini e condizioni',
  '_n._tuzc': 'Politica di restituzione',
  '_n._zczc': 'Politica di supporto',
  '_n._yszc': 'Informativa sulla privacy',
  '_n._scjj': 'Introduzione al centro commerciale',
  '_n._ljgm': 'Acquista ora',

  '_n2._rxsp': 'Articoli più venduti',
  '_n2._pl': 'Commento',
  '_n2._sjxx': 'Informazioni aziendali',
  '_n2._rhsh': 'Come ricevere la merce',
  '_n2._xsxd': 'Ordine online',
  '_n2._jscldd': 'Servizio clienti dedicato',
  '_n2._mfps': 'Spedizione gratuita',
  '_n2._2xsfh': 'Consegna fulminea',
  '_n2._1d5tdd': 'Arrivato entro 1-5 giorni',
  '_n2._tkzd': 'Posta espressa',
  '_n2._ckfh': 'Spedisci dal magazzino',
  '_n2._zcps': 'Magazzino nelle vicinanze',
  '_n2._jsd': 'Punto di ricezione',
  '_n2._qsjps': 'Consegna in tutto il mondo',
  '_n2._thtj': 'Condizioni di reso',
  '_n2._thtjsm': "Entro 14 giorni dalla data di acquisto, puoi cambiare o restituire l'articolo",
  '_n2._gycp': 'Informazioni sul prodotto',
  '_n2._yc': 'Nascondi',
  '_n2._tiao': 'Tiao',
  '_n2._sypl': 'Tutti i commenti',
  '_n2._lxfk': 'Lascia un feedback',
  '_n2._xzspgg': 'Seleziona le specifiche del prodotto',
  '_n2._qxdl': 'Per favore accedi prima',
  '_n2._rnsj': 'Come puoi vedere',
  '_n2._rx': 'Vendita calda',

  '_n3._qrdd': 'Conferma ordine',
  '_n3._njsp': '{num} elementi',
  '_n3._ddzf': "Pagamento dell'ordine",
  '_n3._wydlsp': 'Benvenuto per accedere al negozio',

  '_n3._sjwfgm': 'Il commerciante non può acquistare beni',
  '_n3._sjbnlt': 'Le aziende non possono chattare',
  '_chat._hc': 'Ritira',

  '_zc._sryxdz': 'Inserisci la tua email',
  '_zc._yxgscw': "Errore nel formato dell'e-mail",
  '_zc._qsrmm': 'Inserisci la password',
  '_zc._lcmmbxd': 'Due password non sono coerenti',
  '_zc._hdyzwtg': 'Verifica tramite lo slider',
  '_zc._qsryqm': 'Inserisci il codice di invito',
  '_zc._yhzh': 'Registra account utente',
  '_zc._qzc': 'Vai alla registrazione',
  '_zc._srdpm': 'Inserisci il nome del negozio',

  '_zc._gszz': 'Qualifica aziendale',
  '_zc._ptzz': 'Qualifiche relative alla piattaforma',

  '_dd._tijd': 'Invio ordine',
  '_dd._xzdz': "Seleziona l'indirizzo di consegna",
  '_dd._zfz': 'Pagamento in corso',

  '_wd._zf': 'pagamento',
  '_wd._cz': 'Ricarica',
  '_wd._txsq': 'Domanda di ritiro',
  '_wd._ddsy': 'Entrate dagli ordini',
  '_wd._cgzf': 'Acquisto',
  '_wd._txbh': 'Rifiuta',
  '_wd._txtg': 'Superato',
  '_wd._ddtk': "Rimborso dell'ordine",
  '_wd._yehz': 'Trasferisci',
  '_wd._gmll': 'Acquista traffico',
  '_wd._zjkc': 'detrazione affitto',
  '_wd._fk': 'Bene',
  '_wd._ns': 'Imposta',
  '_wd._bzj': 'Margine',

  '_xxtz._zntz': 'Notifica del sito',
  '_xxtz._fh': 'ritorno',
  '_xxtz._jzgd': 'Carica altro',
  '_xxtz._mysj': 'Non più',

  '_sjsy._jrdd': 'Ordine di oggi',
  '_sjsy._rxse': 'Vendite giornaliere',

  '_sjsy._xh': 'Indice',

  '_wd._nbjy': 'Trasferimento interno',
  '_wd._qsruid': "Inserisci l'UID del beneficiario",
  '_wd._je': 'Importo',
  '_wd._qsrhzje': "Inserisci l'importo del trasferimento",
  '_wd._skr': 'Beneficiario',
  '_wd._nbhzsm': 'Per trasferire internamente agli utenti della piattaforma, controlla attentamente le informazioni del beneficiario',

  '_st._spxlphb': 'Classifica delle vendite dei prodotti',
  '_st._czsp': 'Reimposta la ricerca',

  '_st._pljrgwc': 'Unisciti a tutti',

  '_sj._yxz': 'Selezionato',
  '_sj._hjzje': 'Importo totale totale',
  '_sj._zdcr': 'Deposita automaticamente',

  '_wd._ddfh': 'Dividendo',

  '_dd._csqx': 'Annullamento ordine',

  '_tx._yhktx': 'Prelievo bancario',
  '_tx._sryhmc': 'Inserisci il nome della banca',
  '_tx._srskrmc': 'Inserisci il nome del beneficiario',
  '_tx._srskzh': 'Inserisci il numero del conto di pagamento',
  '_tx._sryhluh': 'Inserisci il numero di routing della banca',
  '_tx._sryhdm': 'Inserisci il codice bancario',
  '_tx._sryhdz': "Inserisci l'indirizzo della banca",
  '_tx._txje': 'Importo del prelievo',
  '_tx._srtxje': "Inserisci l'importo del prelievo",
  '_tx._txkc': 'Commissione di prelievo {_sxf}%',

  '_yye._yyye': 'Vendite mensili',
  '_yye._ysy': 'Reddito mensile',

  '_sj._hjzsy': 'Reddito totale totale',

  '_yhdl._dl': 'Accedi',
  '_yhdl._zc': 'Crea account',

  '_zdy._24zrkf': 'Siamo qui per assisterti 24 ore su 24, 7 giorni su 7',
  '_zdy._zhsz': 'Impostazioni account',
  '_zdy._mfps': 'Per un periodo limitato, puoi usufruire della spedizione gratuita in 2 giorni lavorativi su migliaia di articoli in aree selezionate. ',
  '_zdy._gmsp': 'Acquista nuovi prodotti',
  '_zdy._czyh': 'Grandi offerte, goditi i tuoi prodotti preferiti',
  '_zdy._xsgw': "Tempo di shopping limitato! Ordina prima del 12 maggio a mezzogiorno ET per la spedizione gratuita. Consegnato a casa tua con un'auto dedicata! ",
  '_zdy._ssgjc': 'Inserisci ricerca per parola chiave',

  '_zdy._rmjx': 'Le tue scelte migliori',

  '_dd._plcz': 'Operazione batch',
  '_dd._plcg': 'Acquisto in blocco',
  '_dd._qgxcgd': "Controlla l'ordine che devi acquistare",
  '_dd._yxddbcg': "L'ordine selezionato non richiede l'acquisto",
  '_dd._qdyplcgm': "Sei sicuro di voler acquistare in blocco l'ordine selezionato? ,Quantità:{n}",
  '_dd._qdcg': 'Conferma acquisto',
  '_dd._qx': 'Annulla',
  '_dd._plcgwc': 'Acquisto in batch completato, riuscito: {s}/{t}',
  '_dd._zzcgz': 'Acquisto',
  '_st._czhk': 'Ricaricare e rimborsare',
  '_st._dkje': 'ammontare del prestito',
  '_st._dkjl': 'storia del prestito',
  '_st._hkfs': 'Rimborso',
  '_st._hkje': 'Importo del rimborso',
  '_st._jrong': 'Finanza del centro commerciale',
  '_st._ljsq': 'applicare immediatamente',
  '_st._qrshkje': "Inserisci l'importo del rimborso",
  '_st._qsrdkje': "Inserisci l'importo del prestito",
  '_st._shz': 'in corso di revisione',
  '_st._sqdklx': 'prestito',
  '_st._sqhk': 'Richiedere il rimborso',
  '_st._sqhklx': 'rimborso',
  '_st._sqjk': 'chiedere un prestito',
  '_st._sqlx': 'tipo',
  '_st._ybh': 'rifiutare',
  '_st._yjk': 'Preso in prestito',
  '_st._ytg': 'passaggio',
  '_st._zzhk': 'In attesa di rimborso',

  '_st._dqed': 'Importo del prestito',
  '_st._ccdked': 'Non può superare il limite del prestito',
  '_st._tjsqz': 'La domanda è stata presentata',
  '_st._dklx': 'Interesse',
  '_st._dkll': 'Tasso di interesse del prestito:{n}%',
  '_kdts._ts': 'Aperto per {t} giorni',
  '_jltg._tgje': 'Investimenti pubblicitari',

  '_st._yqk': 'Arretratezza',
  '_st._qb': 'Tutti',

  '_spxx._spbm': 'codice',

  '_scrz._yyzz': 'Licenza commerciale',

  '_sjhb._sjbt': 'Coupon',
  '_sjhb._kfxd': 'Elenco coupon',
  '_sjhb._fxlb': 'Elenco condivisi',
  '_sjdhb._hbje': 'denominazione',
  '_sjdhb._fxzs': 'Numero totale di azioni',
  '_sjdhb._fxcz': 'Operazione',
  '_sjdhb._fxyhj': 'Condividi',
  '_sjdhb._gqsj': 'Tempo di scadenza',
  '_sjdhb._lqsj': 'Ora di ritiro',
  '_sjdhb._lqbm': 'codifica',
  '_sjdhb._wlq': 'Non ricevuto',
  '_sjdhb._wsy': 'Non utilizzato',
  '_sjdhb._ysy': 'usato',
  '_sjdhb._ylq': 'Ricevuto',
  '_sjdhb._ygq': 'Scaduto',
  '_sjdhb._fuzi': 'Copia',
  '_sjdhb._lqyhj': 'Coupon',
  '_sjdhb._lqlqyhj': 'Ricevi coupon',
  '_sjdhb._yhjbm': 'Codice coupon',
  '_sjdhb._ljlq': 'Ricevilo adesso',

  '_sjdhb._hqgdyhj': 'Ricevi più coupon per te',
  '_sjdhb._nddzyj': 'La tua email',
  '_sjdhb._dy': 'Iscriviti',
  '_sjdhb._zffs': 'Metodo di pagamento',
  '_sjdhb._zfjs': 'Gli utenti di Wholesale Global Station provengono da 112 paesi in tutto il mondo e utilizzano USDT/ETH/BTC per il regolamento. USDT/ETH/BTC è un metodo di transazione senza confini che consente transazioni istantanee a basso costo in tutto il mondo, senza attese e senza commissioni internazionali. ',
  '_sjdhb._wzbq': 'Copyright 2023 Wholesale Limited',

  '_sjdk.yhqbt': 'Attività scontate',
  '_sjdk.yhq': 'Buono',
  '_sjdk.dkq': 'Buoni sconto del commerciante',

  '_sjdk._gmzk': "Sconto sull'acquisto",
  '_sjdk._yhez': 'Importo dello sconto',
  '_sjdk._yhsmwa': "Importo dello sconto sull'acquisto: puoi dare agli acquirenti buoni di qualsiasi importo per detrarre l'importo dell'ordine, in modo da invogliarli ad acquistare beni e aumentare le vendite del negozio. ",

  '_sjdk._yuee': 'Saldo',
  '_sjdk._gmyue': 'Acquista',
  '_sjdk._shoujia': 'Prezzo',
  '_sjdk._zsdx': 'Regalo a',
  '_sjdk._zsyhj': 'Regala buoni sconto',
  '_sjdk._yhjje': 'Importo',
  '_sjdk._sryhjje': "Inserisci l'importo della carta",
  '_sjdk._xftj': 'Condizioni',
  '_sjdk._srxftj': "Inserisci le condizioni per l'importo dell'ordine",
  '_sjdk._yhqtj': "Nota: l'importo dell'ordine deve soddisfare le condizioni per essere utilizzato",
  '_sjdk._ljzs': 'Regalo',
  '_sjdk._sysj': 'Tempo di utilizzo',
  '_sjdk._zssj': 'Tempo di regali',
  '_sjdk._wsy': 'Non utilizzato',
  '_sjdk._yss': 'Usato',
  '_sjdk._qrzs': 'Come donare',
  '_sjdk._qzs': 'Vai a regalare',
  '_sjdk._yhjjl': 'Registro coupon',
  '_sjdk._syyhq': 'Buono',
  '_sjdk._xfmje': 'Importo >= ${n}'
}
