export default {
  'nav.account': 'Centro personal',
  'nav.language': 'Chino simplificado',
  'signup.success': 'Enviar exitosamente',

  //error
  'contact.empty': 'La información de contacto no puede estar vacía',
  'phone.country.empty': 'El prefijo del teléfono no puede estar vacío',
  'phone.empty': 'El teléfono no puede estar vacío',
  'email.valid': 'Ingrese el correo electrónico correcto',

  // base
  'base.éxito': 'éxito',
  'base.save': 'Guardar',
  'base.slide': 'Por favor, tire del control deslizante para verificar',
  'base.send.code': 'Enviar código de verificación',
  'base.send.time': '({time}) reenviar código de verificación',
  'base.send.success': 'Enviar con éxito',
  'base.view.more': 'Ver más',
  'base.Idioma': 'Idioma',
  'base.previous': 'Página anterior',
  'base.next': 'Página siguiente',
  'base.total.page': 'Total de {página} páginas',
  'base.go.to.page': 'Ir a la página',
  'base.go': 'ir a',
  'base.followers': 'Seguidores',
  'base.seguir': 'seguir',
  'base.following': 'Seguido',
  'base.visit.store': 'Buscar tienda',
  'base.contact': 'Contáctenos',
  'base.delivery': 'Tiempo de entrega estimado: 1-7 días',
  'base.search': 'Buscar',
  'base.search.text': 'Ingresar búsqueda por palabra clave',
  'base.server': 'Servicio al cliente en línea',

  //índice
  'text.categories.title': 'Categoría de producto',
  'text.welcome': '¡Bienvenido a comprar!',
  'text.wishlist': 'Lista de Me gusta',
  'text.account': 'Centro personal',
  'text.orders': 'Pedidos',
  'text.messages': 'Mensajes',
  'text.suggest.title': 'Oferta exclusiva',
  'text.suggest.content': '¡Solo para nuevos miembros premium globales!',
  'text.super.deals': 'Los mejores productos, precios increíbles.',

  //inscribirse
  'signup.registrar': 'Registrarse',
  'signup.signin': 'Iniciar sesión',
  'signup.store.signin': 'Inicio de sesión empresarial',
  'signup.sign.out': 'Cerrar sesión',
  'signup.email': 'Dirección de correo electrónico',
  'signup.password': 'Contraseña',
  'signup.qr.password': 'Confirmar contraseña',
  'signup.confirm.password': 'Confirmar contraseña',
  'signup.forgot.password': 'Olvidé mi contraseña',
  'signup.invitation': 'Código de invitación',
  'signup.char': 'Personaje',
  'signup.contains': 'Contiene números, letras o símbolos',
  'signup.qr.contains': 'Las dos contraseñas son inconsistentes',
  'signup.create.account': 'Crear cuenta',
  'signup.agree': 'Crea una cuenta, estás de acuerdo',
  'signup.member.agreement': 'Acuerdo de Membresía',
  'registrarse.y': 'y',
  'signup.privacy.policy': 'Política de Privacidad',
  'signup.email.code': 'Verificación de correo electrónico',
  'signup.last.step': 'Último paso',
  'signup.send.email': 'Ingrese el código de 4 dígitos enviado a {email}',
  'signup.modify.email': 'Modificar correo electrónico',
  'signup.verify.email': 'Verificar correo electrónico',
  'signup.have.store': 'Tener cuenta de comerciante',
  'signup.goto.signin': 'Inicio de sesión empresarial',
  'signup.no.store': 'Sin cuenta de comerciante',
  'signup.goto.store': 'Registrar Comerciante',
  'signup.next': 'Siguiente',
  'signup.your.email': 'tu correo electrónico',
  'signup.code.text': 'Código de verificación',
  'signup.submit.signup': 'Regístrese ahora',
  'signup.smrz': 'Autenticación de nombre real',
  'signup.derb': 'Segundo paso',
  'signup.qsrxm': 'Ingrese el nombre del documento',
  'signup.qsrhm': 'Ingrese el número de identificación',

  //olvidó
  'forgot.title': 'Restablecer contraseña',
  'forgot.btn.check': 'Verificar correo electrónico',
  'forgot.reset.now': 'Restablecer ahora',

  //almacenar
  'store.info.open': 'Abierto {y} semanas',

  // pie de página
  'footer.great.value': 'Gran valor',
  'footer.great.value.desc': 'Ofrecemos precios competitivos para más de 100 millones de artículos.',
  'footer.shopping': 'Compras globales',
  'footer.shopping.desc': 'Realizamos envíos a más de 200 países y regiones, y nuestro sitio web está disponible en 7 idiomas.',
  'footer.safe.payment': 'Pago seguro',
  'footer.safe.payment.desc': 'Pague con los métodos de pago más populares y seguros del mundo.',
  'footer.shop.with.confidence': 'Compre con confianza',
  'footer.shop.with.confidence.desc': 'Nuestra política de protección del comprador cubre todo el proceso de compra.',
  'footer.help.center': 'Centro de ayuda',
  'footer.help.center.desc': 'Asistencia en todo clima para crear una experiencia de compra sin problemas.',
  'footer.terms.conditions': 'Términos y condiciones',
  'footer.return.policy': 'Política de devolución',
  'footer.support.policy': 'Política de soporte',
  'footer.privacy.policy': 'Política de privacidad',
  'footer.be.seller': 'Conviértete en vendedor',
  'footer.apply.now': 'Solicitar ahora',
  'footer.stay.connected': 'Mantenerse conectado',

  'footer.about.us': 'Acerca de nosotros',
  'footer.about.company': 'Perfil de la empresa',
  'footer.about.video': 'Introducción al video',
  'footer.contact': 'Información de contacto',

  'footer.my.account': 'Mi cuenta',
  'footer.my.logout': 'Cerrar sesión',
  'footer.my.order': 'Historial de pedidos',
  'footer.my.wish': 'Mi lista de deseos',
  'footer.my.join': 'Conviértase en socio miembro',
  'footer.email': 'Correo electrónico',
  'footer.gfemail': 'Correo electrónico oficial',
  'footer.fwemail': 'Correo electrónico de servicio',
  'footer.address': 'Dirección',

  'apply.success': 'Solicitud exitosa',
  'apply.success.desc': 'Aplicado con éxito, vaya a la cuenta de inicio de sesión',

  // categorias
  'category.title': 'Categorías relacionadas',
  'category.all': 'Todas las categorías',

  //detalle
  'detail.store.home': 'Tienda de inicio',
  'detail.sale.items': 'Lista de productos',
  'detail.recommend': 'Recomendar',
  'detail.orders': 'Ventas',
  'detail.quantity': 'Cantidad',
  'detail.pieces.available': 'Disponible',
  'detail.delivery': 'Entrega',
  'detail.free.shipping': 'Envío gratis',
  'detail.estimated.delivery': 'Llegada estimada',
  'detail.days': 'dias',
  'detail.buy.now': 'Comprar',
  'detail.add.to.cart': 'Agregar al carrito',
  'detail.buyer.protection': 'Protección del Comprador',
  'detail.money.guarantee': 'Garantía de devolución de dinero',
  'detail.refund.desc': 'Obtenga un reembolso completo si el artículo no se corresponde con la descripción o no se entregó',
  'detail.description': 'Descripción del producto',
  'detail.customer.reviews': 'Opiniones de clientes',
  'detail.specifications': 'Especificaciones del producto',
  'detail.top.selling.products': 'Productos más vendidos',
  'detail.recommended.for.you': 'Recomendado para usted',
  'detail.sold': 'Ventas',
  'detail.receipt': 'Confirmar recibo',
  'detail.receipt.title': '¿Confirmar la recepción del pedido? ',
  'detail.receipt.content': 'Después de la confirmación, la orden de transacción se completa',
  'detail.comment': 'Comentario',
  'detail.refund.title': 'Confirmar solicitud de reembolso',
  'detail.refund.content': 'Después de la confirmación, el pedido solicitará un reembolso',
  'detail.sqtk': 'Solicitar reembolso',

  //confirmar
  'confirm.shipping.address': 'Dirección del destinatario',
  'confirmar.cambio': 'Cambiar',
  'confirm.payment.methods': 'Métodos de pago',
  'confirm.summary': 'Liquidación',
  'confirm.total.item.costs': 'Artículos totales',
  'confirmar.total': 'Total',
  'confirm.checkout': 'Ir a la caja',
  'confirm.place.order': 'Realice un pedido ahora',
  'confirm.pay.now': 'Pagar ahora',
  'confirm.order.desc': "Después de hacer clic en 'Realizar pedido', confirmo que he leído y confirmo",
  'confirm.order.policy': 'Todos los términos y políticas',
  'confirm.payment': 'Global Shopping garantiza la seguridad de su información y pago',

  // DIRECCIÓN
  'address.title': 'Dirección de recibo',
  'dirección.predeterminada': 'predeterminada',
  'dirección.edit': 'Editar',
  'dirección.eliminar': 'Eliminar',
  'address.new': 'Crear una nueva dirección',
  'dirección.contacto': 'Contacto',
  'dirección.dirección': 'Dirección',
  'dirección.teléfono': 'Número de teléfono',
  'address.set.default': 'Establecer predeterminado',
  'dirección.confirmar': 'Confirmar',
  'dirección.cancel': 'Cancelar',
  'address.del.title': '¿Confirmar para eliminar la dirección?',
  'address.del.content': '¿Está seguro de que desea eliminar esta dirección de envío?',

  'método.de.pago': 'Método de pago',

  // Carro de la compra
  'shopping.title': 'Carrito de la compra',
  'shopping.back.buy': 'Volver a compras especiales',
  'shopping.start.shopping': 'Empezar a comprar',

  // miembro
  'member.account': 'Centro personal',
  'member.overview': 'Resumen',
  'member.orders': 'Pedidos',
  'miembro.pago': 'Mi billetera',
  'member.address': 'Dirección de recibo',
  'member.wishlist': 'Lista de Me gusta',
  'member.followlist': 'Lista de seguimiento',
  'member.message.center': 'Centro de mensajes',
  'member.setting': 'Configuración personal',
  'member.shop.info': 'Información de la tienda',
  'member.shop.setting': 'Configuración de la tienda',
  'member.order.notify': 'Notificación de nuevo pedido',
  'member.order.tips': 'Enviando correos electrónicos al correo electrónico de la cuenta del comerciante',

  //miembro.orden
  'member.order.title': 'Mi pedido',
  'member.order.view.all': 'Ver todo',
  'member.order.all': 'Todos',
  'member.order.unpaid': 'no pagado',
  'member.order.paid': 'Pagado',
  'member.order.procurement': 'a comprar',
  'member.order.seller.paid': 'Comprado',
  'member.order.processing': 'para ser procesado',
  'member.order.shipped': 'enviado',
  'member.order.completed': 'Completado',
  'member.order.refund': 'Reembolsado',
  'member.order.all.time': 'all',
  'member.order.empty': 'Todavía no hay pedido',
  'miembro.pedido.fecha': 'fecha',
  'member.order.purchase.date': 'Comprar',
  'member.order.cpoy': 'Copiar',
  'member.order.id': 'Pedido',
  'member.order.detail': 'Detalles del pedido',
  'member.order.logistics': 'Información logística',

  // miembro.pago
  'member.payment.title': 'Mi billetera',
  'member.wallet.balance': 'saldo de la billetera',
  'member.crypto.recharge': 'Recargar',
  'member.crypto.withdrawal': 'Retiro',
  'member.crypto.bank': 'Tarjeta bancaria',
  'member.wallet.record': 'registro de depósito de monedas',
  'member.bankcard.record': 'Registro de tarjeta bancaria',
  'member.withdrawal.record': 'Registro de retiro',
  'member.income.record': 'registro de ingresos',
  'member.transaction.record': 'Registro de transacciones',
  'member.wallet.freeze': 'Congelar fondos',
  'member.wallet.profit': 'Ganancias estimadas',

  //recargar
  'recharge.currency': 'Moneda',
  'recharge.protocol': 'Protocolo',
  'recarga.qrcode': 'Código QR',
  'recharge.address': 'Dirección de la billetera',
  'recharge.copy': 'Copiar dirección',
  'recarga.ok.text': 'Confirmar',
  'recargar.cancelar.texto': 'Cancelar',

  //banco
  'bank.recharge.title': 'Recarga de tarjeta bancaria',
  'bank.title': 'Nombre del banco',
  'bank.name': 'Nombre del beneficiario',
  'bank.account': 'Cuenta receptora',
  'bank.routing': 'Número de ruta bancaria',
  'codigo.banco': 'Código del Banco',
  'bank.bankname': 'Nombre del banco',
  'bank.bankaddress': 'Dirección del banco',
  'bank.ok.text': 'Siguiente',
  'bank.ok.prev': 'Paso anterior',
  'bank.submit': 'Enviar ahora',
  'bank.amount': 'Importe de la recarga',
  'bank.amount.desc': 'Ingrese el monto de la recarga',
  'banco.tipo': 'tipo',
  'banco.usd': 'USD',
  'banco.eur': 'Euro',
  'bank.receipt.number': 'Número de recibo',
  'bank.receipt.number.desc': 'Ingrese el número de recibo de la transacción',
  'bank.credential.picture': 'Imagen de la credencial',
  'bank.credential.picture.desc': 'Cargue la imagen de la credencial',
  'bank.remark': 'Observación',
  'bank.upload': 'Subir fotos',
  'bank.text.title': 'Billetes',
  'bank.text.t1': '1. No necesita ningún comentario para la transferencia, déjelo en blanco',
  'bank.text.t2': '2. Seleccione Hong Kong como el país del hogar de Hong Kong, no agregue China',
  'bank.text.t3': '3. Remesas de EE. UU., de lunes a viernes, remesas a Europa antes de las 3:00 p. m., ¡las remesas están disponibles durante el horario laboral del banco!',
  'bank.text.t4': '4. El tiempo de pago es T+1, y el último pago es T+3 en Europa',
  'bank.text.t5': '5. Informe a la plataforma antes de transferir dinero para confirmar si la cuenta está disponible. Si la cuenta se cierra, no se pagará ninguna compensación.',
  'bank.text.t6': '6. Depósito mediante tarjeta bancaria, prevalecerá toda la información de servicio al cliente.',

  //lista de bancos
  'bank.list.title': 'Nombre del banco',
  'bank.list.code': 'Código del Banco',
  'bank.list.amount': 'Importe de la transferencia',
  'bank.list.number': 'Número de recibo',
  'bank.list.img': 'Imagen del cupón',
  'bank.list.status': 'Estado',
  'bank.list.time': 'tiempo',
  'bank.list.status1': 'En revisión',
  'bank.list.status2': 'Aprobado',
  'bank.list.status3': 'Rechazado',

  // Retiro
  'withdrawal.address.desc': '¡Ingrese la dirección de la billetera!',
  'número.de.retiro': 'Número',
  'retiro.número.real': 'llegada real',
  'withdrawal.number.desc': '¡Ingrese el monto del retiro!',
  'retiro.btn.all': 'todos',
  'retiro.saldo': 'Saldo',
  'retiro.comisión': 'comisión',
  'retiro.cantidad.real': 'Contabilizado',
  'retiro.aviso': 'Aviso',
  'withdrawal.notice.text': 'Antes de realizar la transferencia, confirme que la información de la dirección de recepción sea correcta. Una vez que se transfieren los activos, no se pueden devolver.',
  'withdrawal.notice.content': '{name}({cp_name}) comisión: valor de mercado actual {fee_rate}%/pen, estándar mínimo: {fee_min} {name}/pen',
  'retiro.enviar': 'Enviar',
  'withdrawal.choice': 'Elija una criptomoneda',
  'retiro.yzm': 'Código de verificación',
  'retiro.fs': 'Enviar',
  'withdrawal.qsryzm': 'Ingrese el código de verificación del correo electrónico',

  // recibir
  'recive.method': 'Protocolo',
  'recibir.cantidad': 'Cantidad',
  'dirección.recibida': 'dirección',
  'recibir.fecha': 'hora',
  'recive.status': 'Estado',
  'recive.create.at': 'Tiempo de transacción',
  'recibir.tipo': 'Tipo',
  'recive.befor': 'antes de la transacción',
  'recibir.saldo': 'Saldo',
  'recive.freeze': 'Congelar',
  'recive.review': 'Revisando',
  'recive.success': 'Aprobado',
  'recibir.rechazar': 'Rechazado',

  // publicidad
  'advertise.title': 'Centro de promoción',
  'advertise.shop.title': 'Promoción de la tienda',
  'advertise.shop.status': 'Estado',
  'advertise.shop.expired': 'Caducado',
  'advertise.shop.promotion': 'Promoción',
  'advertise.shop.expire.date': 'Fecha de vencimiento',
  'advertise.shop.renew': 'Renovación de publicidad',
  'advertise.shop.payable': 'Cantidad a pagar',
  'advertise.shop.explanation': 'Explicación del anuncio',
  'advertise.shop.text': 'Participe en la promoción de publicidad de la plataforma, aumente la exposición de la tienda y promueva pedidos de transacciones',
  'advertise.shop.paynow': 'Renovar ahora',
  'advertise.shop.modal.title': 'Confirmar pago',
  'advertise.shop.modal.desc': 'Confirmado para pagar la tarifa de promoción',
  'advertise.shop.modal.btn': 'Confirmar pago',

  //Lista de deseos
  'lista de deseos.title': 'Lista de deseos',
  'lista de deseos.eliminar': 'Eliminar',
  'lista de deseos.pedidos': 'Ventas',

  //Seguir lista
  'followlist.title': 'Lista de seguimiento',
  'followlist.delete': 'Borrar',
  'followlist.follow': 'Seguir',

  // almacenar
  'store.dashboard': 'Tablero',
  'store.products': 'Productos',
  'store.products.list': 'Lista de productos',
  'store.products.reviews': 'Reseñas de productos',
  'store.orders': 'Pedidos',
  'store.wallet': 'Monedero',
  'store.message': 'Centro de mensajes',
  'store.setting': 'configuración',
  'store.order.total.profit': 'Ingresos operativos totales estimados',

  //panel
  'dashboard.store.hour.views': 'Visitas en tiempo real',
  'dashboard.store.today.views': 'Visitas de hoy',
  'dashboard.product.total': 'Producto total',
  'dashboard.product.today': 'Nuevo hoy',
  'dashboard.order.total': 'Pedido Total',
  'dashboard.sales.total': 'Facturación estimada',
  'dashboard.sales.real': 'Ventas reales',
  'dashboard.sales.pay': 'Pago total',
  'dashboard.sales.profit': 'Beneficio total',
  'dashboard.commission.total': 'Ingresos totales',
  'dashboard.commission.today': 'Ganancias de hoy',
  'dashboard.order.sales': 'Ventas de productos básicos',
  'dashboard.sales.list': 'Clasificación de ventas',
  'dashboard.goods.cate.rate': 'Ratio de clasificación de productos',
  'dashboard.goods.wish': 'Ranking de productos similares',
  'dashboard.january': 'Enero',
  'dashboard.february': 'febrero',
  'dashboard.march': 'Marzo',
  'dashboard.april': 'abril',
  'dashboard.may': 'Mayo',
  'dashboard.june': 'Junio',
  'dashboard.july': 'Julio',
  'dashboard.agosto': 'agosto',
  'dashboard.septiembre': 'Septiembre',
  'dashboard.octubre': 'Octubre',
  'dashboard.noviembre': 'Noviembre',
  'dashboard.diciembre': 'Diciembre',

  // productos
  'products.add.new': 'Agregar nuevo producto',
  'products.add.from.warehouse': 'Agregar productos del almacén',
  'productos.delete': 'Eliminar',
  'productos.add': 'Agregar',
  'productos.table.img': 'imagen',
  'productos.table.name': 'nombre del producto',
  'productos.tabla.categoría': 'Categoría',
  'productos.table.wish': 'me gusta',
  'productos.table.stock': 'stock',
  'productos.tabla.precio': 'Precio',
  'products.table.profit': 'Beneficio',
  'products.table.action': 'Acción',
  'products.search.category': 'Buscar por categoría',
  'products.back.product': 'Volver a la lista de productos',
  'productos.total': 'Bienes totales',
  'productos.yes': 'Sí',
  'productos.no': 'Cancelar',
  'products.batch.add': 'Aumento de lote',
  'products.ask.add': '¿Está seguro de que desea agregar productos?',
  'products.batch.delete': 'Eliminar lote',
  'products.ask.delete': '¿Está seguro de que desea eliminar el producto?',
  'productos.top': 'Arriba',
  'products.syzd': 'Principio de página de inicio',
  'products.zdwz': 'Primera posición',
  'productos.t1': 'Promoción',
  'productos.t2': 'Preferido',
  'productos.t3': 'Tienda',
  'productos.t4': 'ninguno',

  // reseñas
  'reviews.title': 'Reseñas de productos',
  'opiniones.producto.nombre': 'Producto',
  'opiniones.usuario.nombre': 'Usuario',
  'reviews.star': 'Calificación',
  'reviews.comment': 'Contenido del comentario',
  'reviews.sku': 'Especificaciones del producto',
  'revisiones.imgs': 'Atlas',
  'opiniones.creadas': 'Fecha',

  // almacenar.pedir
  'store.order.purchase': 'Comprar ahora',
  'store.order.purchase.desc': '¿Estás seguro de que quieres pagar por este producto?',
  'store.order.purchase.yes': 'Pagar ahora',
  'store.order.purchase.no': 'Cancelar',
  'store.order.desc': 'La compra de este pedido requiere pago, y obtendrá beneficios una vez que se complete el pedido. ',
  'store.order.no': 'Número de pedido',
  'store.order.number': 'Cantidad',
  'store.order.buyer': 'Comprador',
  'store.order.total': 'Cantidad total',
  'store.order.will.earning': 'Ingresos',
  'store.order.profit': 'Beneficio',
  'store.order.dividends': 'Dividendos',
  'store.order.payment.status': 'Estado del pago',
  'store.order.seller.buy.status': 'Estado de compra',
  'store.order.status': 'Estado del pedido',
  'store.order.date': 'fecha del pedido',
  'store.order.purchase.date': 'Fecha de compra',
  'store.order.action': 'Operación',
  'store.order.purchase.amount': 'Importe de la compra',

  //ingreso
  'income.create.at': 'Tiempo récord',
  'ingresos.pedido.sn': 'Número de orden',
  'ingresos.realpay': 'Cantidad del pedido',
  'ingresos.beneficio': 'ingresos',

  //Configuración
  'setting.avatar': 'Avatar empresarial',
  'setting.upload': 'Subir',
  'setting.shop.name': 'Nombre de la tienda',
  'setting.shop.phone': 'Teléfono de contacto',
  'setting.shop.address': 'Dirección de la tienda',
  'setting.shop.save': 'Guardar',
  'setting.upload.pic': 'Subir imagen',
  'setting.send.pic': 'Enviar foto',

  //vendedor
  'beseller.title': 'Aplicar para ser comerciante',
  'beseller.account': 'Información de la cuenta',
  'beseller.store': 'Información de la tienda',
  'beseller.store.name': 'Nombre de la tienda',
  'beseller.store.address': 'Dirección de la tienda',

  // guardar casa
  'store.home.title': 'Configuración de la página de inicio',
  'store.home.topimg': 'imagen superior',
  'store.home.banner': 'Imagen del carrusel',
  'store.home.up3': 'Observaciones: al menos 3 subidas',
  'store.upload.more': 'Subir más imágenes',
  'store.home.columns': 'Columnas',
  'store.home.bgimg': 'Imagen de fondo',
  'store.goods.remark': 'Observación: 5 artículos por línea, 10 artículos por placa como máximo',
  'store.home.select': 'Seleccionar producto',
  'store.home.add': 'Agregar columna',

  //informacion personal
  'setting.update.user': 'Información personal',
  'setting.update.info': 'Modificar información',
  'setting.user.avatar': 'Avatar de usuario',
  'setting.user.nickname': 'User Nickname',
  'setting.user.nickname.desc': 'Ingrese el apodo del usuario',

  'setting.safe.title': 'Información de seguridad',
  'setting.user.passwd': 'Cambiar contraseña',
  'setting.passwd.title1': 'Contraseña anterior',
  'setting.passwd.desc1': 'Ingrese la contraseña anterior',
  'setting.passwd.title2': 'Nueva contraseña',
  'setting.passwd.desc2': 'Ingrese una nueva contraseña',
  'setting.passwd.title3': 'Confirmar contraseña',
  'setting.passwd.desc3': 'Ingrese una contraseña de confirmación',
  'setting.invitation': 'Invitar amigos',
  'setting.copy': 'Copiar',

  'adv.add.products': 'Agregar productos promocionales',
  'adv.list.title': 'Promoción de producto',
  'adv.type': 'Tipo de promoción',
  'adv.begin.time': 'Tiempo de promoción',
  'adv.end.time': 'Hora de finalización',
  'adv.status': 'Estado',
  'adv.status.s1': 'Promoviendo',
  'adv.status.s2': 'Fin',
  'adv.add.back': 'Volver a la lista de promocionados',
  'adv.recharge': 'Recarga puntos promoción',
  'adv.select.goods': 'Seleccionar productos',
  'adv.select.btn': 'Productos Promocionados',
  'adv.recharge.title': 'Recarga Puntos Promoción',
  'adv.recharge.balance': 'Saldo de puntos',
  'adv.punto': 'punto',
  'adv.point.rate': 'Proporción de puntos',
  'adv.recharge.amount': 'Cantidad de recarga',
  'adv.recharge.input.amount': 'Ingrese el monto de la recarga',
  'adv.select.mod': 'Seleccionar panel',
  'adv.mod1': 'Columnas recomendadas',
  'adv.mod2': 'Columna preferida',
  'adv.mod3': 'Columna de venta caliente',
  'adv.mod4': 'Excelente lista',
  'adv.mod5': 'Lista de clasificación',
  'adv.mod6': 'Promoción detallada',
  'adv.ge': 'Cantidad',
  'adv.xs': 'hora',
  'adv.xzbk': 'Seleccionar plato',
  'adv.syme': 'Cuota restante',
  'adv.tgsc': 'Duración de la promoción',
  'adv.tgjg': 'Precio de promoción',
  'adv.syye': 'Saldo actual',
  'adv.tgye': 'Saldo de promoción',
  'adv.yj1': 'esperado',
  'adv.yj2': 'Iniciar promoción',
  'adv.pay.point': 'Puntos de consumo',

  'wallet.ykcsxf': 'La tarifa de manejo ha sido deducida',
  'wallet.sxf': 'Tarifa de servicio',
  'wallet.zxje': 'Cantidad mínima',
  'wallet.24hxe': 'límite de 24 horas',
  'wallet.desc.text': 'Bajo ninguna circunstancia, el oficial no te pedirá que transfieras dinero a una cuenta, ni te pedirá un código de verificación. No participe en actividades ilegales como compras en nombre de otros, lavado de dinero y recaudación de fondos ilegal, y tenga cuidado con el fraude en línea',
  'wallet.cunbi.text': 'Solo puede depositar {nombre} en esta dirección, no se recuperarán otros activos',

  'credit.title': 'Límite de crédito',
  'credit.ed': 'Cuota',
  'credit.hk': 'Reembolso',
  'credit.kyye': 'Saldo disponible',
  'credit.dqqk': 'Atrasos actuales',
  'credit.hkje': 'Cantidad de reembolso',
  'credit.hkje.desc': 'Ingrese el monto del reembolso',
  'credit.cgje': 'Importe de la compra',
  'credit.qrz': 'Ir a autenticación',
  'credit.srxyj': 'Ingrese el monto del pago del crédito',

  'store.gzl': 'Cantidad de atención',
  'store.wxz': 'Ilimitado',

  'auth.smrz': 'Autenticación de nombre real',
  'auth.rzlx': 'Tipo',
  'auth.sfzm': 'Frente de la tarjeta de identificación',
  'auth.sffm': 'El reverso de la tarjeta de identificación',
  'auth.zsxm': 'nombre real',
  'auth.zjhm': 'Número de documento',
  'auth.yyzz': 'Licencia comercial',
  'auth.gsmc': 'Nombre de la empresa',
  'auth.shz': 'Auditoría',
  'auth.ytg': 'Aprobado',
  'auth.ybh': 'Rechazado',
  'auth.zt': 'estado',
  'auth.gr': 'Personal',
  'auth.gs': 'Empresa',
  'auth.ljtj': 'Solicitar autenticación',
  'auth.wrz': 'No autenticado',

  'credit.qts': 'Ver descripción',
  'store.spss': 'Límite superior de productos básicos',
  'store.dpfh': 'Almacenar dividendos',
  'store.qydj': 'Nivel de equidad',
  'tienda.nivel': 'nivel',
  'store.jian': 'piezas',

  'order.notify.email': 'Debido al control de riesgos del correo electrónico, los correos electrónicos de recordatorio de pedidos de la plataforma se envían con frecuencia y serán interceptados. Para recibirlos a tiempo, se recomienda agregar {email} al inicio de sesión lista blanca de correo electrónico',

  'setting.sjxx': 'Información comercial',
  'setting.smrz': 'Autenticación de nombre real',
  'setting.aqzx': 'Centro de Seguridad',
  'setting.tzxx': 'Información de notificación',
  'setting.dpzx': 'Página de inicio de decoración',
  'setting.sjqy': 'Intereses comerciales',
  'setting.sjdjqy': 'Descripción de los beneficios a nivel empresarial',

  'setting.fhbky': 'El dividendo actual no está disponible',
  'setting.spgzyzy': 'Reglas y pautas de la tienda',

  'desc.dqqy': 'Patrimonio actual',
  'desc.xjqy': 'Intereses subordinados',
  'desc.sjqy_0': 'Límite de crédito de la tienda',
  'desc.sjqy_1': 'Almacenar dividendos',
  'desc.sjqy_2': 'Número de productos que se pueden publicar',

  'share.yqlb': 'Lista de invitados',
  'share.zmj': 'Vendedor total',
  'share.zdd': 'Pedido total',
  'share.zsy': 'Ingresos totales',
  'share.wcl': 'sin procesar',
  'share.ycl': 'Procesado',
  'share.ywc': 'Completado',
  'share.dpmc': 'Nombre de la tienda',
  'share.ddl': 'Cantidad del pedido',
  'share.jjsy': 'Ingresos de corretaje',
  'share.zts': 'Número total',

  'chat.state': 'estado',
  'chat.zx': 'En línea',
  'chat.ljz': 'Conectando',

  'bind.wallet.title': 'Dirección de retiro',
  'bind.address.title': 'Agregar dirección',
  'bind.bj': 'Editar',
  'bind.sc': 'Eliminar',
  'bind.qrsc': '¿Está seguro de que desea eliminar esta dirección?',
  'bind.qbdz': 'Dirección de billetera',
  'bind.tjsj': 'Agregar hora',
  'bind.cz': 'Operación',
  'withdrawal.address.choice': '¡Por favor elija la dirección de retiro!',

  'order.sxsj': 'Tiempo efectivo',
  'order.ljsj': 'inmediatamente efectivo',
  'order.dssj': 'Tiempo efectivo',

  'store.st.zt': 'Estado de la tienda',
  'tienda.st.zc': 'normal',
  'store.st.zc.desc': 'Tu tienda es normal, sigue así',
  'store.st.yc': 'Excepción',
  'store.st.yc.desc': 'Tu tienda tiene algunos pedidos anormales o recibió quejas de clientes',
  'store.st.dj': 'Congelar',
  'store.st.dj.desc': 'Tu tienda tiene cierto riesgo operativo y los fondos están congelados',
  'store.st.jy': 'deshabilitado',
  'store.st.jy.desc': 'Tu tienda ha violado los acuerdos y reglas relacionados con la plataforma y ha sido deshabilitada',

  'tarea.sy': 'Página de inicio',
  'task.rwlbo': 'Lista de tareas',
  'task.rwxqo': 'Detalles de la tarea',
  'task.ljcy': 'Participa ahora',
  'task.gdrw': 'Más tareas',
  'task.rwlb': 'Lista de tareas de captura',
  'task.qbrw': 'Todas las tareas',
  'task.wcyd': 'Yo participé en',
  'task.qdz': 'Recibiendo pedidos',
  'tarea.ywc': 'Completado',
  'task.djy': 'a negociar',
  'task.ddje': 'Cantidad del pedido',
  'task.cyrs': 'Número de participantes',
  'task.ewsy': 'Beneficios adicionales',
  'tarea.jzsj': 'Fecha límite',
  'task.ckxq': 'Ver detalles',
  'task.rwxq': 'Detalles de la tarea',
  'task.cylb': 'Lista de participantes',
  'task.qdcyrw': '¿Está seguro de que desea participar en esta tarea de pedido?',
  'tarea.sd': 'Sí',
  'tarea.bue': 'no',

  'tarea.wdsy': 'Ingresos',
  'task.wdfh': 'Dividendos',
  'task.wdqd': 'Obtener pedido',
  'task.dd.leixin': 'Tipo de orden',
  'task.dd.qdrw': 'Tarea de pedido rápido',
  'task.dd.ptdd': 'Orden ordinario',

  'flow.lltg': 'Promoción de flujo',
  'flow.gmll': 'Paquete de datos de compra',
  'flow.dqtc': 'Paquete actual',
  'flow.tc1': 'Paquete',
  'flujo.sy2': 'restante',
  'flujo.tian3': 'día',
  'flow.jlzs': 'Número total de registros',
  'flow.lltc': 'Paquete de flujo',
  'flujo.ll': 'flujo',
  'flow.gmsj': 'Tiempo de compra',
  'flow.tcjg': 'Precio del paquete',
  'flow.sjzf': 'pago real',
  'flow.tcsm': 'Descripción del paquete',
  'flow.tcsm.sm': 'La compra de este paquete aumentará la exposición de la tienda y obtendrá más tráfico de clientes. Con el fin de obtener más pedidos y aumentar los ingresos de la tienda. ',

  'flow.tcmc': 'Nombre del paquete',
  'flow.gmsc': 'Duración de la compra',
  'flow.zje': 'Cantidad total',
  'flow.jzsj': 'Fecha límite',
  'flow.gmrq': 'Fecha de compra',
  'flujo.tian1': 'día',
  'flow.zhou1': 'semana',
  'flujo.yue1': 'mes',

  'share.dj': 'nivel',

  'share.hz': 'Transferir',
  'share.hzd': 'Transferir a',
  'share.qbye': 'saldo de la billetera',
  'share.tgje': 'Importe de la promoción',
  'share.hzje': 'Importe de la transferencia',
  'share.qsrhzje': 'Ingrese el monto de la transferencia',

  '_inv._title': 'Descripción de la invitación',
  '_inv._t1': '1.Invitación y relación invitada',
  '_inv._desc1': 'A invita a B, B invita a C, C invita a D. El nivel más alto es 4, si D vuelve a invitar a E, entonces E no tiene nada que ver con A. ',
  '_inv._t2': '2. El que invita recibe una recompensa',
  '_inv._desc2': 'A obtiene el 4% del monto total completado por el pedido B, A obtiene el 2% del monto total completado por el pedido C y A obtiene el 1% del monto total completado por el pedido D',

  '_index._gm': 'Comprar',
  '_index._sm': 'Descripción: Aumente la exposición de la tienda y atraiga tráfico',

  '_n.dlyzc': 'Inicio de sesión y registro',
  '_n.hy4': 'Hay 4 más',
  '_n.ckgd': 'Ver más',
  '_n._cxhzk': 'Promociones y descuentos',
  '_n._cwyp': 'Suministros para mascotas',
  '_n._tjsp': 'Oferta especial',
  '_n._rmfl': 'Categorías populares',
  '_n._tjsp2': 'Productos recomendados',
  '_n._rxsp': 'Productos recomendados',
  '_n._rmsp': 'Artículos populares',
  '_n._gdsp': 'Más productos',
  '_n._yzsj': 'Comerciante de calidad',

  '_n._zxsc': 'Centro comercial en línea',
  '_n._fkyys': 'Pago y Envío',
  '_n._xsgz': 'Reglas de venta',
  '_n._hhjth': 'Cambios y devoluciones',
  '_n._lxwm': 'Contáctanos',
  '_n._sczc': 'política del centro comercial',
  '_n._tkytj': 'Términos y Condiciones',
  '_n._tuzc': 'Política de Devolución',
  '_n._zczc': 'Política de soporte',
  '_n._yszc': 'Política de Privacidad',
  '_n._scjj': 'Introducción al centro comercial',
  '_n._ljgm': 'Comprar ahora',

  '_n2._rxsp': 'Artículos de gran venta',
  '_n2._pl': 'Comentario',
  '_n2._sjxx': 'Información comercial',
  '_n2._rhsh': 'Cómo recibir la mercancía',
  '_n2._xsxd': 'Pedido en línea',
  '_n2._jscldd': 'Servicio al cliente dedicado',
  '_n2._mfps': 'Envío gratuito',
  '_n2._2xsfh': 'Entrega relámpago',
  '_n2._1d5tdd': 'Llegó entre 1 y 5 días',
  '_n2._tkzd': 'Correo Urgente',
  '_n2._ckfh': 'Envío desde almacén',
  '_n2._zcps': 'Almacén cercano',
  '_n2._jsd': 'Punto de recepción',
  '_n2._qsjps': 'Entrega mundial',
  '_n2._thtj': 'Condiciones de devolución',
  '_n2._thtjsm': 'Puedes cambiar o devolver el producto dentro de los 14 días siguientes a la fecha de compra',
  '_n2._gycp': 'Acerca del producto',
  '_n2._yc': 'Ocultar',
  '_n2._tiao': 'Tiao',
  '_n2._sypl': 'Todos los comentarios',
  '_n2._lxfk': 'Dejar comentarios',
  '_n2._xzspgg': 'Por favor seleccione las especificaciones del producto',
  '_n2._qxdl': 'Por favor inicia sesión primero',
  '_n2._rnsj': 'Como puedes ver',
  '_n2._rx': 'Venta caliente',

  '_n3._qrdd': 'Confirmar pedido',
  '_n3._njsp': '{num} elementos',
  '_n3._ddzf': 'Pago del pedido',
  '_n3._wydlsp': 'Bienvenido a iniciar sesión en la tienda',

  '_n3._sjwfgm': 'El comerciante no puede comprar bienes',
  '_n3._sjbnlt': 'Las empresas no pueden chatear',
  '_chat._hc': 'Retirar',

  '_zc._sryxdz': 'Por favor ingresa tu correo electrónico',
  '_zc._yxgscw': 'Error de formato de correo electrónico',
  '_zc._qsrmm': 'Por favor ingrese la contraseña',
  '_zc._lcmmbxd': 'Dos contraseñas son inconsistentes',
  '_zc._hdyzwtg': 'Verifique mediante el control deslizante',
  '_zc._qsryqm': 'Por favor ingresa el código de invitación',
  '_zc._yhzh': 'Registrar cuenta de usuario',
  '_zc._qzc': 'Ir al registro',
  '_zc._srdpm': 'Por favor ingresa el nombre de la tienda',

  '_zc._gszz': 'Cualificación de la empresa',
  '_zc._ptzz': 'Cualificaciones relacionadas con la plataforma',

  '_dd._tijd': 'Enviando pedido',
  '_dd._xzdz': 'Por favor seleccione la dirección de entrega',
  '_dd._zfz': 'Pago en curso',

  '_wd._zf': 'pago',
  '_wd._cz': 'Recargar',
  '_wd._txsq': 'Solicitud de retiro',
  '_wd._ddsy': 'Ingresos del pedido',
  '_wd._cgzf': 'Compra',
  '_wd._txbh': 'Rechazar',
  '_wd._txtg': 'Aprobado',
  '_wd._ddtk': 'Reembolso del pedido',
  '_wd._yehz': 'Transferir',
  '_wd._gmll': 'Comprar tráfico',
  '_wd._zjkc': 'deducción de alquiler',
  '_wd._fk': 'Bien',
  '_wd._ns': 'Impuesto',
  '_wd._bzj': 'Margen',

  '_xxtz._zntz': 'Notificación del sitio',
  '_xxtz._fh': 'Regresar',
  '_xxtz._jzgd': 'Cargar más',
  '_xxtz._mysj': 'No más',

  '_sjsy._jrdd': 'Pedido de hoy',
  '_sjsy._rxse': 'Ventas diarias',

  '_sjsy._xh': 'Índice',

  '_wd._nbjy': 'Transferencia interna',
  '_wd._qsruid': 'Por favor ingrese el UID del beneficiario',
  '_wd._je': 'Cantidad',
  '_wd._qsrhzje': 'Por favor ingrese el monto de la transferencia',
  '_wd._skr': 'Beneficiario',
  '_wd._nbhzsm': 'Para realizar transferencias internas a usuarios de la plataforma, verifique cuidadosamente la información del beneficiario',

  '_st._spxlphb': 'Ranking de ventas de productos',
  '_st._czsp': 'Restablecer búsqueda',

  '_st._pljrgwc': 'Unirse a todos',

  '_sj._yxz': 'Seleccionado',
  '_sj._hjzje': 'Monto total total',
  '_sj._zdcr': 'Depositar automáticamente',

  '_wd._ddfh': 'Dividendo',

  '_dd._csqx': 'Cancelación de pedido',

  '_tx._yhktx': 'Retiro bancario',
  '_tx._sryhmc': 'Por favor ingrese el nombre del banco',
  '_tx._srskrmc': 'Por favor ingrese el nombre del beneficiario',
  '_tx._srskzh': 'Por favor ingrese el número de cuenta de pago',
  '_tx._sryhluh': 'Ingrese el número de ruta bancaria',
  '_tx._sryhdm': 'Por favor ingrese el código bancario',
  '_tx._sryhdz': 'Por favor ingrese la dirección del banco',
  '_tx._txje': 'Monto del retiro',
  '_tx._srtxje': 'Por favor ingrese el monto del retiro',
  '_tx._txkc': 'Tarifa de retiro {_sxf}%',

  '_yye._yyye': 'Ventas mensuales',
  '_yye._ysy': 'Ingresos mensuales',

  '_sj._hjzsy': 'Ingresos totales totales',

  '_yhdl._dl': 'Iniciar sesión',
  '_yhdl._zc': 'Crear cuenta',

  '_zdy._24zrkf': 'Estamos aquí para ayudarte 24 horas al día, 7 días a la semana',
  '_zdy._zhsz': 'Configuración de la cuenta',
  '_zdy._mfps': 'Por tiempo limitado, puedes obtener envío gratuito en 2 días hábiles en miles de artículos en áreas seleccionadas. ',
  '_zdy._gmsp': 'Comprar nuevos productos',
  '_zdy._czyh': 'Grandes ofertas, disfruta de tus productos favoritos',
  '_zdy._xsgw': '¡Compras por tiempo limitado! Haga su pedido antes del 12 de mayo al mediodía, hora del Este, para obtener envío gratis. ¡Entregado a su puerta por un automóvil exclusivo! ',
  '_zdy._ssgjc': 'Ingrese la búsqueda de palabras clave',

  '_zdy._rmjx': 'Tus mejores opciones',

  '_dd._plcz': 'Operación por lotes',
  '_dd._plcg': 'Compra al por mayor',
  '_dd._qgxcgd': 'Por favor verifique el pedido que necesita comprar',
  '_dd._yxddbcg': 'El pedido seleccionado no requiere compra',
  '_dd._qdyplcgm': '¿Está seguro de que desea comprar el pedido seleccionado al por mayor? ,Cantidad:{n}',
  '_dd._qdcg': 'Confirmar compra',
  '_dd._qx': 'Cancelar',
  '_dd._plcgwc': 'Compra por lotes completada, exitosa: {s}/{t}',
  '_dd._zzcgz': 'Comprando',
  '_st._czhk': 'Recarga y paga',
  '_st._dkje': 'monto del préstamo',
  '_st._dkjl': 'historial de préstamos',
  '_st._hkfs': 'Reembolso',
  '_st._hkje': 'Monto de reembolso',
  '_st._jrong': 'Finanzas del centro comercial',
  '_st._ljsq': 'aplicar inmediatamente',
  '_st._qrshkje': 'Por favor ingrese el monto del reembolso',
  '_st._qsrdkje': 'Por favor ingrese el monto del préstamo',
  '_st._shz': 'bajo revisión',
  '_st._sqdklx': 'préstamo',
  '_st._sqhk': 'Solicitar reembolso',
  '_st._sqhklx': 'reembolso',
  '_st._sqjk': 'solicitar un préstamo',
  '_st._sqlx': 'tipo',
  '_st._ybh': 'rechazar',
  '_st._yjk': 'Prestado',
  '_st._ytg': 'aprobar',
  '_st._zzhk': 'Pendiente de reembolso',

  '_st._dqed': 'Monto del préstamo',
  '_st._ccdked': 'No se puede exceder el límite del préstamo',
  '_st._tjsqz': 'La solicitud se está enviando',
  '_st._dklx': 'Interés',
  '_st._dkll': 'Tasa de interés del préstamo:{n}%',
  '_kdts._ts': 'Abierto durante {t} días',
  '_jltg._tgje': 'Inversión publicitaria',

  '_st._yqk': 'Atrasos',
  '_st._qb': 'Todos',

  '_spxx._spbm': 'código',

  '_scrz._yyzz': 'Licencia comercial',

  '_sjhb._sjbt': 'Cupón',
  '_sjhb._kfxd': 'Lista de cupones',
  '_sjhb._fxlb': 'Compartir lista',
  '_sjdhb._hbje': 'denominación',
  '_sjdhb._fxzs': 'Número total de acciones',
  '_sjdhb._fxcz': 'Operación',
  '_sjdhb._fxyhj': 'Compartir',
  '_sjdhb._gqsj': 'Tiempo de vencimiento',
  '_sjdhb._lqsj': 'Hora de recolección',
  '_sjdhb._lqbm': 'codificación',
  '_sjdhb._wlq': 'No recibido',
  '_sjdhb._wsy': 'No utilizado',
  '_sjdhb._ysy': 'usado',
  '_sjdhb._ylq': 'Recibido',
  '_sjdhb._ygq': 'Caducado',
  '_sjdhb._fuzi': 'Copiar',
  '_sjdhb._lqyhj': 'Cupón',
  '_sjdhb._lqlqyhj': 'Recibir cupón',
  '_sjdhb._yhjbm': 'Código de cupón',
  '_sjdhb._ljlq': 'Consíguelo ahora',

  '_sjdhb._hqgdyhj': 'Obtén más cupones para ti',
  '_sjdhb._nddzyj': 'Tu correo electrónico',
  '_sjdhb._dy': 'Suscribirse',
  '_sjdhb._zffs': 'Método de pago',
  '_sjdhb._zfjs': 'Los usuarios de Wholesale Global Station provienen de 112 países de todo el mundo y utilizan USDT/ETH/BTC para la liquidación. USDT/ETH/BTC es un método de transacción sin fronteras que permite transacciones instantáneas de bajo costo en todo el mundo, sin esperas y sin tarifas internacionales. ',
  '_sjdhb._wzbq': 'Copyright 2023 Wholesale Limited',

  '_sjdk.yhqbt': 'Actividades de descuento',
  '_sjdk.yhq': 'Cupón',
  '_sjdk.dkq': 'Cupones de comerciante',

  '_sjdk._gmzk': 'Descuento de compra',
  '_sjdk._yhez': 'Monto del descuento',
  '_sjdk._yhsmwa': 'Monto de descuento de compra, puede dar a los compradores cupones de cualquier denominación para deducir el monto del pedido, a fin de atraer a los compradores a comprar productos y aumentar las ventas de la tienda. ',

  '_sjdk._yuee': 'Equilibrio',
  '_sjdk._gmyue': 'Compra',
  '_sjdk._shoujia': 'Precio',
  '_sjdk._zsdx': 'Regalo para',
  '_sjdk._zsyhj': 'Regala cupones',
  '_sjdk._yhjje': 'Cantidad',
  '_sjdk._sryhjje': 'Por favor ingrese el monto de la tarjeta',
  '_sjdk._xftj': 'Condiciones',
  '_sjdk._srxftj': 'Por favor, introduzca las condiciones del importe del pedido',
  '_sjdk._yhqtj': 'Nota: El importe del pedido debe cumplir las condiciones para ser utilizado',
  '_sjdk._ljzs': 'Regalo',
  '_sjdk._sysj': 'Tiempo de uso',
  '_sjdk._zssj': 'Hora de regalar',
  '_sjdk._wsy': 'No utilizado',
  '_sjdk._yss': 'Usado',
  '_sjdk._qrzs': 'Cómo donar',
  '_sjdk._qzs': 'Ir a regalar',
  '_sjdk._yhjjl': 'Registro de cupón',
  '_sjdk._syyhq': 'Cupón',
  '_sjdk._xfmje': 'Cantidad >= ${n}'
}
