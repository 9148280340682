import request from '@/utils/request'

const api = {
  getCouponList: 'coupon/getCouponList',
  payCoupon: 'coupon/payCoupon',
  getMyCoupon: 'coupon/getMyCoupon',
  giveaway: 'coupon/giveaway',
  getCouponRecord: 'coupon/getCouponRecord'
}
export default {
  getCouponRecord(parameter) {
    return request({
      url: api.getCouponRecord,
      method: 'post',
      data: parameter
    })
  },
  giveaway(parameter) {
    return request({
      url: api.giveaway,
      method: 'post',
      data: parameter
    })
  },
  getCouponList(parameter) {
    return request({
      url: api.getCouponList,
      method: 'post',
      data: parameter
    })
  },
  payCoupon(parameter) {
    return request({
      url: api.payCoupon,
      method: 'post',
      data: parameter
    })
  },
  getMyCoupon(parameter) {
    return request({
      url: api.getMyCoupon,
      method: 'post',
      data: parameter
    })
  }
}
