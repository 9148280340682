export default {
  'nav.account': 'Trung tâm cá nhân',
  'nav.language': 'Tiếng Trung Giản thể',
  'signup.success': 'Gửi thành công',

  //lỗi
  'contact.empty': 'Không được để trống thông tin liên hệ',
  'phone.country.empty': 'Không được để trống tiền tố điện thoại',
  'phone.empty': 'Không thể để trống điện thoại',
  'email.valid': 'Vui lòng nhập đúng email',

  // căn cứ
  'base.success': 'thành công',
  'base.save': 'Lưu',
  'base.slide': 'Vui lòng kéo thanh trượt để xác minh',
  'base.send.code': 'Gửi mã xác nhận',
  'base.send.time': '({time}) gửi lại mã xác minh',
  'base.send.success': 'Gửi thành công',
  'base.view.more': 'Xem thêm',
  'base.Language': 'Ngôn ngữ',
  'base.previous': 'Trang trước',
  'base.next': 'Trang tiếp theo',
  'base.total.page': 'Tổng cộng {page} trang',
  'base.go.to.page': 'Đi tới trang',
  'base.go': 'đi tới',
  'base.followers': 'Người theo dõi',
  'base.follow': 'Theo dõi',
  'base.following': 'Đã theo dõi',
  'base.visit.store': 'Duyệt qua cửa hàng',
  'base.contact': 'Liên hệ với chúng tôi',
  'base.delivery': 'Thời gian giao hàng ước tính: 1-7 ngày',
  'base.search': 'Tìm kiếm',
  'base.search.text': 'Nhập từ khóa tìm kiếm',
  'base.server': 'Dịch vụ khách hàng trực tuyến',

  //mục lục
  'text.categories.title': 'Danh mục sản phẩm',
  'text.welcome': 'Chào mừng đến với mua sắm!',
  'text.wishlist': 'Danh sách thích',
  'text.account': 'Trung tâm cá nhân',
  'text.orders': 'Đơn hàng',
  'text.messages': 'Tin nhắn',
  'text.suggest.title': 'Ưu đãi độc quyền',
  'text.suggest.content': 'Chỉ dành cho thành viên cao cấp toàn cầu mới!',
  'text.super.deals': 'Sản phẩm hàng đầu, giá không tưởng.',

  //đăng ký
  'signup.register': 'Đăng ký',
  'signup.signin': 'Đăng nhập',
  'signup.store.signin': 'Đăng nhập doanh nghiệp',
  'signup.sign.out': 'Đăng xuất',
  'signup.email': 'Địa chỉ email',
  'signup.password': 'Mật khẩu',
  'signup.qr.password': 'Xác nhận mật khẩu',
  'signup.confirm.password': 'Xác nhận mật khẩu',
  'signup.forgot.password': 'Quên mật khẩu',
  'signup.invitation': 'Mã thư mời',
  'signup.char': 'Nhân vật',
  'signup.contains': 'Chứa số, chữ cái hoặc ký hiệu',
  'signup.qr.contains': 'Hai mật khẩu không nhất quán',
  'signup.create.account': 'Tạo tài khoản',
  'signup.agree': 'Tạo một tài khoản, bạn đồng ý',
  'signup.member.agreement': 'Thỏa thuận thành viên',
  'signup.and': 'và',
  'signup.privacy.policy': 'Chính sách bảo mật',
  'signup.email.code': 'Email xác minh',
  'signup.last.step': 'Bước cuối cùng',
  'signup.send.email': 'Vui lòng nhập mã gồm 4 chữ số được gửi tới {email}',
  'signup.modify.email': 'Sửa đổi email',
  'signup.verify.email': 'Xác minh email',
  'signup.have.store': 'Có tài khoản người bán',
  'signup.goto.signin': 'Đăng nhập doanh nghiệp',
  'signup.no.store': 'Không có tài khoản người bán',
  'signup.goto.store': 'Người bán đăng ký',
  'signup.next': 'Tiếp theo',
  'signup.your.email': 'email của bạn',
  'signup.code.text': 'Mã xác nhận',
  'signup.submit.signup': 'Đăng ký ngay bây giờ',
  'signup.smrz': 'Xác thực tên thật',
  'signup.derb': 'Bước thứ hai',
  'signup.qsrxm': 'Vui lòng nhập tên tài liệu',
  'signup.qsrhm': 'Vui lòng nhập số ID',

  //quên
  'forgot.title': 'Đặt lại mật khẩu',
  'forgot.btn.check': 'Xác minh email',
  'forgot.reset.now': 'Đặt lại ngay bây giờ',

  //cửa hàng
  'store.info.open': 'Mở {y} tuần',

  // chân trang
  'footer.great.value': 'Giá trị lớn',
  'footer.great.value.desc': 'Chúng tôi đưa ra mức giá cạnh tranh cho hơn 100 triệu mặt hàng.',
  'footer.shopping': 'Mua sắm Toàn cầu',
  'footer.shopping.desc': 'Chúng tôi giao hàng đến hơn 200 quốc gia và khu vực, và trang web của chúng tôi có sẵn bằng 7 ngôn ngữ.',
  'footer.safe.payment': 'Thanh toán An toàn',
  'footer.safe.payment.desc': 'Thanh toán bằng các phương thức thanh toán an toàn và phổ biến nhất trên thế giới.',
  'footer.shop.with.trust': 'Tự tin mua sắm',
  'footer.shop.with.trust.desc': 'Chính sách bảo vệ người mua của chúng tôi bao gồm toàn bộ quá trình mua hàng của bạn.',
  'footer.help.center': 'Trung tâm trợ giúp',
  'footer.help.center.desc': 'Hỗ trợ trong mọi điều kiện thời tiết để tạo trải nghiệm mua sắm suôn sẻ.',
  'footer.terms.conditions': 'Điều khoản và Điều kiện',
  'footer.return.policy': 'Chính sách hoàn trả',
  'footer.support.policy': 'Chính sách hỗ trợ',
  'footer.privacy.policy': 'Chính sách Bảo mật',
  'footer.be.seller': 'Trở thành người bán',
  'footer.apply.now': 'Đăng ký ngay bây giờ',
  'footer.stay.connected': 'Duy trì kết nối',

  'footer.about.us': 'Giới thiệu về chúng tôi',
  'footer.about.company': 'Hồ sơ Công ty',
  'footer.about.video': 'Video giới thiệu',
  'footer.contact': 'Thông tin liên hệ',

  'footer.my.account': 'Tài khoản của tôi',
  'footer.my.logout': 'Đăng xuất',
  'footer.my.order': 'Lịch sử Đặt hàng',
  'footer.my.wish': 'Danh sách mong muốn của tôi',
  'footer.my.join': 'Trở thành đối tác thành viên',
  'footer.email': 'Email',
  'footer.gfemail': 'Email chính thức',
  'footer.fwemail': 'Email dịch vụ',
  'footer.address': 'Địa chỉ',

  'apply.success': 'Đăng ký thành công',
  'apply.success.desc': 'Đã đăng ký thành công, chuyển đến tài khoản đăng nhập',

  // Thể loại
  'category.title': 'Các danh mục liên quan',
  'category.all': 'Tất cả danh mục',

  //chi tiết
  'detail.store.home': 'Cửa hàng Trang chủ',
  'detail.sale.items': 'Danh sách sản phẩm',
  'detail.recommend': 'Đề xuất',
  'detail.orders': 'Bán hàng',
  'detail.quantity': 'Số lượng',
  'detail.pieces.available': 'Có sẵn',
  'detail.delivery': 'Giao hàng',
  'detail.free.shipping': 'Giao hàng miễn phí',
  'detail.estimated.delivery': 'Ước tính hàng đến',
  'detail.days': 'ngày',
  'detail.buy.now': 'Mua',
  'detail.add.to.cart': 'Thêm vào giỏ hàng',
  'detail.buyer.protection': 'Bảo vệ người mua',
  'detail.money.guarantee': 'Đảm bảo hoàn lại tiền',
  'detail.refund.desc': 'Được hoàn lại toàn bộ tiền nếu mặt hàng không đúng như mô tả hoặc không được giao',
  'detail.description': 'Mô tả sản phẩm',
  'detail.customer.reviews': 'Nhận xét của khách hàng',
  'detail.specations': 'Thông số kỹ thuật sản phẩm',
  'detail.top.selling.products': 'Sản phẩm bán chạy nhất',
  'detail.recommended.for.you': 'Được đề xuất cho bạn',
  'detail.sold': 'Bán hàng',
  'detail.receipt': 'Xác nhận đã nhận',
  'detail.receipt.title': 'Xác nhận đã nhận đơn đặt hàng? ',
  'detail.receipt.content': 'Sau khi xác nhận, lệnh giao dịch đã hoàn tất',
  'detail.comment': 'Bình luận',
  'detail.refund.title': 'Xác nhận đơn xin hoàn tiền',
  'detail.refund.content': 'Sau khi xác nhận, đơn hàng sẽ được hoàn tiền',
  'detail.sqtk': 'Đăng ký hoàn tiền',

  //xác nhận
  'confirm.shipping.address': 'Địa chỉ người nhận',
  'confirm.change': 'Thay đổi',
  'confirm.payment.methods': 'Phương thức thanh toán',
  'confirm.summary': 'Dàn xếp',
  'confirm.total.item.costs': 'Tổng số mặt hàng',
  'confirm.total': 'Tổng số',
  'confirm.checkout': 'Chuyển đến trang thanh toán',
  'confirm.place.order': 'Đặt hàng ngay bây giờ',
  'confirm.pay.now': 'Thanh toán ngay',
  'confirm.order.desc': "Sau khi nhấp vào 'Đặt hàng', tôi xác nhận rằng tôi đã đọc và xác nhận",
  'confirm.order.policy': 'Tất cả các điều khoản và chính sách',
  'confirm.payment': 'Global Shopping đảm bảo an toàn cho thông tin và thanh toán của bạn',

  // Địa chỉ
  'address.title': 'Địa chỉ biên nhận',
  'địa chỉ.default': 'mặc định',
  'address.edit': 'Chỉnh sửa',
  'address.delete': 'Xóa',
  'address.new': 'Tạo địa chỉ mới',
  'address.contact': 'Liên hệ',
  'address.address': 'Địa chỉ',
  'address.phone': 'Số điện thoại',
  'address.set.default': 'Đặt mặc định',
  'address.confirm': 'Xác nhận',
  'address.cancel': 'Hủy bỏ',
  'address.del.title': 'Xác nhận xóa địa chỉ?',
  'address.del.content': 'Bạn có chắc chắn muốn xóa địa chỉ giao hàng này không?',

  'thanh toán.method': 'Phương thức thanh toán',

  // Giỏ hàng
  'shopping.title': 'Giỏ hàng',
  'shopping.back.buy': 'Quay lại mua sắm đặc biệt',
  'shopping.start.shopping': 'Bắt ​​đầu mua sắm',

  // thành viên
  'member.account': 'Trung tâm cá nhân',
  'member.overview': 'Tổng quan',
  'member.orders': 'Đơn hàng',
  'thành viên.thanh toán': 'Ví của tôi',
  'member.address': 'Địa chỉ biên nhận',
  'member.wishlist': 'Danh sách thích',
  'member.followlist': 'Danh sách theo dõi',
  'member.message.center': 'Trung tâm Thông báo',
  'member.setting': 'Cài đặt cá nhân',
  'member.shop.info': 'Thông tin cửa hàng',
  'member.shop.setting': 'Cài đặt cửa hàng',
  'member.order.notify': 'Thông báo đơn hàng mới',
  'member.order.tips': 'Bằng cách gửi email đến email tài khoản người bán',

  // thành viên.đặt hàng
  'member.order.title': 'Đơn hàng của tôi',
  'member.order.view.all': 'Xem tất cả',
  'member.order.all': 'Tất cả',
  'member.order.unpaid': 'chưa thanh toán',
  'member.order.paid': 'Đã thanh toán',
  'member.order.procurement': 'được mua',
  'member.order.seller.paid': 'Đã mua',
  'member.order.processing': 'được xử lý',
  'member.order.shipped': 'đã vận chuyển',
  'member.order.completed': 'Đã hoàn thành',
  'member.order.refund': 'Đã hoàn tiền',
  'thành viên.order.all.time': 'tất cả',
  'member.order.empty': 'Chưa có đơn đặt hàng',
  'thành viên.order.date': 'ngày',
  'member.order.purchase.date': 'Mua hàng',
  'member.order.cpoy': 'Sao chép',
  'member.order.id': 'Đặt hàng',
  'member.order.detail': 'Chi tiết Đơn hàng',
  'member.order.logistics': 'Thông tin hậu cần',

  // thành viên.thanh toán
  'member.payment.title': 'Ví của tôi',
  'member.wallet.balance': 'số dư trong ví',
  'member.crypto.recharge': 'Nạp tiền',
  'member.crypto.withdrawal': 'Rút tiền',
  'member.crypto.bank': 'Thẻ ngân hàng',
  'member.wallet.record': 'kỷ lục gửi tiền xu',
  'member.bankcard.record': 'Hồ sơ thẻ ngân hàng',
  'member.withdrawal.record': 'Hồ sơ rút tiền',
  'member. income.record': 'hồ sơ thu nhập',
  'member.transaction.record': 'Bản ghi giao dịch',
  'member.wallet.freeze': 'Khoản đóng băng',
  'member.wallet.profit': 'Thu nhập ước tính',

  //nạp điện
  'recharge.currency': 'Tiền tệ',
  'recharge.protocol': 'Giao thức',
  'recharge.qrcode': 'mã QR',
  'recharge.address': 'Địa chỉ ví',
  'recharge.copy': 'Sao chép địa chỉ',
  'recharge.ok.text': 'Xác nhận',
  'recharge.cancel.text': 'Hủy',

  //ngân hàng
  'bank.recharge.title': 'Nạp tiền vào thẻ ngân hàng',
  'bank.title': 'Tên ngân hàng',
  'bank.name': 'Tên người nhận thanh toán',
  'bank.account': 'Nhận tài khoản',
  'bank.routing': 'Số định tuyến ngân hàng',
  'bank.code': 'Mã ngân hàng',
  'bank.bankname': 'Tên ngân hàng',
  'bank.bankaddress': 'Địa chỉ ngân hàng',
  'bank.ok.text': 'Tiếp theo',
  'bank.ok.prev': 'Bước trước',
  'bank.submit': 'Gửi ngay bây giờ',
  'bank.amount': 'Số tiền nạp',
  'bank.amount.desc': 'Vui lòng nhập số tiền nạp',
  'bank.type': 'loại',
  'bank.usd': 'USD',
  'bank.eur': 'Euro',
  'bank.receipt.number': 'Số biên nhận',
  'bank.receipt.number.desc': 'Vui lòng nhập số biên nhận giao dịch',
  'bank.credential.picture': 'Ảnh xác thực',
  'bank.credential.picture.desc': 'Vui lòng tải lên ảnh thông tin xác thực',
  'bank.remark': 'Ghi chú',
  'bank.upload': 'Tải ảnh lên',
  'bank.text.title': 'Ghi chú',
  'bank.text.t1': '1. Chuyển khoản không cần ghi chú, để trống',
  'bank.text.t2': '2. Chọn Hồng Kông làm quốc gia của hộ gia đình Hồng Kông, không thêm Trung Quốc',
  'bank.text.t3': '3. Chuyển tiền đến Hoa Kỳ, vào các ngày trong tuần, chuyển tiền đến Châu Âu trước 3:00 chiều, chuyển tiền có sẵn trong giờ làm việc của ngân hàng!',
  'bank.text.t4': '4. Thời gian thanh toán là T+1, và lần thanh toán gần nhất là T+3 ở Châu Âu',
  'bank.text.t5': '5. Thông báo cho nền tảng trước khi chuyển tiền để xác nhận xem tài khoản có khả dụng hay không. Nếu tài khoản bị đóng, sẽ không có khoản bồi thường nào được trả.',
  'bank.text.t6': '6. Gửi tiền qua thẻ ngân hàng, tất cả thông tin dịch vụ khách hàng sẽ được áp dụng.',

  //danh sách ngân hàng
  'bank.list.title': 'Tên ngân hàng',
  'bank.list.code': 'Mã ngân hàng',
  'bank.list.amount': 'Số tiền chuyển',
  'bank.list.number': 'Số biên nhận',
  'bank.list.img': 'Hình ảnh phiếu thưởng',
  'bank.list.status': 'Trạng thái',
  'bank.list.time': 'thời gian',
  'bank.list.status1': 'Đang xem xét',
  'bank.list.status2': 'Đạt',
  'bank.list.status3': 'Bị từ chối',

  // Rút tiền
  'drawal.address.desc': 'Vui lòng nhập địa chỉ ví!',
  'drawal.number': 'Số',
  'drawal.real.number': 'thực tế đến',
  'drawal.number.desc': 'Vui lòng nhập số tiền rút!',
  'rút tiền.btn.all': 'tất cả',
  'drawal.balance': 'Số dư',
  'rút tiền. hoa hồng': 'hoa hồng',
  'drawal.actual.amount': 'Đã tính',
  'rút tiền.notice': 'Nhắc nhở',
  'drawal.notice.text': 'Trước khi chuyển, vui lòng xác nhận rằng thông tin địa chỉ nhận là chính xác. Sau khi tài sản được chuyển đi, chúng không thể được trả lại.',
  'withdrawal.notice.content': '{name}({cp_name}) hoa hồng: giá trị thị trường hiện tại {fee_rate}%/pen, tiêu chuẩn tối thiểu: {fee_min} {name}/pen',
  'rút tiền.submit': 'Gửi',
  'drawal.choice': 'Chọn một loại tiền điện tử',
  'drawal.yzm': 'Mã xác minh',
  'drawal.fs': 'Gửi',
  'drawal.qsryzm': 'Vui lòng nhập mã xác minh email',

  // nhận lại
  'recive.method': 'Giao thức',
  'recive.amount': 'Số tiền',
  'recive.address': 'Địa chỉ',
  'recive.date': 'thời gian',
  'recive.status': 'Trạng thái',
  'recive.create.at': 'Thời gian giao dịch',
  'recive.type': 'loại',
  'recive.befor': 'trước giao dịch',
  'recive.balance': 'Số dư',
  'recive.freeze': 'Đóng băng',
  'recive.review': 'Đánh giá',
  'recive.success': 'Đạt',
  'recive.reject': 'Bị từ chối',

  // Quảng cáo
  'advertise.title': 'Trung tâm Quảng cáo',
  'advertise.shop.title': 'Khuyến mãi cửa hàng',
  'advertise.shop.status': 'Trạng thái',
  'advertise.shop.expired': 'Đã hết hạn',
  'advertise.shop.promotion': 'Quảng cáo',
  'advertise.shop.expire.date': 'Ngày hết hạn',
  'advertise.shop.renew': 'Gia hạn quảng cáo',
  'advertise.shop.payable': 'Số tiền phải trả',
  'advertise.shop.explanation': 'Giải thích quảng cáo',
  'advertise.shop.text': 'Tham gia quảng cáo nền tảng, tăng khả năng hiển thị cửa hàng và thúc đẩy các đơn đặt hàng giao dịch',
  'advertise.shop.paynow': 'Gia hạn ngay',
  'advertise.shop.modal.title': 'Xác nhận thanh toán',
  'advertise.shop.modal.desc': 'Đã xác nhận thanh toán phí khuyến mãi',
  'advertise.shop.modal.btn': 'Xác nhận Thanh toán',

  // Danh sách mong muốn
  'wishlist.title': 'Danh sách mong muốn',
  'wishlist.delete': 'Xóa',
  'wishlist.orders': 'Bán hàng',

  // Danh sách theo dõi
  'followlist.title': 'Danh sách theo dõi',
  'followlist.delete': 'Xóa',
  'followlist.follow': 'Theo dõi',

  // cửa hàng
  'store.dashboard': 'Bảng điều khiển',
  'store.products': 'Sản phẩm',
  'store.products.list': 'Danh sách sản phẩm',
  'store.products.reviews': 'Đánh giá sản phẩm',
  'store.orders': 'Đơn hàng',
  'store.wallet': 'Ví tiền',
  'store.message': 'Trung tâm tin nhắn',
  'store.setting': 'cài đặt',
  'store.order.total.profit': 'Tổng thu nhập hoạt động ước tính',

  //bảng điều khiển
  'dashboard.store.hour.views': 'Lượt truy cập trong thời gian thực',
  'dashboard.store.today.views': 'Lượt truy cập hôm nay',
  'dashboard.product.total': 'Tổng sản phẩm',
  'dashboard.product.today': 'Hôm nay mới',
  'dashboard.order.total': 'Tổng đơn hàng',
  'dashboard.sales.total': 'Doanh thu ước tính',
  'dashboard.sales.real': 'Doanh số thực',
  'dashboard.sales.pay': 'Tổng số tiền xuất chi',
  'dashboard.sales.profit': 'Tổng lợi nhuận',
  'dashboard.commission.total': 'Tổng doanh thu',
  'dashboard.commission.today': 'Thu nhập hôm nay',
  'dashboard.order.sales': 'Bán hàng hóa',
  'dashboard.sales.list': 'Xếp hạng doanh số',
  'dashboard.goods.cate.rate': 'Tỷ lệ phân loại sản phẩm',
  'dashboard.goods.wish': 'Giống như xếp hạng sản phẩm',
  'dashboard.january': 'Tháng Giêng',
  'dashboard.february': 'Tháng hai',
  'dashboard.march': 'Tháng ba',
  'dashboard.april': 'Tháng Tư',
  'dashboard.may': 'May',
  'dashboard.june': 'Tháng sáu',
  'dashboard.july': 'Tháng 7',
  'dashboard.august': 'Tháng 8',
  'dashboard.september': 'Tháng 9',
  'dashboard.october': 'Tháng 10',
  'dashboard.november': 'Tháng 11',
  'dashboard.december': 'Tháng 12',

  // các sản phẩm
  'products.add.new': 'Thêm sản phẩm mới',
  'products.add.from.warehouse': 'Thêm sản phẩm từ kho hàng',
  'products.delete': 'Xóa',
  'products.add': 'Thêm',
  'products.table.img': 'hình ảnh',
  'products.table.name': 'tên sản phẩm',
  'products.table.category': 'Danh mục',
  'products.table.wish': 'thích',
  'products.table.stock': 'cổ phiếu',
  'products.table.price': 'Giá',
  'products.table.profit': 'Lợi nhuận',
  'products.table.action': 'Hành động',
  'products.search.category': 'Tìm kiếm theo danh mục',
  'products.back.product': 'Quay lại danh sách sản phẩm',
  'products.total': 'Tổng số hàng hóa',
  'products.yes': 'Có',
  'products.no': 'Hủy',
  'products.batch.add': 'Tăng hàng loạt',
  'products.ask.add': 'Bạn có chắc chắn muốn thêm sản phẩm không?',
  'products.batch.delete': 'Xóa hàng loạt',
  'products.ask.delete': 'Bạn có chắc chắn muốn xóa sản phẩm không?',
  'products.top': 'Hàng đầu',
  'products.syzd': 'Đầu trang chủ',
  'products.zdwz': 'Vị trí hàng đầu',
  'products.t1': 'Khuyến mãi',
  'products.t2': 'Ưu tiên',
  'products.t3': 'Cửa hàng',
  'products.t4': 'không',

  // đánh giá
  'reviews.title': 'Đánh giá sản phẩm',
  'reviews.product.name': 'Sản phẩm',
  'reviews.user.name': 'Người dùng',
  'reviews.star': 'Xếp hạng',
  'reviews.comment': 'Nội dung bình luận',
  'reviews.sku': 'Thông số sản phẩm',
  'review.imgs': 'Bản đồ',
  'reviews.created': 'Ngày',

  // cửa hàng.đặt hàng
  'store.order.purchase': 'Mua ngay',
  'store.order.purchase.desc': 'Bạn có chắc chắn muốn thanh toán cho sản phẩm này không?',
  'store.order.purchase.yes': 'Thanh toán ngay',
  'store.order.purchase.no': 'Hủy',
  'store.order.desc': 'Việc mua đơn hàng này yêu cầu thanh toán, và bạn sẽ nhận được lợi ích sau khi đơn hàng hoàn tất. ',
  'store.order.no': 'Số đơn đặt hàng',
  'store.order.number': 'Số lượng',
  'store.order.buyer': 'Người mua',
  'store.order.total': 'Tổng số tiền',
  'store.order.will.earning': 'Thu nhập',
  'store.order.profit': 'Lợi nhuận',
  'store.order.dividends': 'Cổ tức',
  'store.order.payment.status': 'Trạng thái Thanh toán',
  'store.order.seller.buy.status': 'Trạng thái mua hàng',
  'store.order.status': 'Trạng thái Đơn hàng',
  'store.order.date': 'ngày đặt hàng',
  'store.order.purchase.date': 'Ngày mua',
  'store.order.action': 'Hoạt động',
  'store.order.purchase.amount': 'Số lượng mua hàng',

  //thu nhập
  ' income.create.at': 'Kỷ lục thời gian',
  ' income.order.sn': 'Số đơn đặt hàng',
  ' income.realpay': 'Số tiền đặt hàng',
  'thu nhập.lợi nhuận': 'thu nhập',

  //Cài đặt
  'setting.avatar': 'Hình đại diện doanh nghiệp',
  'setting.upload': 'Tải lên',
  'setting.shop.name': 'Tên cửa hàng',
  'setting.shop.phone': 'Số điện thoại liên hệ',
  'setting.shop.address': 'Địa chỉ cửa hàng',
  'setting.shop.save': 'Lưu',
  'setting.upload.pic': 'Tải ảnh lên',
  'setting.send.pic': 'Gửi ảnh',

  // người bán
  'beseller.title': 'Đăng ký làm thương gia',
  'beseller.account': 'Thông tin tài khoản',
  'beseller.store': 'Thông tin cửa hàng',
  'beseller.store.name': 'Tên cửa hàng',
  'beseller.store.address': 'Địa chỉ cửa hàng',

  //savehome
  'store.home.title': 'Cài đặt Trang chủ',
  'store.home.topimg': 'hình ảnh trên cùng',
  'store.home.banner': 'Hình ảnh băng chuyền',
  'store.home.up3': 'Ghi chú: Ít nhất 3 lần tải lên',
  'store.upload.more': 'Tải thêm hình ảnh lên',
  'store.home.columns': 'Cột',
  'store.home.bgimg': 'Hình nền',
  'store.goods.remark': 'Ghi chú: 5 mặt hàng mỗi dòng, tối đa 10 mặt hàng mỗi đĩa',
  'store.home.select': 'Chọn sản phẩm',
  'store.home.add': 'Thêm cột',

  //thông tin cá nhân
  'setting.update.user': 'Thông tin cá nhân',
  'setting.update.info': 'Sửa thông tin',
  'setting.user.avatar': 'Hình đại diện người dùng',
  'setting.user.nickname': 'Biệt danh người dùng',
  'setting.user.nickname.desc': 'Vui lòng nhập tên người dùng',

  'setting.safe.title': 'Thông tin An toàn',
  'setting.user.passwd': 'Thay đổi mật khẩu',
  'setting.passwd.title1': 'Mật khẩu cũ',
  'setting.passwd.desc1': 'Vui lòng nhập mật khẩu cũ',
  'setting.passwd.title2': 'Mật khẩu mới',
  'setting.passwd.desc2': 'Vui lòng nhập mật khẩu mới',
  'setting.passwd.title3': 'Xác nhận mật khẩu',
  'setting.passwd.desc3': 'Vui lòng nhập mật khẩu xác nhận',
  'setting.invitation': 'Mời bạn bè',
  'setting.copy': 'Sao chép',

  'adv.add.products': 'Thêm sản phẩm khuyến mại',
  'adv.list.title': 'Quảng cáo sản phẩm',
  'adv.type': 'Loại Quảng cáo',
  'adv.begin.time': 'Thời gian khuyến mãi',
  'adv.end.time': 'Thời gian kết thúc',
  'adv.status': 'Trạng thái',
  'adv.status.s1': 'Quảng cáo',
  'adv.status.s2': 'Kết thúc',
  'adv.add.back': 'Quay lại danh sách được thăng hạng',
  'adv.recharge': 'Nạp điểm khuyến mãi',
  'adv.select.goods': 'Chọn hàng hóa',
  'adv.select.btn': 'Sản phẩm được Quảng cáo',
  'adv.recharge.title': 'Nạp điểm Khuyến mãi',
  'adv.recharge.balance': 'Số dư điểm',
  'adv.điểm': 'điểm',
  'adv.point.rate': 'Tỷ lệ điểm',
  'adv.recharge.amount': 'Số tiền nạp lại',
  'adv.recharge.input.amount': 'Nhập số tiền nạp lại',
  'adv.select.mod': 'Chọn bảng điều khiển',
  'adv.mod1': 'Cột được đề xuất',
  'adv.mod2': 'Cột ưu tiên',
  'adv.mod3': 'Cột bán chạy',
  'adv.mod4': 'Danh sách xuất sắc',
  'adv.mod5': 'Danh sách phân loại',
  'adv.mod6': 'Khuyến mãi chi tiết',
  'adv.ge': 'Số lượng',
  'adv.xs': 'giờ',
  'adv.xzbk': 'Chọn tấm',
  'adv.syme': 'Hạn ngạch còn lại',
  'adv.tgsc': 'Thời gian khuyến mãi',
  'adv.tgjg': 'Giá khuyến mãi',
  'adv.syye': 'Số dư hiện tại',
  'adv.tgye': 'Số dư khuyến mãi',
  'adv.yj1': 'được mong đợi',
  'adv.yj2': 'Bắt ​​đầu quảng cáo',
  'adv.pay.point': 'Điểm tiêu dùng',

  'wallet.ykcsxf': 'Phí xử lý đã được khấu trừ',
  'wallet.sxf': 'Phí dịch vụ',
  'wallet.zxje': 'Số tiền tối thiểu',
  'wallet.24hxe': 'Giới hạn 24 giờ',
  'wallet.desc.text': 'Trong mọi trường hợp, quan chức sẽ không yêu cầu bạn chuyển tiền vào tài khoản, cũng như không yêu cầu bạn cung cấp mã xác minh. Không tham gia vào các hoạt động bất hợp pháp như mua hàng thay cho người khác, rửa tiền và gây quỹ bất hợp pháp và hãy cẩn thận với gian lận trực tuyến',
  'wallet.cunbi.text': 'Bạn chỉ có thể gửi {name} vào địa chỉ này, các tài sản khác sẽ không được lấy lại',

  'credit.title': 'Giới hạn tín dụng',
  'credit.ed': 'Hạn ngạch',
  'credit.hk': 'Trả nợ',
  'credit.kyye': 'Số dư khả dụng',
  'credit.dqqk': 'Khoản nợ hiện tại',
  'credit.hkje': 'Số tiền hoàn trả',
  'credit.hkje.desc': 'Vui lòng nhập số tiền hoàn trả',
  'credit.cgje': 'Số tiền mua hàng',
  'credit.qrz': 'Chuyển đến phần xác thực',
  'credit.srxyj': 'Nhập số tiền thanh toán tín dụng',

  'store.gzl': 'Số lượng chú ý',
  'store.wxz': 'Không giới hạn',

  'auth.smrz': 'Xác thực tên thật',
  'auth.rzlx': 'Loại',
  'auth.sfzm': 'Mặt trước thẻ căn cước',
  'auth.sffm': 'Mặt sau của chứng minh nhân dân',
  'auth.zsxm': 'tên thật',
  'auth.zjhm': 'Số tài liệu',
  'auth.yyzz': 'Giấy phép kinh doanh',
  'auth.gsmc': 'Tên công ty',
  'auth.shz': 'Kiểm tra',
  'auth.ytg': 'Đã đạt',
  'auth.ybh': 'Bị từ chối',
  'auth.zt': 'trạng thái',
  'auth.gr': 'Cá nhân',
  'auth.gs': 'Doanh nghiệp',
  'auth.ljtj': 'Đăng ký xác thực',
  'auth.wrz': 'Chưa xác thực',

  'credit.qts': 'Xem mô tả',
  'store.spss': 'Giới hạn trên của hàng hóa',
  'store.dpfh': 'Lưu trữ cổ tức',
  'store.qydj': 'Mức vốn chủ sở hữu',
  'store.level': 'cấp độ',
  'store.jian': 'miếng',

  'order.notify.email': 'Do kiểm soát rủi ro email, các email nhắc nhở đặt hàng nền tảng sẽ bị chặn thường xuyên. Để nhận được chúng kịp thời, bạn nên thêm {email} vào danh sách trắng email đăng nhập',

  'setting.sjxx': 'Thông tin doanh nghiệp',
  'setting.smrz': 'Xác thực tên thật',
  'setting.aqzx': 'Trung tâm bảo mật',
  'setting.tzxx': 'Thông tin thông báo',
  'setting.dpzx': 'Trang chủ trang trí',
  'setting.sjqy': 'Lợi ích kinh doanh',
  'setting.sjdjqy': 'Mô tả lợi ích cấp doanh nghiệp',

  'setting.fhbky': 'Không có cổ tức hiện tại',
  'setting.spgzyzy': 'Lưu trữ quy tắc và hướng dẫn',

  'desc.dqqy': 'Vốn chủ sở hữu hiện tại',
  'desc.xjqy': 'Lợi ích cấp dưới',
  'desc.sjqy_0': 'Lưu trữ giới hạn tín dụng',
  'desc.sjqy_1': 'Lưu trữ cổ tức',
  'desc.sjqy_2': 'Số lượng sản phẩm có thể xuất bản',

  'share.yqlb': 'Danh sách lời mời',
  'share.zmj': 'Tổng số người bán',
  'share.zdd': 'Tổng đơn hàng',
  'share.zsy': 'Tổng thu nhập',
  'share.wcl': 'chưa xử lý',
  'share.ycl': 'Đã xử lý',
  'share.ywc': 'Đã hoàn thành',
  'share.dpmc': 'Tên cửa hàng',
  'share.ddl': 'Số lượng đặt hàng',
  'share.jjsy': 'Thu nhập môi giới',
  'share.zts': 'Tổng số',

  'chat.state': 'trạng thái',
  'chat.zx': 'Trực tuyến',
  'chat.ljz': 'Đang kết nối',

  'bind.wallet.title': 'Địa chỉ rút tiền',
  'bind.address.title': 'Thêm địa chỉ',
  'bind.bj': 'Chỉnh sửa',
  'bind.sc': 'Xóa',
  'bind.qrsc': 'Bạn có chắc chắn muốn xóa địa chỉ này không?',
  'bind.qbdz': 'Địa chỉ ví',
  'bind.tjsj': 'Thêm thời gian',
  'bind.cz': 'Hoạt động',
  'drawal.address.choice': 'Vui lòng chọn địa chỉ rút tiền!',

  'order.sxsj': 'Thời gian hiệu lực',
  'order.ljsj': 'có hiệu lực ngay lập tức',
  'order.dssj': 'Hẹn giờ có hiệu lực',

  'store.st.zt': 'Trạng thái cửa hàng',
  'store.st.zc': 'bình thường',
  'store.st.zc.desc': 'Cửa hàng của bạn vẫn bình thường, hãy tiếp tục duy trì',
  'store.st.yc': 'Ngoại lệ',
  'store.st.yc.desc': 'Cửa hàng của bạn có một số đơn đặt hàng bất thường hoặc nhận được khiếu nại của khách hàng',
  'store.st.dj': 'Đóng băng',
  'store.st.dj.desc': 'Cửa hàng của bạn có rủi ro hoạt động nhất định và tiền bị đóng băng',
  'store.st.jy': 'đã tắt',
  'store.st.jy.desc': 'Cửa hàng của bạn đã vi phạm các thỏa thuận và quy tắc liên quan đến nền tảng và đã bị vô hiệu hóa',

  'task.sy': 'Trang chủ',
  'task.rwlbo': 'Danh sách nhiệm vụ',
  'task.rwxqo': 'Chi tiết nhiệm vụ',
  'task.ljcy': 'Tham gia ngay',
  'task.gdrw': 'Thêm nhiệm vụ',
  'task.rwlb': 'Danh sách các nhiệm vụ lấy',
  'task.qbrw': 'Tất cả tác vụ',
  'task.wcyd': 'Tôi đã tham gia',
  'task.qdz': 'Nhận đơn đặt hàng',
  'task.ywc': 'Đã hoàn thành',
  'task.djy': 'được giao dịch',
  'task.ddje': 'Số lượng đặt hàng',
  'task.cyrs': 'Số người tham gia',
  'task.ewsy': 'Các lợi ích bổ sung',
  'task.jzsj': 'Hạn chót',
  'task.ckxq': 'Xem chi tiết',
  'task.rwxq': 'Chi tiết nhiệm vụ',
  'task.cylb': 'Danh sách tham gia',
  'task.qdcyrw': 'Bạn có chắc chắn muốn tham gia vào nhiệm vụ đặt hàng này không?',
  'nhiệm vụ.sd': 'Có',
  'nhiệm vụ.bue': 'không',

  'task.wdsy': 'Doanh thu',
  'task.wdfh': 'Cổ tức',
  'task.wdqd': 'Nhận đơn đặt hàng',
  'task.dd.leixin': 'Loại Đơn đặt hàng',
  'task.dd.qdrw': 'Nhiệm vụ đặt hàng nhanh',
  'task.dd.ptdd': 'Thứ tự bình thường',

  'flow.lltg': 'Xúc tiến dòng chảy',
  'flow.gmll': 'Mua gói dữ liệu',
  'flow.dqtc': 'Gói hiện tại',
  'flow.tc1': 'Gói',
  'flow.sy2': 'còn lại',
  'flow.tian3': 'ngày',
  'flow.jlzs': 'Tổng số bản ghi',
  'flow.lltc': 'Gói lưu lượng',
  'flow.ll': 'flow',
  'flow.gmsj': 'Thời gian mua hàng',
  'flow.tcjg': 'Giá trọn gói',
  'flow.sjzf': 'thanh toán thực tế',
  'flow.tcsm': 'Mô tả gói',
  'flow.tcsm.sm': 'Mua gói này sẽ tăng mức độ hiển thị của cửa hàng và có thêm lưu lượng khách hàng. Nhằm có được nhiều đơn hàng và tăng doanh thu cho cửa hàng. ',

  'flow.tcmc': 'Tên gói',
  'flow.gmsc': 'Thời hạn mua hàng',
  'flow.zje': 'Tổng số tiền',
  'flow.jzsj': 'Hạn chót',
  'flow.gmrq': 'Ngày mua',
  'flow.tian1': 'ngày',
  'flow.zhou1': 'tuần',
  'flow.yue1': 'tháng',

  'share.dj': 'cấp độ',
  'share.hz': 'Chuyển',
  'share.hzd': 'Chuyển tới',
  'share.qbye': 'số dư trong ví',
  'share.tgje': 'Số tiền khuyến mãi',
  'share.hzje': 'Số tiền chuyển',
  'share.qsrhzje': 'Vui lòng nhập số tiền chuyển',

  '_inv._title': 'Mô tả lời mời',
  '_inv._t1': '1. Lời mời và mối quan hệ được mời',
  '_inv._desc1': 'A mời B, B mời C, C mời D. Mức cao nhất là 4, nếu D mời E lại thì E không liên quan gì đến A. ',
  '_inv._t2': '2. Người mời nhận được phần thưởng',
  '_inv._desc2': 'A nhận được 4% tổng số tiền đã hoàn thành theo đơn đặt hàng B, A nhận được 2% tổng số tiền đã hoàn thành theo đơn đặt hàng C và A nhận được 1% trên tổng số tiền đã hoàn thành theo đơn đặt hàng D',

  '_index._gm': 'Mua',
  '_index._sm': 'Mô tả: Tăng mức độ hiển thị của cửa hàng và thu hút lưu lượng truy cập',

  '_n.dlyzc': 'Đăng nhập và đăng ký',
  '_n.hy4': 'Còn 4 cái nữa',
  '_n.ckgd': 'Xem thêm',
  '_n._cxhzk': 'Khuyến mãi và giảm giá',
  '_n._cwyp': 'Đồ dùng cho thú cưng',
  '_n._tjsp': 'Ưu đãi đặc biệt',
  '_n._rmfl': 'Danh mục phổ biến',
  '_n._tjsp2': 'Sản phẩm được đề xuất',
  '_n._rxsp': 'Sản phẩm được đề xuất',
  '_n._rmsp': 'Các mặt hàng phổ biến',
  '_n._gdsp': 'Thêm sản phẩm',
  '_n._yzsj': 'Người bán chất lượng',

  '_n._zxsc': 'Trung tâm mua sắm trực tuyến',
  '_n._fkyys': 'Thanh toán và vận chuyển',
  '_n._xsgz': 'Quy tắc bán hàng',
  '_n._hhjth': 'Đổi và trả',
  '_n._lxwm': 'Liên hệ với chúng tôi',
  '_n._sczc': 'chính sách của trung tâm mua sắm',
  '_n._tkytj': 'Điều khoản và Điều kiện',
  '_n._tuzc': 'Chính sách hoàn trả',
  '_n._zczc': 'Chính sách hỗ trợ',
  '_n._yszc': 'Chính sách quyền riêng tư',
  '_n._scjj': 'Giới thiệu trung tâm mua sắm',
  '_n._ljgm': 'Mua ngay',

  '_n2._rxsp': 'Mặt hàng bán chạy',
  '_n2._pl': 'Bình luận',
  '_n2._sjxx': 'Thông tin doanh nghiệp',
  '_n2._rhsh': 'Cách nhận hàng',
  '_n2._xsxd': 'Đặt hàng trực tuyến',
  '_n2._jscldd': 'Dịch vụ khách hàng tận tâm',
  '_n2._mfps': 'Giao hàng miễn phí',
  '_n2._2xsfh': 'Giao hàng chớp nhoáng',
  '_n2._1d5tdd': 'Đến trong vòng 1-5 ngày',
  '_n2._tkzd': 'Thư chuyển phát nhanh',
  '_n2._ckfh': 'Gửi hàng từ kho',
  '_n2._zcps': 'Kho gần đây',
  '_n2._jsd': 'Điểm nhận',
  '_n2._qsjps': 'Giao hàng toàn cầu',
  '_n2._thtj': 'Điều kiện trả về',
  '_n2._thtjsm': 'Trong vòng 14 ngày kể từ ngày mua, bạn có thể đổi hoặc trả hàng',
  '_n2._gycp': 'Giới thiệu về sản phẩm',
  '_n2._yc': 'Ẩn',
  '_n2._tiao': 'Tiao',
  '_n2._sypl': 'Tất cả bình luận',
  '_n2._lxfk': 'Để lại phản hồi',
  '_n2._xzspgg': 'Hãy chọn thông số kỹ thuật của sản phẩm',
  '_n2._qxdl': 'Vui lòng đăng nhập trước',
  '_n2._rnsj': 'Như bạn thấy',
  '_n2._rx': 'Giảm giá hấp dẫn',

  '_n3._qrdd': 'Xác nhận đơn hàng',
  '_n3._njsp': '{num} mục',
  '_n3._ddzf': 'Thanh toán đơn hàng',
  '_n3._wydlsp': 'Chào mừng bạn đăng nhập vào cửa hàng',

  '_n3._sjwfgm': 'Người bán không thể mua hàng',
  '_n3._sjbnlt': 'Doanh nghiệp không thể trò chuyện',
  '_chat._hc': 'Rút tiền',

  '_zc._sryxdz': 'Vui lòng nhập email của bạn',
  '_zc._yxgscw': 'Lỗi định dạng email',
  '_zc._qsrmm': 'Vui lòng nhập mật khẩu',
  '_zc._lcmmbxd': 'Hai mật khẩu không nhất quán',
  '_zc._hdyzwtg': 'Vui lòng xác minh qua thanh trượt',
  '_zc._qsryqm': 'Vui lòng nhập mã mời',
  '_zc._yhzh': 'Đăng ký tài khoản người dùng',
  '_zc._qzc': 'Đi tới đăng ký',
  '_zc._srdpm': 'Vui lòng nhập tên cửa hàng',

  '_zc._gszz': 'Chứng chỉ của công ty',
  '_zc._ptzz': 'Các bằng cấp liên quan đến nền tảng',

  '_dd._tijd': 'Đang gửi đơn hàng',
  '_dd._xzdz': 'Hãy chọn địa chỉ giao hàng',
  '_dd._zfz': 'Đang thanh toán',

  '_wd._zf': 'thanh toán',
  '_wd._cz': 'Nạp tiền',
  '_wd._txsq': 'Đơn xin rút tiền',
  '_wd._ddsy': 'Thu nhập từ đơn hàng',
  '_wd._cgzf': 'Mua',
  '_wd._txbh': 'Từ chối',
  '_wd._txtg': 'Đã vượt qua',
  '_wd._ddtk': 'Hoàn tiền đơn hàng',
  '_wd._yehz': 'Chuyển',
  '_wd._gmll': 'Mua lưu lượng truy cập',
  '_wd._zjkc': 'khấu trừ tiền thuê nhà',
  '_wd._fk': 'Ổn',
  '_wd._ns': 'Thuế',
  '_wd._bzj': 'Ký quỹ',

  '_xxtz._zntz': 'Thông báo về trang web',
  '_xxtz._fh': 'trở lại',
  '_xxtz._jzgd': 'Tải thêm',
  '_xxtz._mysj': 'Không còn nữa',

  '_sjsy._jrdd': 'Đơn hàng hôm nay',
  '_sjsy._rxse': 'Doanh số hàng ngày',

  '_sjsy._xh': 'Chỉ mục',

  '_wd._nbjy': 'Chuyển khoản nội bộ',
  '_wd._qsruid': 'Vui lòng nhập UID người nhận thanh toán',
  '_wd._je': 'Số tiền',
  '_wd._qsrhzje': 'Vui lòng nhập số tiền chuyển',
  '_wd._skr': 'Người được thanh toán',
  '_wd._nbhzsm': 'Để chuyển nội bộ tới người dùng nền tảng, vui lòng kiểm tra kỹ thông tin người nhận thanh toán',

  '_st._spxlphb': 'Xếp hạng doanh số bán sản phẩm',
  '_st._czsp': 'Đặt lại tìm kiếm',

  '_st._pljrgwc': 'Tham gia tất cả',

  '_sj._yxz': 'Đã chọn',
  '_sj._hjzje': 'Tổng số tiền',
  '_sj._zdcr': 'Tự động gửi tiền',

  '_wd._ddfh': 'Cổ tức',

  '_dd._csqx': 'Hủy đơn hàng',

  '_tx._yhktx': 'Rút tiền ngân hàng',
  '_tx._sryhmc': 'Hãy nhập tên ngân hàng',
  '_tx._srskrmc': 'Xin vui lòng nhập tên người nhận thanh toán',
  '_tx._srskzh': 'Vui lòng nhập số tài khoản thanh toán',
  '_tx._sryhluh': 'Vui lòng nhập số định tuyến ngân hàng',
  '_tx._sryhdm': 'Hãy nhập mã ngân hàng',
  '_tx._sryhdz': 'Hãy nhập địa chỉ ngân hàng',
  '_tx._txje': 'Số tiền rút',
  '_tx._srtxje': 'Vui lòng nhập số tiền rút',
  '_tx._txkc': 'Phí rút tiền {_sxf}%',

  '_yye._yyye': 'Doanh số hàng tháng',
  '_yye._ysy': 'Thu nhập hàng tháng',

  '_sj._hjzsy': 'Tổng thu nhập',

  '_yhdl._dl': 'Đăng nhập',
  '_yhdl._zc': 'Tạo tài khoản',

  '_zdy._24zrkf': 'Chúng tôi ở đây để hỗ trợ bạn 24/7',
  '_zdy._zhsz': 'Cài đặt tài khoản',
  '_zdy._mfps': 'Trong thời gian có hạn, bạn có thể được giao hàng miễn phí trong 2 ngày làm việc đối với hàng nghìn mặt hàng tại một số khu vực được chọn. ',
  '_zdy._gmsp': 'Mua sản phẩm mới',
  '_zdy._czyh': 'Ưu đãi lớn, hãy tận hưởng những sản phẩm bạn yêu thích',
  '_zdy._xsgw': 'Thời gian mua sắm có hạn! Đặt hàng trước trưa ngày 12 tháng 5 theo giờ ET để được giao hàng miễn phí. Xe chuyên dụng sẽ giao đến tận nhà bạn! ',
  '_zdy._ssgjc': 'Nhập từ khóa tìm kiếm',

  '_zdy._rmjx': 'Lựa chọn hàng đầu của bạn',

  '_dd._plcz': 'Thao tác hàng loạt',
  '_dd._plcg': 'Mua số lượng lớn',
  '_dd._qgxcgd': 'Xin vui lòng kiểm tra đơn hàng bạn cần mua',
  '_dd._yxddbcg': 'Đơn hàng đã chọn không cần mua',
  '_dd._qdyplcgm': 'Bạn có chắc chắn muốn mua số lượng lớn đơn hàng đã chọn không? ,Số lượng:{n}',
  '_dd._qdcg': 'Xác nhận mua hàng',
  '_dd._qx': 'Hủy',
  '_dd._plcgwc': 'Mua hàng loạt đã hoàn tất, thành công: {s}/{t}',
  '_dd._zzcgz': 'Mua hàng',
  '_st._czhk': 'Nạp tiền và hoàn trả',
  '_st._dkje': 'số tiền vay',
  '_st._dkjl': 'lịch sử cho vay',
  '_st._hkfs': 'Trả nợ',
  '_st._hkje': 'Số tiền trả nợ',
  '_st._jrong': 'trung tâm mua sắm tài chính',
  '_st._ljsq': 'nộp đơn ngay lập tức',
  '_st._qrshkje': 'Vui lòng nhập số tiền hoàn trả',
  '_st._qsrdkje': 'Vui lòng nhập số tiền vay',
  '_st._shz': 'đang được xem xét',
  '_st._sqdklx': 'khoản vay',
  '_st._sqhk': 'Nộp đơn xin hoàn trả',
  '_st._sqhklx': 'trả nợ',
  '_st._sqjk': 'nộp đơn xin vay',
  '_st._sqlx': 'kiểu',
  '_st._ybh': 'từ chối',
  '_st._yjk': 'Mượn',
  '_st._ytg': 'vượt qua',
  '_st._zzhk': 'Đang chờ trả nợ',

  '_st._dqed': 'Số tiền vay',
  '_st._ccdked': 'Không thể vượt quá giới hạn cho vay',
  '_st._tjsqz': 'Đơn đăng ký đang được gửi',
  '_st._dklx': 'Sở thích',
  '_st._dkll': 'Lãi suất vay:{n}%',
  '_kdts._ts': 'Mở cửa trong {t} ngày',
  '_jltg._tgje': 'Đầu tư quảng cáo',

  '_st._yqk': 'Nợ đọng',
  '_st._qb': 'Tất cả',

  '_spxx._spbm': 'mã',

  '_scrz._yyzz': 'Giấy phép kinh doanh',

  '_sjhb._sjbt': 'Phiếu giảm giá',
  '_sjhb._kfxd': 'Danh sách phiếu giảm giá',
  '_sjhb._fxlb': 'Chia sẻ danh sách',
  '_sjdhb._hbje': 'giáo phái',
  '_sjdhb._fxzs': 'Tổng số lượt chia sẻ',
  '_sjdhb._fxcz': 'Hoạt động',
  '_sjdhb._fxyhj': 'Chia sẻ',
  '_sjdhb._gqsj': 'Thời gian hết hạn',
  '_sjdhb._lqsj': 'Thời gian thu thập',
  '_sjdhb._lqbm': 'mã hóa',
  '_sjdhb._wlq': 'Không nhận được',
  '_sjdhb._wsy': 'Không được sử dụng',
  '_sjdhb._ysy': 'đã qua sử dụng',
  '_sjdhb._ylq': 'Đã nhận',
  '_sjdhb._ygq': 'Đã hết hạn',
  '_sjdhb._fuzi': 'Sao chép',
  '_sjdhb._lqyhj': 'Phiếu giảm giá',
  '_sjdhb._lqlqyhj': 'Nhận phiếu giảm giá',
  '_sjdhb._yhjbm': 'Mã phiếu giảm giá',
  '_sjdhb._ljlq': 'Nhận ngay bây giờ',

  '_sjdhb._hqgdyhj': 'Nhận thêm phiếu giảm giá cho bạn',
  '_sjdhb._nddzyj': 'Email của bạn',
  '_sjdhb._dy': 'Đăng ký',
  '_sjdhb._zffs': 'Phương thức thanh toán',
  '_sjdhb._zfjs': 'Người dùng của Wholesale Global Station đến từ 112 quốc gia trên thế giới và sử dụng USDT/ETH/BTC để thanh toán. USDT/ETH/BTC là phương thức giao dịch không biên giới cho phép giao dịch tức thì với chi phí thấp trên toàn thế giới, không phải chờ đợi và không mất phí quốc tế. ',
  '_sjdhb._wzbq': 'Bản quyền bán buôn 2023 Limited',

  '_sjdk.yhqbt': 'Hoạt động giảm giá',
  '_sjdk.yhq': 'Phiếu giảm giá',
  '_sjdk.dkq': 'Phiếu giảm giá của thương gia',

  '_sjdk._gmzk': 'Giảm giá khi mua hàng',
  '_sjdk._yhez': 'Số tiền giảm giá',
  '_sjdk._yhsmwa': 'Giảm giá khi mua hàng, bạn có thể tặng người mua phiếu giảm giá có mệnh giá bất kỳ để khấu trừ vào số tiền đơn hàng, nhằm thu hút người mua mua hàng và tăng doanh số bán hàng của cửa hàng. ',

  '_sjdk._yuee': 'Cân bằng',
  '_sjdk._gmyue': 'Mua',
  '_sjdk._shoujia': 'Giá',
  '_sjdk._zsdx': 'Tặng cho',
  '_sjdk._zsyhj': 'Tặng phiếu giảm giá',
  '_sjdk._yhjje': 'Số lượng',
  '_sjdk._sryhjje': 'Vui lòng nhập số tiền thẻ',
  '_sjdk._xftj': 'Điều kiện',
  '_sjdk._srxftj': 'Vui lòng nhập điều kiện số tiền đặt hàng',
  '_sjdk._yhqtj': 'Lưu ý: Số tiền đặt hàng phải đáp ứng các điều kiện để được sử dụng',
  '_sjdk._ljzs': 'Quà tặng',
  '_sjdk._sysj': 'Thời gian sử dụng',
  '_sjdk._zssj': 'Thời gian tặng quà',
  '_sjdk._wsy': 'Không sử dụng',
  '_sjdk._yss': 'Đã sử dụng',
  '_sjdk._qrzs': 'Cách tặng',
  '_sjdk._qzs': 'Đi tặng quà',
  '_sjdk._yhjjl': 'Bản ghi phiếu giảm giá',
  '_sjdk._syyhq': 'Phiếu giảm giá',
  '_sjdk._xfmje': 'Số lượng >= ${n}'
}
